.top-banner-layout {
	top: 299px !important;
}
.osfhealthcare-innovation-header {
	display: flex;
	flex-shrink: 0;
	min-height: 80px;
	width: 100%;
	z-index: 4;

	.mobile-nav-open & {
		.open-mobile-nav {
			display: none;
		}

		.mobile-nav-menu {
			visibility: visible;
			width: 100vw;
		}
	}

	.navigation-container {
		@include make-container();
		align-items: center;
		background-color: $color-white;
		display: flex;
		justify-content: space-between;
	}

	.mobile-nav-header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 20px 0;
	}

	.mobile-home-link {
		padding-left: 20px;
	}

	.home-link {
		display: flex;
		flex-basis: 200px;
		min-width: 200px;
	}

	.osf-logo {
		max-width: 250px;
		height: auto;
	}

	.mobile-close-button {
		align-items: center;
		background: #fff;
		border: 0;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		height: 40px;
		justify-content: center;
		width: 40px;
	}

	.open-mobile-nav {
		cursor: pointer;
		display: block;

		.icon-menu {
			display: none;
		}
	}

	.mobile-nav-menu {
		background: $color-neutral-lightest-3;
		height: 100vh;
		overflow: hidden;
		overflow-y: auto;
		padding-bottom: 5rem;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(100%);
		visibility: hidden;
		width: 0;
		z-index: 10;

		.home-link {
			.icon {
				fill: $color-brown;
				height: 34.97px;
				width: 260px;
			}
		}

		.filter-btn {
			display: flex;
		}
	}

	.mobile-nav-menu-container {
		@include make-container();
	}

	.list-block {
		background-color: $color-white;
		color: $color-black;
		padding: 20px;

		h3 {
			@include text(lambda);
			color: $color-green;
			font-style: $font-weight-medium;
			margin-bottom: 0.25rem;
			text-transform: uppercase;
		}

		p {
			@include text(kappa);
			color: $color-neutral-dark;
			font-family: $font-family-sans-serif;
			font-style: $font-weight-light;
			margin-bottom: 0;
		}
	}

	.mobile-nav-list {
		li {
			.list-block {
				display: block;
			}
		}
	}

	.mobile-nav-footer {
		@include text(theta);
		align-items: center;
		background-color: $color-light-gray;
		bottom: 0;
		color: $color-black;
		display: flex;
		justify-content: space-between;
		left: 0;
		padding: 20px 30px;
		position: absolute;
		width: 100%;
	}

	.innovation-links,
	.search-btn {
		display: none;
	}

	.action-section-mobile {
		background-color: $color-neutral-lightest-2;
		position: relative;

		.icon {
			bottom: 20px;
			height: 20px;
			position: absolute;
			right: 20px;
			width: 20px;
		}
	}

	.subnav-container {
		display: none;
	}

	@include breakpoint(sm) {
		min-height: 140px;

		.home-link {
			min-width: 330px;

			.osf-logo {
				min-width: 330px;
				height: auto;
			}
		}
	}

	@include breakpoint(md) {
		min-height: 140px;

		.home-link {
			min-width: 400px;

			.osf-logo {
				min-width: 400px;
				height: auto;
			}
		}

		.mobile-nav-open & {
			.mobile-nav-menu {
				width: 50vw;
			}
		}

		.mobile-nav-menu {
			box-shadow: inset 5px 0px 5px rgba($color-black, 0.1);
			transform: translateX(100%);
		}

		.mobile-nav-header {
			padding: 50px 0;
		}

		.icon-arrow-up {
			transform: rotate(90deg);
		}

		.search {
			background-color: $color-neutral-lightest;
			border: none;
			border-radius: 2em;
			margin-left: 1.25em;
			padding: 0.8em;
			width: 95%;
		}

		.site-link {
			color: $color-neutral-dark;
			font-style: italic;
		}
	}

	@include breakpoint(lg) {
		.icon-osf-logo,
		.search-btn {
			display: block;

			&.filter-btn {
				display: none;
			}
		}

		.icon-osf-logo-long,
		.open-mobile-nav,
		.mobile-nav-menu {
			display: none;
		}

		.home-link {
			margin-right: 35px;
			padding: 35px 0;
		}

		.innovation-links {
			align-items: center;
			display: flex;
			flex-grow: 1;
			justify-content: right;
			padding: 0 35px;

			.nav-element {
				@include text(kappa);
				color: $color-green-dark;
				display: block;
				padding: 0 30px;
				text-transform: uppercase;

				span,
				a {
					display: block;
					padding: 60px 0;
					position: relative;
					color: $color-green-dark;

					&:after {
						background-color: $color-green;
						bottom: 0;
						content: '';
						display: block;
						height: 8px;
						opacity: 0;
						position: absolute;
						transform: translateY(-60px);
						transition: 0.3s ease-out;
						width: 100%;
					}
				}

				&.hover {
					color: $color-black;

					span {
						&:after {
							bottom: 72px;
							opacity: 1;
						}
					}
				}
			}
		}

		.search-btn {
			cursor: pointer;
			display: flex;
			padding: 35px 0 35px 35px;

			&:hover {
				.icon-search {
					stroke: $color-green;
				}
			}

			.icon-search {
				height: 20px;
				width: 20px;
			}
		}

		.subnav-container {
			background-color: rgba(white, 0.9);
			display: block;
			height: 150px;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 140px;
			transition: opacity 0.3s ease-out;
			z-index: 10;
			pointer-events: none;

			&.active {
				opacity: 1;
				pointer-events: unset;
			}
		}

		.subnav-element {
			@include make-container();
			display: flex;
			height: 0;
			justify-content: center;
			margin: auto;
			opacity: 0;
			overflow: hidden;
			transition: opacity 0.3s ease-out;
			visibility: hidden;

			&.active {
				min-height: 150px;
				opacity: 1;
				visibility: visible;
			}

			.subnav-headline {
				@include text(theta, 24);
				color: $color-green;
				font-weight: $font-weight-regular;
				padding-bottom: 0.25em;
			}

			.subnav-description {
				@include text(kappa);
				color: $color-black;
			}
		}

		.subnav-inner {
			display: flex;
			width: 100%;
		}

		.subnav-cta {
			background-color: $color-white;
			display: flex;
			flex-grow: 1;
			opacity: 1;
			padding: 20px 25px;
			position: relative;
			width: 100%;

			&.subnav-cta-secondary {
				background-color: $color-neutral-lightest-2;
				flex-basis: 330px;
				min-width: 330px;
			}

			&:hover {
				background-color: $color-blue-lightest-2;

				.subnav-headline,
				.subnav-description {
					color: $color-neutral-darker;
				}
			}

			.icon {
				bottom: 20px;
				height: 20px;
				position: absolute;
				right: 20px;
				width: 20px;
			}
		}
	}
}

#footer-legal {
	background: #fff;
	position: relative;
	bottom: 0;
	font-size: 0.9em;
	padding: 1em;
	width: 100%;
	text-align: center;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

#footer-legal ul,
#footer-legal li {
	display: inline-block;
	margin: 0;
}

#footer-legal ul {
	margin-left: 0.5em;
	padding-left: 0;
}

#footer-legal a {
	border-left: 1px solid;
	display: block;
	color: inherit;
	line-height: 0.9em;
	margin-bottom: 0.2em;
	padding: 0 0.5em;
}

.gic-header {
	background-color: rgba($color-white, 0.9);
	box-shadow: none;
	min-height: $gic-header-height-xxs;
	padding: 20px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 4;

	&.gradient {
		@include gradient-background();
		box-shadow: $nav-inner-shadow;
		color: $color-neutral-lightest-4;

		.home-link {
			.icon {
				fill: $color-white;
			}
		}

		.open-mobile-nav {
			.icon {
				display: block;
				fill: $color-white;
			}
			.icon-menu-dark {
				display: none;
			}
		}
	}

	.navigation-container {
		@include make-container();
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	
	.home-link {
		display: flex;

		.icon {
			fill: $color-brown;
		}
	}

	.icon-osf-logo,
	.site-link {
		display: none;
	}

	.open-mobile-nav {
		cursor: pointer;

		.icon-menu {
			display: none;
		}
	}

	.close-mobile-nav {
		cursor: pointer;
	}

	.mobile-nav-menu {
		background: $color-neutral-lightest;
		height: 0;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		transition: 0.2s ease;
		visibility: hidden;
		width: 100%;

		&.open {
			height: 100vh;
			visibility: visible;
		}

		.home-link {
			.icon {
				fill: $color-brown;
				height: 34.97px;
				width: 260px;
			}
		}
	}

	.mobile-nav-menu-container {
		@include make-container();
	}

	.list-block {
		background-color: $color-white;
		padding: 1rem 1rem;
	}

	.mobile-nav-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		padding-top: 40px;
	}

	.mobile-nav-list {
		li {
			&:last-child {
				.list-block {
					background-color: $color-green-lightest;
				}
			}

			.list-block {
				color: $color-black;
			}
		}
	}

	.mobile-nav-footer {
		@include text(theta);
		align-items: center;
		background-color: $color-light-gray;
		bottom: 0;
		color: $color-black;
		display: flex;
		justify-content: space-between;
		left: 0;
		padding: 20px 30px;
		position: absolute;
		width: 100%;
	}
	.lang-nav-mobile {
		display: block;
	}
	.lang-nav-desktop {
		display: none;
	}
	
	@include breakpoint(md) {
		background-color: rgba($color-white, 0.9);
		min-height: $gic-header-height-md;
		padding-left: 0;
		padding-right: 0;

		&.gradient {
			.site-link {
				color: $color-white;
			}
		}
		.lang-nav-mobile {
			display: none;
		}
		.lang-nav-desktop {
			display: block;
		}
		.icon-osf-logo-long,
		.open-mobile-nav,
		.mobile-nav-menu {
			display: none;
		}

		.icon-osf-logo,
		.site-link {
			display: block;
		}

		.site-link {
			color: $color-neutral-dark;
		}
	}
}
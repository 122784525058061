.query-search-page {
	background-color: $color-white;
	display: flex;
	position: relative;
	padding: 12px 0 40px;

	.query-search-page-container {
		@include make-container();

		.provider-location-results {
			.featured-list {
				padding-left: 0;
				padding-right: 0;
				.featured-item {
					flex-basis: 100%;
					max-width: 100%;
				}
			}
			&::before {
				display: none;
			}
		}

		.pagination-controls {
			padding-left: 0;
			padding-right: 0;
		}

		.mobile-provider-location-links {
			display: flex;
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
			padding: 0 15px;
			width: 100%;

			.providers-link {
				@include text(iota, 20);
				align-items: center;
				background-color: $color-green-lightest;
				color: $color-black;
				display: flex;
				flex: 0 1 50%;
				height: 45px;
				justify-content: center;
			}

			.locations-link {
				@include text(iota, 20);
				align-items: center;
				background-color: $color-green-lighter;
				color: $color-black;
				display: flex;
				flex: 0 1 50%;
				height: 45px;
				justify-content: center;
			}
		}

		.query-filter-list {
			width: 100%;
			padding: 24px 8px;
			display: flex;
			flex-wrap: wrap;
			border-bottom: 2px solid $color-neutral-lightest;
			cursor: pointer;
			justify-content: space-evenly;
			align-items: center;

			.filter-item {
				padding: 8px 16px;
				border-radius: 24px;
				&.active {
					background-color: $color-neutral-lightest;
				}
				&:hover {
					background-color: $color-blue-lightest;
				}
			}
		}

		.search-listing-groups {
			.alert-listing-groups {
				margin-top: 1rem;
				mark {
					background-color: $color-blue-lightest;
				}
				.alert-listing-group {
					.alert-type {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
			.search-result-container {
				padding: 16px 0px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.filter-dropdown {
					margin-top: 1.25rem;
					select {
						padding: 5px 20px 5px 40px;
						color: $color-light-black;
					}
					.icon-chevron-right {
						right: unset;
						left: 1rem;
					}
				}

				.search-result-text {
					font-size: 14px;
					span {
						font-weight: 500;
						margin-left: 4px;
					}
				}
			}
		}
	}

	.r2-search-bar {
		width: 100%;
		.search-input-container {
			width: 100%;
			max-width: 100%;
			.search-input {
				@include text(kappa, 19);
				background-color: $color-neutral-lightest;
				border: 1.5px solid $color-neutral-lightest;
				padding: 8px 42px 8px 22px;
			}
		}
	}

	.title-filter-toggle-container {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 0 0 20px;
	}

	.provider-location-container {
		display: none;
	}

	@include breakpoint(md) {
		.query-page-row {
			@include make-row();
		}
		.title-filter-toggle-container {
			@include make-col-ready();
			margin-bottom: 20px;
		}
		.provider-location-container {
			@include make-col-ready();
			flex: 0 0 350px;
			display: block;
		}
		.search-listing-groups {
			@include make-col-ready();
			flex: 1;
			margin-left: 10px;
		}
		.query-search-page-container {
			.mobile-provider-location-links {
				display: none;
			}
			.provider-location-results {
				margin-top: 8rem;
				.search-category {
					text-transform: uppercase;
					font-size: 14px;
				}
			}
			.search-listing-groups {
				.search-result-container {
					flex-direction: row;
					justify-content: space-between;
					.filter-dropdown {
						margin-top: 0;
					}
				}
			}
		}
	}
}

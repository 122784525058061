.event-listing-variations {
	display: flex;
	flex-direction: column;
	width: 100%;

	.event-img {
		min-width: 330px;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.event-content {
		color: $color-neutral-darker;
		min-width: 330px;

		.event-details {
			background-color: $color-neutral-lightest-2;
			padding: 10px 20px;

			h1 {
				@include text(lambda, mu);
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
			}
		}

		.event-notes {
			background-color: $color-neutral-with-opacity;
			padding: 0px 20px 20px 20px;

			span {
				@include text(lambda, mu);
				color: $color-green-darker;
				font-family: $font-family-sans-serif;
			}

			p {
				@include text(lambda, mu);
				font-family: $font-family-sans-serif;
				margin: 15px 0 0 0;
			}
		}

		.learn-or-register {
			display: flex;
			flex-direction: column;

			.action-btn {
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;
				min-height: 65px;

				&:hover {
					background-color: $color-blue-lightest;
				}

				.action-text {
					@include text(theta);
				}

				p {
					@include text(kappa);
				}

				svg {
					transform: rotate(90deg);
				}
				.rotate-0 {
					transform: rotate(0deg) !important;
				}

				&:last-of-type {
					background-color: $color-green-lighter;

					&:hover {
						background-color: $color-blue-lightest;
					}
				}
			}
		}
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: row;
		max-width: 780px;

		.event-img {
			flex-basis: 30%;
			min-width: 240px;
		}

		.event-content {
			flex-basis: 70%;
			width: inherit;

			.learn-or-register {
				flex-direction: row;
			}
		}
	}
}

.right-rail-navigation {
	margin: 0 calc(var(--spacing-base) * -4);

	@include breakpoint(lg) {
		margin-left: 0;
		margin-right: 0;
	}

	.rail {
		color: #000;
		cursor: pointer;
		display: flex;

		.rail-link {
			width: 100%;
		}

		.rail-icon {
			align-items: center;
			cursor: pointer;
			display: flex;
			justify-content: center;
			width: 60px;
			flex: 0 0 60px;

			svg {
				transform: rotate(90deg);
				transition: 0.5s;

				&.active {
					transform: rotate(270deg);
				}
			}
		}
	}

	&.innovation-rail {
		.rail {
			background-color: $color-green-lightest;
			height: 82px;
			justify-content: space-between;
			margin-bottom: 2px;

			.rail-link {
				padding: 14px 20px;
				display: flex;
				align-items: center;

				a {
					color: #000;
					font-family: $font-family-sans-serif;
					@include text(kappa, 16);
				}

				&.child {
					align-items: center;

					a {
						overflow: auto;
						white-space: wrap;
					}
				}

				&:hover {
					background-color: $color-blue-lightest-2;
				}
			}

			.rail-icon {
				background-color: $color-green-lighter;

				svg {
					&.active {
						&:hover {
							background-color: $color-green-lighter;
						}
					}
				}

				&:hover {
					background-color: $color-blue-lightest-2;

					&.active {
						background-color: $color-green-lighter;
					}
				}
			}

			&.child {
				background-color: $color-green-lighter;
				margin-bottom: unset;
			}

			&:hover {
				background-color: $color-blue-lightest-2;
			}

			&.unset-mb {
				margin-bottom: unset;
			}
		}

		.rail:nth-of-type(1) {
			&:hover {
				background-color: $color-green-lightest;
			}
		}

		.sub-rail {
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.3s ease;

			&.show {
				max-height: 1800px;
				overflow: hidden;
			}

			&:hover {
				background-color: $color-blue-lightest-2;
			}

			.rail {
				&.active {
					border-left: 4px solid $color-green-dark;
				}
			}

			.rail:nth-of-type(1) {
				&:hover {
					background-color: $color-blue-lightest-2;
				}
			}
		}
	}

	&.osf-rail {
		@include breakpoint(lg) {
			@include outer-vertical-spacing;
			padding-right: 16px;
		}

		@include breakpoint(xl) {
			padding-right: 0;
		}

		.top-level {
			background-color: var(--color-primary-dark);

			@include breakpoint(lg) {
				border-radius: 10px 10px 0 0;
			}

			&.active {
				div.rail-link {
					text-decoration: none;
				}

				~ .level-1 {
					max-height: fit-content;
				}
			}

			.rail {
				.rail-link {
					color: white;
					font-size: 22px;
					font-weight: $font-weight-medium;
					line-height: 30px;
					padding: 14px 18px 14px 18px;
				}

				.rail-icon {
					@include breakpoint(lg) {
						display: none;
					}

					svg {
						stroke: white;
					}
				}
			}
		}

		.level-1 {
			background-color: #f8f7f5;
			max-height: 0;
			overflow: hidden;
			transition: all 1s ease;

			&.active,
			&:hover,
			&:focus-within {
				background-color: white;
			}

			@include breakpoint(lg) {
				max-height: fit-content;
			}

			.sub {
				border-bottom: 1px solid $color-neutral-lighter;
			}

			.rail {
				.rail-link {
					font-size: 18px;
					font-weight: $font-weight-medium;
					padding: 14px 0 14px 18px;
				}

				.rail-icon {
					svg {
						stroke-width: 2px;
						width: 8px;
					}
				}
			}

			.level-2,
			.level-3,
			.level-4 {
				max-height: 0;
				overflow: hidden;
				transition: max-height 0.3s ease;

				.outer-rail {
					&:hover:not(:has(.sub-rail:hover)) {
						&:not(.current) > a .rail-link:first-of-type {
							color: #5d5c5b;
						}
					}
				}
			}

			&.show,
			.show {
				max-height: fit-content;
				overflow: hidden;
			}

			.level-2,
			.level-3,
			.level-4 {
				background-color: white;

				.rail {
					.rail-link {
						font-weight: $font-weight-regular;
					}

					.rail-icon {
						svg {
							width: 6px;
						}
					}
				}

				.outer-rail {
					&.current > a {
						.rail-link {
							color: $color-green-dark;
							text-decoration: underline;
						}
					}
				}
			}

			.level-2 {
				.rail {
					.rail-link {
						font-size: 18px;
						padding: 14px 0 14px 28px;
					}

					.rail-icon {
						svg {
							stroke-width: 1.5px;
						}
					}
				}
			}

			.level-3 {
				.outer-rail {
					.rail {
						justify-content: space-between;
						max-width: 75%;

						.rail-link {
							font-size: 16px;
							padding: 4px 0 4px 48px;
							width: auto;
						}

						.rail-icon {
							flex: 0 0 auto;
							padding: 0 8px;
							width: auto;

							svg {
								stroke-width: 1px;
							}
						}
					}
				}
			}

			.level-4 {
				.outer-rail {
					.rail {
						.rail-link {
							font-size: 14px;
							line-height: 24px;
							padding: 2px 0 2px 73px;
						}

						&:active {
							.rail-link {
								color: $color-green-dark;
								text-decoration: underline;
							}
						}
					}
				}
			}
		}

		.outer-rail {
			.rail {
				&.active {
					.rail-link {
						text-decoration: underline;
					}
				}
			}
		}

		.level-1,
		.level-2,
		.level-3,
		.level-4 {
			.last-active {
				&::after {
					content: '';
					display: block;
					height: 1px;
					background-color: #e3dbd1;
					margin: 0;
				}
			}
		}

		@include breakpoint(lg) {
			.top-level {
				.rail {
					.rail-link {
						cursor: pointer;
					}

					&.folder {
						.rail-link {
							cursor: default;
						}
					}
				}
			}
		}

		@include breakpoint(xl) {
			.top-level {
				.rail {
					.rail-link {
						padding: 14px 10px 14px 18px;
						overflow-wrap: break-word;
						white-space: normal;
					}
				}
			}
		}

		@include breakpoint(md) {
			.level-1,
			.level-2,
			.level-3,
			.level-4 {
				.last-active {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}

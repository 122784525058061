body {
	.bg-green-dark {
		background-color: $color-green-dark;
	}

	.bg-green {
		background-color: $color-green;
	}

	.bg-green-light {
		background-color: $color-green-light;
	}

	.bg-green-lighter {
		background-color: $color-green-lighter;
	}

	.bg-green-lightest {
		background-color: $color-green-lightest;
	}

	.bg-blue-dark {
		background-color: $color-blue-dark;
	}

	.bg-blue {
		background-color: $color-blue;
	}

	.bg-blue-light {
		background-color: $color-blue-light;
	}

	.bg-blue-lighter {
		background-color: $color-blue-lighter;
	}

	.bg-blue-lightest {
		background-color: $color-blue-lightest;
	}

	.bg-neutral-darkest {
		background-color: $color-neutral-darkest;
	}

	.bg-neutral-darker {
		background-color: $color-neutral-darker;
	}

	.bg-neutral-dark {
		background-color: $color-neutral-dark;
	}

	.bg-neutral {
		background-color: $color-neutral;
	}

	.bg-neutral-light {
		background-color: $color-neutral-light;
	}

	.bg-neutral-lighter {
		background-color: $color-neutral-lighter;
	}

	.bg-neutral-lightest {
		background-color: $color-neutral-lightest;
	}

	.bg-neutral-lightest-2 {
		background-color: $color-neutral-lightest-2;
	}

	.bg-white {
		background-color: $color-white;
	}

	.bg-white-90 {
		background-color: $color-white-90;
	}

	.bg-black {
		background-color: $color-black;
		color: $color-white;
	}
}

//NEW OSF - BG COLORS
.overhauled {
	.bg-color-base {
		background-color: var(--color-base);
	}
	.bg-primary {
		background-color: var(--color-bg-primary);
	}
	.bg-secondary {
		background-color: var(--color-bg-secondary);
	}

	//Gradients
	.gradient-bg-light {
		@include osf-gradient-bg-light(90deg);
	}
	.gradient-bg-dark {
		@include osf-gradient-bg-dark(90deg);
	}

	//Base Overlay
	.overlay {
		height: 100%;
		mix-blend-mode: multiply;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;

		&.overlay-base {
			background-color: var(--color-overlay-base);

			&.gradient {
				@include breakpoint(lg) {
					&.left {
						@include osf-overlay-base-gradient(70deg);
					}
					&.right {
						@include osf-overlay-base-gradient(-70deg);
					}
				}
			}
		}

		&.overlay-dark {
			background-color: var(--color-overlay-dark);

			&.gradient {
				@include breakpoint(lg) {
					&.left {
						@include osf-overlay-dark(70deg);
					}
					&.right {
						@include osf-overlay-dark(-70deg);
					}
				}
			}
		}

		&.overlay-secondary-dark {
			background-color: var(--color-secondary-dark);

			&.gradient {
				@include breakpoint(lg) {
					&.left {
						@include osf-overlay-secondary-dark(70deg);
					}
					&.right {
						@include osf-overlay-secondary-dark(-70deg);
					}
				}
			}
		}
	}

	//Adds Shadow
	.shadow {
		box-shadow: var(--btn-shadow);
	}
}

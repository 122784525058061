.innovation-media {
	&.video {
		.media-container {
			aspect-ratio: 16/9;
		}
	}

	img,
	iframe {
		width: 100%;
		height: 100%;

		&[src*='youtube.com/embed'] {
			height: 100%;
			margin-bottom: 0;
		}
	}

	.caption {
		background-color: var(--color-bg-primary);
		padding: calc(var(--spacing-base) * 2);
	}

	@include breakpoint(md) {
		&.small {
			margin: calc(var(--spacing-base) * 3) auto;
			width: 235px;

			&.left {
				float: left;
				margin-right: calc(var(--spacing-base) * 3);
			}

			&.right {
				float: right;
				margin-left: calc(var(--spacing-base) * 3);
			}

			&.video {
				.media-container {
					aspect-ratio: 16/9;
					height: 100%;
				}
			}

			.media-container {
				aspect-ratio: 1/1;
				height: 235px;
				width: 235px;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.caption {
				padding: var(--spacing-base);
			}
		}
	}
}

.overhauled {
	.general-content,
	.custom-richtext {
		p,
		.osf-body:not(:has(p)) {
			margin-top: var(--spacing-base);

			@include breakpoint(lg) {
				margin-top: calc(var(--spacing-base) * 2);
			}
		}

		h1,
		h2 {
			color: var(--color-primary-dark);
			margin-top: calc(var(--spacing-base) * 4);

			@include breakpoint(md) {
				margin-top: calc(var(--spacing-base) * 5);
			}

			@include breakpoint(xl) {
				margin-top: calc(var(--spacing-base) * 9);
			}
		}
		&:first-of-type h1 {
			@include breakpoint(lg) {
				margin-top: 0;
				}
			}

			h3 {
				margin-top: calc(var(--spacing-base) * 3);

				@include breakpoint(md) {
					margin-top: calc(var(--spacing-base) * 4);
				}

				@include breakpoint(xl) {
					margin-top: calc(var(--spacing-base) * 6);
				}
			}

			h4,
			.osf-intro-copy {
				margin-top: calc(var(--spacing-base) * 2);

				@include breakpoint(md) {
					margin-top: calc(var(--spacing-base) * 3);
				}

				@include breakpoint(xl) {
					margin-top: calc(var(--spacing-base) * 5);
				}
			}

			h1 + p,
			h1 + .osf-body,
			h1 + ul,
			h2 + p,
			h2 + .osf-body,
			h2 + ul {
				margin-top: calc(var(--spacing-base) * 2);

				@include breakpoint(md) {
					margin-top: calc(var(--spacing-base) * 3);
				}
			}

			h3 + p,
			h3 + .osf-body,
			h3 + ul {
				margin-top: var(--spacing-base);

				@include breakpoint(md) {
					margin-top: calc(var(--spacing-base) * 2);
				}
			}

			h4 + p,
			h4 + .osf-body,
			h4 + ul {
				margin-top: calc(var(--spacing-base) / 2);

				@include breakpoint(md) {
					margin-top: var(--spacing-base);
				}
			}

			.btn-primary,
			.btn-secondary,
			.btn-tertiary {
				margin-top: calc(var(--spacing-base) * 2);

				@include breakpoint(md) {
					margin-top: calc(var(--spacing-base) * 3);
				}
			}

			table {
				td {
					min-width: 100%;

					@include breakpoint(lg) {
						min-width: unset;
					}
				}
			}

			.content-block {
				margin-bottom: 0;
			}
		}
	}

.right-rail {
	position: sticky;
	top: 0;
	margin-bottom: 40px;
	z-index: 2;

	@include breakpoint(lg) {
		z-index: auto;
	}

	.right-rail-btn {
		@include text(kappa);
		align-items: center;
		background-color: $color-green-lightest;
		cursor: pointer;
		display: flex;
		height: 60px;
		justify-content: space-between;
		font-weight: $font-weight-medium;
		padding: 20px;
		width: 100%;
		z-index: 1;

		&.btn-open {
			.icon-chevron-right {
				transform: rotate(90deg);
			}
		}

		&.btn-close {
			justify-content: flex-start;
			margin-bottom: 2px;

			.icon-chevron-right {
				margin-right: 1rem;
				transform: rotate(180deg);
			}
		}
	}

	.right-rail-container {
		background-color: $color-white;
		box-shadow: 0px -5px 23px 4px rgba($color-black, 0.15);
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
		visibility: hidden;
		width: 100%;
		z-index: 9;

		&.open {
			display: block;
			opacity: 1;
			pointer-events: auto;
			top: 0;
			transition-delay: 0s;
			visibility: visible;
			overflow: auto;
			height: 100%;
		}
	}

	.right-rail-content {
		background-color: $color-neutral-lightest-2;
		padding: 1rem 20px;
	}

	.right-rail-heading {
		@include text(kappa);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	.right-rail-subtitle {
		@include text(kappa);
		color: $color-light-black;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-medium;
		margin-bottom: 0;
	}

	.right-rail-link {
		@include text(kappa);
		color: $color-green-darker-2;
		display: block;
		font-weight: $font-weight-regular;
		margin-bottom: 1rem;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	@include breakpoint(lg) {
		position: relative;

		.right-rail-btn {
			display: none;
		}

		.right-rail-container {
			box-shadow: none;
			opacity: 1;
			pointer-events: auto;
			position: relative;
			transition: none;
			visibility: visible;
		}
	}
}

.overhauled {
	.split-content-callout {
		.content-wrapper {
			border-radius: var(--br-sm);
			display: flex;
			position: relative;
			overflow: hidden;

			.border-accent {
				@include osf-gradient-bg-light();
				width: 3px;
				height: 100%;
				z-index: 200;
				display: block;
				position: absolute;
			}
			.inner-content {
				.text-content {
					display: flex;
					flex-direction: column;
					gap: calc(var(--spacing-base) * 3);

					@include breakpoint(lg) {
						align-items: center;
						flex-direction: row;
						gap: calc(var(--spacing-base) * 6);
					}

					.left-content,
					.right-content {
						ul,
						p {
							&:last-child {
								margin-bottom: 0;
							}
						}
						li {
							@include osf-body();
							margin-bottom: calc(var(--spacing-base) * 2);

							&:last-child {
								margin-bottom: 0;
							}
						}
					}

					.left-content {
						@include breakpoint(lg) {
							flex: 1 1 54%;
						}
						.left-description {
							margin-top: calc(var(--spacing-base) * 3);
						}
					}
					.right-content {
						@include breakpoint(lg) {
							flex: 1 1 46%;
						}
					}
				}
			}
		}
	}
}

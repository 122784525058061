.overhauled {
	.separator {
		height: calc(var(--spacing-base) / 2);
		background-color: var(--color-shadows);
		margin-bottom: calc(var(--spacing-base) * 5);
		margin-top: calc(var(--spacing-base) * 5);
	}

	.separator-blank {
		height: calc(var(--spacing-base) * 5);
	}
}

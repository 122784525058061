.alert-container {
	.view-more-alerts {
		align-items: center;
		background-color: $color-neutral-lightest;
		display: flex;
		justify-content: center;
		min-height: 40px;

		.alert-link {
			@include text(kappa);
			color: $color-black;
			font-family: $font-family-sans-serif;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.system-alert-banner {
		background-color: $color-neutral-lightest;
		border-bottom: 1px solid $color-neutral-light;
		position: sticky;

		&:last-child {
			border-bottom: 0;
		}

		.alert-container {
			@include make-container();
			align-items: center;
			display: flex;
			flex-direction: column;
			padding-bottom: 20px;
			padding-top: 20px;
			position: relative;
		}

		.alert-icon {
			display: flex;
			flex: 0 1 40px;
			margin-bottom: 10px;

			.icon {
				height: 40px;
				width: 40px;
			}
		}

		.alert-description {
			@include text(kappa);
			font-family: $font-family-sans-serif;

			p {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.action-links {
			display: flex;
			justify-content: center;
			margin-top: 10px;
		}

		.action-link {
			@include text(kappa);
			color: $color-blue-dark;

			&:not(:first-of-type) {
				margin-left: 50px;
			}
		}

		.btn-close {
			display: flex;
			position: absolute;
			right: 20px;
			top: 20px;

			.icon {
				height: 14px;
				width: 14px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		@include breakpoint(md) {
			.alert-container {
				flex-direction: row;
				padding-bottom: 30px;
				padding-top: 30px;
			}

			.alert-icon {
				margin-bottom: 0;
				margin-right: 20px;
			}

			.alert-description {
				padding-right: 20px;
			}

			.action-links {
				justify-content: flex-start;
			}

			.action-link {
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			.btn-close {
				top: 30px;
			}
		}

		@include breakpoint(lg) {
			.alert-description {
				padding-right: 0;
			}
		}
	}
}

@mixin heading-0 {
	@include text(beta, 54);
	color: $color-green-dark;
	font-weight: $font-weight-light;
	margin-bottom: 1.25rem;
}

@mixin heading-1 {
	@include text(34, 38);
	color: $color-green-dark;
	font-weight: $font-weight-regular;
	margin-bottom: 1.25rem;
	font-family: $font-family-serif;
}

@mixin heading-2 {
	@include text(eta, 26);
	font-weight: $font-weight-light;
	margin-bottom: 0.3rem;
}

@mixin heading-2-regular {
	@include text(eta, 26);
	font-weight: $font-weight-regular;
	margin-bottom: 0.3rem;
}

@mixin heading-3 {
	@include text(gamma);
	font-weight: $font-weight-medium;
	letter-spacing: 0.15rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

@mixin heading-3-regular {
	@include text(theta, gamma);
	font-weight: $font-weight-regular;
}

@mixin heading-4 {
	@include text(delta, 20);
	font-weight: $font-weight-bold;
	letter-spacing: 0.1rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

@mixin heading-5 {
	@include text(epsilon);
	font-weight: $font-weight-bold;
	margin-bottom: 0.875rem;
}

@mixin heading-6 {
	@include text(zeta, 20);
	font-weight: $font-weight-medium;
	margin-bottom: 0.875rem;
}

@mixin intro-headline {
	@include text(alpha);
	color: $color-green-dark;
	font-weight: $font-weight-light;
}

@mixin headline {
	@include text(eta, 26);
}

@mixin module-title {
	@include text(mu);
	font-weight: $font-weight-medium;
	letter-spacing: 0.15rem;
	text-transform: uppercase;
}

@mixin page-title {
	@include text(iota);
	font-weight: $font-weight-bold;
}

@mixin section-title {
	@include text(lambda);
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	letter-spacing: 0.84px;
	margin-bottom: 1.25rem;
}

@mixin content-title {
	@include text(iota);
	font-weight: $font-weight-light;
	margin-bottom: 1rem;
}

@mixin content-subtitle {
	@include text(epsilon);
	font-weight: $font-weight-light;
	color: $color-green-dark;
	margin-bottom: 1rem;
}

@mixin subtitle {
	@include text(iota);
	font-weight: $font-weight-bold;
}

@mixin hero-content-title {
	@include text(34, 38);
	color: $color-green-dark;
	font-weight: $font-weight-regular;
	font-family: $font-family-serif;
	margin-bottom: 1.25rem;
}

@mixin hero-content-paragraph {
	@include text(34, 38);
	color: $color-neutral-darker;
	font-weight: $font-weight-light;
	font-family: $font-family-serif;
	margin-bottom: 1.25rem;
}

@mixin content-paragraph {
	@include text(kappa, iota);
	color: $color-neutral-darker;
	font-weight: $font-weight-light;
}

//NEW OSF - Typography Mixins
@mixin osf-home-h1 {
	font-size: var(--fs-home-h1);
	font-weight: var(--fw-regular);
	line-height: var(--lh-full);
}

@mixin osf-display-h1 {
	font-family: var(--ff-serif);
	font-size: var(--fs-display-h1);
	font-weight: var(--fw-regular);
	line-height: var(--lh-full);
}

@mixin osf-h2 {
	font-family: var(--ff-serif);
	font-size: var(--fs-h2);
	font-weight: var(--fw-regular);
	line-height: var(--lh-full);
}

@mixin osf-inner-h2 {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-inner-h2);
	font-weight: var(--fw-medium);
	line-height: var(--lh-extra);
}

@mixin osf-h3 {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-h3);
	font-weight: var(--fw-medium);
	line-height: var(--lh-extra);
}

@mixin osf-inner-h3 {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-inner-h3);
	font-weight: var(--fw-medium);
	line-height: var(--lh-extra);
}

@mixin osf-h4 {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-h4);
	font-weight: var(--fw-medium);
	line-height: var(--lh-extra);
}

@mixin osf-overview {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-overview);
	font-weight: var(--fw-regular);
	line-height: var(--lh-extra);
}

@mixin osf-intro-copy {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-intro-copy);
	font-weight: var(--fw-regular);
	line-height: var(--lh-extra);
}

@mixin osf-body {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-body);
	font-weight: var(--fw-regular);
	line-height: var(--lh-extra);
}

@mixin osf-label {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-label);
	font-weight: var(--fw-regular);
	letter-spacing: var(--ls-small);
	line-height: var(--lh-extra);
}

@mixin osf-link {
	color: var(--color-link);
	font-family: var(--ff-sans-serif);
	font-weight: var(--fw-medium);
	text-decoration: underline;

	&:hover {
		text-decoration: underline;
		text-shadow: none;
	}

	&:active,
	&:focus,
	&:focus-visible {
		color: var(--color-secondary-light);
		outline: none;
	}

	@include breakpoint(md) {
		&:hover {
			color: var(--color-secondary-light);
		}

		&:active,
		&:focus,
		&:focus-visible {
			color: var(--color-secondary-dark);
			text-decoration: none;
		}
	}
}

@mixin text-hover {
	cursor: pointer;

	&:active,
	&:focus,
	&:focus-visible {
		color: var(--color-text-hover);
	}

	@include breakpoint(md) {
		&:hover {
			color: var(--color-text-hover);
		}

		&:active,
		&:focus,
		&:focus-visible {
			color: var(--color-body-dark);
			text-decoration: underline;
		}
	}
}

@mixin osf-nav-body-copy {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-nav-body-copy);
	font-weight: var(--fw-regular);
}

@mixin osf-nav-menu-titles {
	color: var(--color-primary-dark);
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-nav-menu-titles);
	font-weight: var(--fw-bold);
}

@mixin osf-nav-section-titles {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-nav-section-titles);
	font-weight: var(--fw-medium);
}

@mixin osf-nav-top-level {
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-nav-top-level);
	font-weight: var(--fw-regular);
}

.innovation-footer {
	@include gradient-background-light();
	font-family: $font-family-sans-serif;
	padding: 4px 0px 29px;
	width: 100%;

	.footer-inner {
		background-color: $color-white;
	}

	.footer-container {
		@include make-container();
	}

	.footer-link:hover {
		text-decoration: underline;
	}

	.footer-logo {
		display: block;
		width: 200px;
		aspect-ratio: auto;
		height: 100%;
		top: 2668px;
		left: 115px;
		width: 200px;
		height: 68px;
		/* UI Properties */
		opacity: 1;
	}

	@include breakpoint(xs) {
		.footer-logo {
			top: 1416px;
			left: 36px;
			width: 200px;
			height: 68px;
			opacity: 1;
		}
	}

	.content {
		padding: 50px 0;
		width: 100%;
	}

	.link-list-block {
		margin-bottom: 43px;
	}

	@include breakpoint(xs) {
		h3.link-list-heading {
			color: #333230;
		}

		a.footer-link {
			color: #333333;
		}

		.legal {
			color: #333333;
			font-size: 14px;

			a.footer-link {
				color: #333230;
			}
		}

		img.footer-logo {
			width: 200px;
			height: 68px;
		}
	}

	@include breakpoint(md) {
		h3.link-list-heading {
			color: #000000;
		}

		a.footer-link {
			color: #333230;
		}

		.legal {
			color: #000000;
			font-size: 16px;

			a.footer-link {
				color: #000000;
			}
		}

		img.footer-logo {
			width: 200px;
			height: auto;
		}
	}

	.link-list-heading {
		@include text(lambda);
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	.footer-link {
		font-family: $font-family-sans-serif;
		color: $color-neutral-darker;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}

	.logo-link {
		display: block;
		margin-bottom: 44px;
		min-width: 190px;
		width: 190px;
	}

	.social-channels {
		display: flex;
		height: 35px;
		margin-bottom: 20px;
	}

	.social-channel-link {
		display: block;
		height: 35px;

		&:hover {
			[class$='hover'] {
				display: block;
			}
		}

		.icon {
			cursor: pointer;
			height: 35px;
			margin-right: 8px;
			width: 35px;

			&:hover {
				filter: invert(100%) sepia(100%) saturate(950%) hue-rotate(153deg) brightness(111%) contrast(100%);
			}
		}

		[class$='hover'] {
			display: none;
		}
	}

	.innovation-and-social {
		.social-channels {
			display: none;
		}
	}

	.legal {
		margin-top: 24px;

		.footer-link,
		.copyright-text {
			@include text(lambda);
			color: $color-black;
		}

		.copyright-text {
			font-family: $font-family-sans-serif;
			margin-bottom: 0;
		}

		.footer-link {
			display: inline-block;
		}
	}

	.link-list-item {
		display: inline;
		margin-bottom: 0;
		margin-right: 0.25rem;

		&:after {
			content: '|';
			display: inline-block;
			font-style: normal;
			margin-left: 0.25rem;
		}

		&:first-child {
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;

			&:after {
				display: none;
			}
		}
	}

	@include breakpoint(522px) {

		.links-and-resources {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.innovation-and-social,
		.related-resources {
			flex-basis: 50%;
			min-width: 50%;
		}

		.innovation-and-social,
		.resources-and-legal {
			flex-basis: 50%;
			max-width: 50%;
		}

		.innovation-and-social {
			.link-list-block {
				margin-bottom: 18px;
			}

			.social-channels {
				display: flex;
			}
		}

		.resources-and-legal {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	@include breakpoint(842px) {
		.content {
			display: flex;
			justify-content: space-evenly;
		}

		.logo-and-social {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;

			.social-channels {
				display: flex;
			}
		}

		.logo-link {
			margin-bottom: 0;
			min-width: 200px;
			width: 200px;
		}

		.links-and-resources {
			flex-grow: 1;
			justify-content: space-between;
			width: auto;
		}

		.innovation-and-social {
			.social-channels {
				display: none;
			}

			.link-list-block {
				margin-bottom: 0;
			}
		}

		.link-list-heading {
			font-family: $font-family-sans-serif;
			margin-bottom: 20px;
		}
	}

	@include breakpoint(lg) {
		.logo-and-social {
			max-width: 450px;
		}
	}
}

.scrollable-content {
	min-height: 100vh;
	min-width: 100%;
	position: relative;

	.content {
		@include make-row();
	}

	.content-container {
		@include make-container();
		padding-top: 45vh;
	}

	@include breakpoint(md) {
		.content-container {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 100%;
			width: 700px;
			padding-bottom: 100px;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

.heading-0 {
	@include heading-0();
}

.heading-1 {
	@include heading-1();
}

.heading-2 {
	@include heading-2();
}

.heading-2-regular {
	@include heading-2-regular();
	color: $color-black;
	font-family: $font-family-sans-serif;
}

.heading-3 {
	@include heading-3();
}

.heading-4 {
	@include heading-4();
}

.heading-5 {
	@include heading-5();
}

.heading-6 {
	@include heading-6();
}

.subtitle {
	@include subtitle();
}

.page-title {
	@include page-title();
}

.section-title {
	@include section-title();
	color: $color-light-black;
	font-family: $font-family-sans-serif;
	font-weight: 700;
	@include breakpoint(xs) {
		font-size: 16px;
	}
}

.content-title {
	@include content-title();
}

.content-subtitle {
	@include content-subtitle();
}

.hero-content-title {
	@include hero-content-title();
	font-family: $font-family-serif;
	@include breakpoint(xs) {
		font-size: 24px;
	}
	@include breakpoint(md) {
		font-size: 34px;
	}
}

.lead {
	@include heading-2;
	color: $color-black;
	font-family: $font-family-sans-serif;
}

.hero-content-paragraph {
	@include hero-content-paragraph();
	color: $color-neutral-darker;
	font-family: $font-family-serif;
	@include breakpoint(xs) {
		font-size: 24px;
	}
	@include breakpoint(md) {
		font-size: 34px;
	}
}

.content-paragraph {
	@include content-paragraph();
	p {
		@include content-paragraph();
	}
}

/*OSF Rich Text Editor styles: start*/
.osf-marquee-headline {
	@include heading-0();
	font-family: $font-family-sans-serif;
}
.osf-intro-headline {
	@include heading-1();
	font-family: $font-family-sans-serif;
}
.osf-heading-2-black {
	@include heading-2();
	font-family: $font-family-sans-serif;
	color: #000;
}
.osf-heading-2-green {
	@include heading-2();
	font-family: $font-family-sans-serif;
	color: #64a70b;
}
.osf-heading-2-white {
	@include heading-2();
	font-family: $font-family-sans-serif;
	color: #fff;
}
.osf-section-title {
	@include section-title();
	font-family: $font-family-sans-serif;
}
.osf-page-title {
	@include page-title();
	font-family: $font-family-sans-serif;
}
.osf-subtitle {
	@include subtitle();
	font-family: $font-family-sans-serif;
}

.osf-module-title {
	@include module-title();
	font-family: $font-family-sans-serif;
}
/*OSF Rich Text Editor styles: end*/

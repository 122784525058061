.contact-card {
	background-color: $color-neutral-lightest-2;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;

	.contact-info {
		display: flex;
		flex-direction: column;
		padding: 20px 15px;
	}

	.contact-img {
		aspect-ratio: 1/1;
		height: 100%;
		width: 100%;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.contact-name {
		@include text(kappa, 20);
		color: $color-neutral-darker;
		font-weight: $font-weight-bold;
		letter-spacing: 1px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	.contact-title {
		@include text(kappa, 20);
		color: $color-neutral-darker;
		display: block;
		font-weight: $font-weight-regular;
		margin-bottom: 2rem;
	}

	.contact-methods {
		display: flex;
		flex-direction: column;
	}

	.contact-email {
		@include text(kappa, 20);
		color: $color-blue-dark;
		font-weight: $font-weight-regular;
		margin-bottom: 10px;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.contact-phone {
		@include text(kappa, 20);
		color: $color-blue-dark;
		font-weight: $font-weight-regular;
		margin-bottom: 10px;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;

		.contact-info {
			justify-content: space-between;
		}

		.contact-img {
			height: 240px;
			width: 240px;
		}
	}
}

.osf-header-nav {
	background-color: var(--color-base);
	color: var(--color-body-dark);
	z-index: 999999;

	border-top: 10px solid transparent;
	border-image: linear-gradient(
			270deg,
			var(--color-primary-light) 13.57%,
			var(--color-secondary-light) 52.32%
		)
		1;
	height: 126px;

	&.home {
		height: 82px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		box-shadow: 0px 10px 20px var(--color-btn-hover-shadow);
		pointer-events: none;
	}
	display: flex;
	position: sticky;
	top: 0;
	width: 100%;
	flex-direction: column;

	.back-button {
		position: absolute;
		display: flex;
		align-items: center;
		padding: 8px 21px;

		@include breakpoint(xl) {
			margin: 0 calc(var(--spacing-base) * 12) 0 calc(var(--spacing-base) * 12);
		}

		&-link {
			@include text(kappa, 16);
			color: var(--color-primary-dark);
			font-weight: $font-weight-regular;
		}
		.icon {
			margin-right: 8px;
			svg {
				fill: var(--color-primary-dark);
				transform: rotate(90deg);
			}
		}
	}
	.navbar {
		border-top: 10px solid transparent;
		border-image: linear-gradient(
				270deg,
				var(--color-primary-light) 13.57%,
				var(--color-secondary-light) 52.32%
			)
			1;
		border-radius: 0px 0px 10px 10px;
		box-shadow: 0px 0px 17px 0px var(--color-btn-hover-shadow);
		background-color: var(--color-base);

		padding: 1rem 21px;
		align-items: center;
		display: flex;
		height: auto;
		z-index: 2;
		justify-content: space-between;
		position: fixed;
		top: 0;
		width: 100%;
		flex-direction: column;

		.icon-osf-logo {
			fill: var(--color-secondary-dark);
			height: 40px;
			width: 108px;
		}
		.nav-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 40px;
			img {
				height: 40px;
				width: auto;
			}
		}
		.osf-logo {
			width: 100%;
		}
		.back-button {
			position: relative;
			left: 0;
			padding: 0 8px 24px 0;
			align-self: flex-start;
		}
	}

	.menu-open {
		align-items: center;
		display: flex;
		.icon.icon-menu {
			width: 26px;
		}
		&:hover {
			cursor: pointer;
		}
	}

	.nav-menu {
		background-color: var(--color-base);
		display: none;
		opacity: 0;
		overflow: auto;
		pointer-events: none;

		&.mobile-nav-open {
			animation: fade-in 0.4s;
			display: block;
			height: 100%;
			left: 0;
			opacity: 1;
			z-index: 100000001;
			pointer-events: auto;
			position: fixed;
			top: 0;
			width: 100%;

			@keyframes fade-in {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
		}

		&.closing {
			animation: fade-out 0.4s;
			opacity: 0;

			@keyframes fade-out {
				from {
					opacity: 1;
				}
				to {
					opacity: 0;
				}
			}
		}
	}
	.nav-search {
		visibility: hidden; // done for #7570
	}
	.nav-content {
		margin-top: 126px;
		.osf-logo {
			display: none;
		}
		.featured-item-wrapper {
			flex: 0 1 100%;
			border-bottom: none;
			&:first-of-type {
				border-top: 0;
			}
		}
		.featured-items {
			display: flex;
			gap: 1rem;
			padding: 32px 40px 24px 40px;
			display: flex;
			justify-content: space-between;
			padding: 1rem 2rem;
			// border-bottom: 1px solid var(--color-shadows);
		}
		.featured-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-decoration: none;
			color: var(--color-body-dark);
			flex: 1;
			text-align: center;
			gap: 1rem;
			.featured-text {
				@include text(kappa, 16);
				font-weight: $font-weight-regular;
			}
		}

		.featured-icon {
			width: 40px;
			height: 50px;
			margin-bottom: 0.5rem;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			svg {
				width: 100%;
				height: 100%;
				max-width: 40px;
				max-height: 40px;
				fill: var(--color-primary-light);
				& #nurse {
					max-height: 50px;
				}
			}
		}
	}

	.nav-header {
		align-items: center;
		display: grid;
		grid-template-columns: auto auto;
		grid-template-rows: 1fr;
		justify-content: space-between;
		padding: 1rem 21px;
		border-bottom: 1px solid var(--color-shadows);
		border-top: 10px solid transparent;
		border-image: linear-gradient(
				270deg,
				var(--color-primary-light) 13.57%,
				var(--color-secondary-light) 52.32%
			)
			1;
		position: fixed;
		width: 100%;
		background-color: var(--color-base);
		> *:first-child {
			grid-column: 1 / -1;
		}

		> *:nth-child(2),
		> *:nth-child(3) {
			grid-column: span 1;
		}
		.icon-osf-logo {
			fill: var(--color-secondary-dark);
			height: 40px;
			width: 108px;
			img {
				height: 40px;
				width: 108px;
			}
		}
		.osf-logo {
			img {
				height: 40px;
				width: auto;
			}
		}
		.back-button {
			position: relative;
			left: 0;
			padding: 0 8px 24px 0;
			align-self: flex-start;
		}
	}

	.menu-close {
		padding-top: 8px;
		&:hover {
			cursor: pointer;
		}
	}

	.input-icon-container {
		margin-bottom: 1rem;
		position: relative;

		input[type='text'] {
			@include text(kappa, 19);
			border: 1.5px solid var(--color-overlay);
			padding: 9px 42px 9px 22px;
		}

		.icon {
			position: absolute;
			right: 18px;
			stroke: var(--color-primary-light);
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.search-btn {
		@include text(kappa, 19);
		border: 1.5px solid var(--color-overlay);
		margin-bottom: 1rem;
		padding: 9px 42px 9px 22px;
		position: relative;

		.icon {
			position: absolute;
			right: 22px;
			stroke: var(--color-primary-light);
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover,
		&:focus {
			background-color: var(--color-base);
		}
	}

	.main-nav {
		margin-bottom: 1rem;
	}

	.nav-action {
		padding: 1.5rem 1rem;
		border-bottom: 1px solid var(--color-shadows);
		&:first-of-type {
			border-top: 1px solid var(--color-shadows);
		}

		&.has-sub-nav {
			&.active {
				.sub-nav-menu {
					animation: slide-in 0.4s;
					display: block;
					transform: translateX(0);

					@keyframes slide-in {
						from {
							transform: translateX(100%);
						}
						to {
							transform: translateX(0);
						}
					}
				}
			}
			&.closing {
				.sub-nav-menu {
					animation: slide-out 0.4s;
					transform: translateX(100%);

					@keyframes slide-out {
						from {
							transform: translateX(0);
						}
						to {
							transform: translateX(100%);
						}
					}
				}
			}
		}
	}

	.nav-action-btn {
		@include text(theta, iota);
		letter-spacing: -0.22px;
		font-weight: $font-weight-regular;
		font-family: $font-family-sans-serif;

		color: var(--color-body-dark);

		align-items: center;
		display: flex;

		justify-content: space-between;
		padding: 0 15px;
		text-align: left;
		width: 100%;

		&.get-care-btn {
			.icon {
				stroke-width: 3px;
			}
		}

		&:hover {
			cursor: pointer;
			color: var(--color-primary-dark);
		}

		.icon {
			margin-left: 0.25rem;
			width: 11px;
		}

		.icon-arrow-go {
			height: 1.2rem;
		}

		.chevron-right-icon {
			stroke: var(--color-body-dark);
			stroke-width: 1px;
		}
	}

	.sub-nav-menu {
		background-color: var(--color-base);
		display: none;
		height: 100%;
		left: 0;
		overflow: auto;
		padding: 0;
		position: fixed;
		bottom: 0;
		z-index: 2;
		width: 100%;

		height: calc(100% - 126px);
		.featured-item {
			background-color: var(--color-base);

			&:hover,
			&:focus {
				background-color: var(--color-secondary-lighter);
			}
		}
	}

	.sub-nav-mobile-header {
		align-items: center;
		display: flex;
		height: 70px;
		justify-content: space-between;

		.mobile-nav-back-btn {
			align-items: center;
			cursor: pointer;
			display: flex;
			justify-content: flex-start;

			.icon {
				height: 16px;
				transform: rotate(180deg);
				width: 8px;
			}
		}

		.mobile-nav-close-btn {
			align-items: center;
			cursor: pointer;
			display: flex;
			justify-content: center;

			.icon {
				height: 16px;
				width: 16px;
			}
		}
	}

	.sub-nav-title {
		@include text(lambda, 20);
		color: var(--color-body-dark);
		font-weight: $font-weight-bold;
		margin: 0;
	}

	.sub-nav-section {
		&:not(:first-of-type) {
			margin-top: 20px;
		}
	}

	.sub-nav-heading {
		@include text(iota, 20);
		color: var(--color-body-dark);
		font-weight: $font-weight-medium;
		margin: 0 0 15px;
	}

	.featured-list {
		margin-bottom: 0;
		padding: 0;

		.featured-item {
			flex: 0 1 100%;
			max-width: 100%;
			justify-content: flex-start;
			align-items: start;
		}
	}
	.sub-link-item {
		@include text(iota, 20);
		padding: 1.5rem 1rem;
		ul {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			a {
				color: var(--color-body-dark);
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.sub-link-heading {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
		&:has(> .sub-link-heading) {
			font-weight: $font-weight-medium;
			border-bottom: 1px solid var(--color-shadows);
			ul {
				display: none;
			}
		}
		svg {
			height: 17px;
			width: 1rem;
			stroke-width: 1px;
			color: var(--color-body-dark);
		}
		&.nav-action {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.sub-nav-link-list {
		li {
			margin-bottom: 0;
		}
		.sub-nav-link {
			@include text(kappa, 20);
			color: var(--color-body-dark);
			display: inline-block;
			margin-bottom: 15px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.sub-nav-btn-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			flex: 0 1 calc(50% - 10px);
		}

		.sub-nav-link-btn {
			@include text(kappa, 20);
			align-items: center;
			background-color: var(--color-base);
			color: var(--color-body-dark);
			display: flex;
			justify-content: center;
			margin-bottom: 15px;
			padding: 8px;
			text-align: center;
			width: 100%;

			&:hover,
			&:focus {
				background-color: var(--color-secondary-lighter);
			}
		}
	}

	.view-all-link {
		@include text(kappa, 20);
		color: var(--color-body-dark);
		margin-top: 1rem;

		&:hover {
			text-decoration: underline;
		}
	}

	.nav-utility {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		padding: 1rem;
		position: relative;
	}

	.utility-links {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-bottom: 1rem;
		padding: 0 1rem;
		position: relative;
	}

	.utility-link {
		@include text(iota, kappa);
		align-items: center;
		color: var(--color-body-dark);
		display: inline-flex;
		cursor: pointer;
		&:hover,
		&:focus {
			color: var(--color-primary-dark);
			text-decoration: underline;
		}

		.icon {
			fill: var(--color-primary-light);
			margin-right: 0.25rem;
		}
	}

	.zip-btn {
		min-width: 46px;

		&:hover {
			cursor: pointer;
		}

		&.geolocating {
			justify-content: flex-start;
			text-decoration: none;

			&:after {
				-webkit-animation: ellipsis steps(4, end) 900ms infinite;
				animation: ellipsis steps(4, end) 900ms infinite;
				content: '\2026'; /* ascii code for the ellipsis character */
				display: inline-block;
				overflow: hidden;
				vertical-align: bottom;
				width: 0px;
			}

			@keyframes ellipsis {
				to {
					width: 12px;
				}
			}
		}
	}

	.icon {
		fill: var(--color-body-dark);
	}

	@include breakpoint(xl) {
		.nav-container {
			background-color: var(--color-base);
			margin: 45px calc(var(--spacing-base) * 12) 36px calc(var(--spacing-base) * 12);
			position: relative;
			width: auto;
			height: auto;
		}

		.navbar {
			display: none;
		}

		.nav-menu {
			align-items: center;
			display: flex;
			opacity: 1;
			overflow: visible;
			pointer-events: auto;
		}

		.nav-content {
			align-items: flex-end;
			flex-direction: row-reverse;
			display: flex;
			flex: 1;
			flex-wrap: wrap;

			.osf-logo {
				display: block;
				margin-bottom: 1rem;

				&:focus:not(.focus-visible),
				&:focus:not(&:focus-visible),
				&:focus-visible {
					outline: none;
				}

				.icon-osf-logo {
					fill: var(--color-secondary-dark);
					height: 50px;
					width: 147px;

					img {
						&:focus:not(.focus-visible),
						&:focus-visible {
							outline: none;
						}
					}
				}
			}
		}

		.menu-close {
			display: none;
		}

		.nav-search {
			margin-left: auto;
			position: relative;
		}

		.input-icon-container {
			margin-bottom: 1.25rem;

			input[type='text'] {
				padding: 5px 42px 5px 22px;
				width: 275px;
			}
		}

		.search-btn {
			margin-bottom: 1.25rem;
			padding: 5px 42px 5px 22px;
			width: 275px;
		}

		.main-nav {
			display: flex;
			margin-bottom: 0;
			order: 1;
			position: static;
			width: 100%;
			align-items: baseline;
			justify-content: flex-end;
		}

		.nav-action {
			margin-bottom: 0;
			margin: 0;
			border-bottom: none;
			padding: 0;
			cursor: pointer;

			&:first-of-type {
				border-top: none;
			}
			&.has-sub-nav:before {
				content: '';
				display: block;
				height: 72px;
				position: absolute;
				top: 20px;
				left: 0;
				right: 0;
				background-color: transparent;
				pointer-events: auto;
				z-index: 1;
			}
			&:last-of-type {
				margin-right: 0;
			}

			&.has-sub-nav {
				position: relative;
				.nav-action-btn {
					&:before {
						content: '';
						cursor: default;
						display: block;
						width: 0;
						height: 4px;
						left: 0;
						pointer-events: auto;
						position: absolute;
						top: 130%;
						border-bottom: 3px solid $color-neutral-darker;
						z-index: 999999999;
						transition: width 0.3s ease;
					}
				}
				&:hover,
				&:focus-within {
					.nav-action-btn {
						position: relative;
						transition: all 0.3s ease;
						color: var(--color-primary-dark);

						.icon {
							transform: rotate(-90deg);
							display: flex;
							align-items: center;
							justify-content: center;
						}

						&:after {
							content: '';
							cursor: default;
							display: block;
							height: 4px;
							left: 0;
							pointer-events: auto;
							position: absolute;
							top: 100%;
							width: 100%;
							z-index: 1;
						}
						&:before {
							width: 100%;
						}
					}
					.sub-nav-menu {
						opacity: 1;
						visibility: visible;
						display: block;
						transform: translate(-50%, 0);
						z-index: 2;
						&.closed {
							display: none;
						}
					}
				}

				.nav-action-btn {
					pointer-events: auto;
					position: relative;
					z-index: 2;
				}
				&.active {
					.sub-nav-menu {
						animation: none;
						transform: none;
					}
				}
			}
		}

		.nav-action-btn {
			@include text(iota, 20);
			color: var(--color-body-dark);
			font-weight: $font-weight-medium;
			padding: 0.5rem 1rem;

			.icon {
				display: none;
			}
		}

		.sub-nav-menu {
			background-color: var(--color-base);
			border-radius: 0px 0px 10px 10px;
			box-shadow: 0px 0px 17px 0px var(--color-btn-hover-shadow);
			display: block;
			overflow: auto;
			height: auto;
			width: calc(100% - (var(--spacing-base) * 16));
			z-index: 1;
			padding: 42px 56px 56px 56px;
			clip-path: inset(20px -20px -20px -20px);
			position: fixed;
			top: 187px;
			bottom: unset;
			left: 50%;
			transform: translate(-50%, -20px);
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
			cursor: default;

			&.active {
				display: flex;
			}
		}

		.sub-nav-mobile-header {
			display: none;
		}
		.sub-nav-title {
			text-transform: uppercase;
		}

		.sub-nav-content {
			display: flex;
			justify-content: space-evenly;
			gap: 32px;
		}

		.sub-nav-column {
			.sub-link-item:not(:first-child) {
				.sub-link-heading {
					margin-top: 40px;
				}
			}
			.sub-link-item {
				.sub-link-heading {
					@include text(iota, 27);
					color: $color-green-dark;
					font-weight: $font-weight-medium;
					margin-bottom: 18px;
				}
				ul {
					list-style: none;
					padding-left: 0;
					margin-bottom: 6px;
					li {
						margin-bottom: 0;
						margin-left: 0;
						a {
							@include text(iota, epsilon);
							color: $color-black;
							font-weight: $font-weight-regular;
							text-decoration: none;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
				padding: 0;
				font-weight: initial;
				border-bottom: none;

				&:has(> .sub-link-heading) {
					font-weight: initial;
					border-bottom: none;

					ul {
						display: block;
					}
				}
			}
		}

		.sub-nav-section {
			&:not(:first-of-type) {
				border-left: 1px solid $color-white;
				margin-left: 40px;
				margin-top: 0;
				padding-left: 40px;
			}
		}

		.sub-nav-heading {
			@include text(theta, 20);
			color: var(--color-body-dark);
			font-weight: $font-weight-bold;
			margin-bottom: 1rem;
		}

		.sub-nav-link-list {
			max-width: 200px;
			min-width: 125px;
			.sub-nav-link {
				@include text(iota, 20);
				margin-bottom: 0.5rem;

				&:hover {
					color: var(--color-primary-dark);
				}
			}
		}

		.sub-nav-btn-list {
			flex-direction: column;
			flex-wrap: unset;
			max-width: 200px;
			min-width: 200px;

			li {
				flex: 0 1 100%;
			}

			.sub-nav-link-btn {
				@include text(iota, 20);
				justify-content: flex-start;
				margin-bottom: 0.5rem;
				padding: 0.5rem 1rem;
			}
		}

		.view-all-link {
			@include text(iota, 20);
			margin-top: 1.5rem;
		}

		.nav-utility {
			padding: 0;
			margin-bottom: 0;
		}

		.utility-links {
			align-items: center;
			height: 32px;
			justify-content: flex-end;
			margin-bottom: 1.25rem;
			margin-left: 35px;
			padding: 0;
			flex-direction: row;
			gap: unset;
		}

		.utility-link {
			@include text(iota, kappa);
			font-weight: $font-weight-regular;
			margin-left: 1.5rem;

			&:first-child {
				margin-left: 0;
			}
		}

		.custom-cta-btn {
			padding: 6px 20px;
			border-radius: 5px;
			font-weight: $font-weight-regular;
			color: $color-white;
		}
		.sub-nav-menu {
			max-height: calc(100vh - 125px);
		}
		.nav-header {
			border-top: 0;
			display: block;
			margin-bottom: 0;
			margin-right: 57px;
			border-bottom: none;

			.icon-osf-logo img {
				height: 76px;
				width: 205px;
			}
			.osf-logo {
				img {
					height: 88px;
					width: auto;
				}
			}
		}
		.nav-utility {
			z-index: 2;
		}
		.utility-links {
			margin-left: 57px;
			padding: 0;
		}

		.utility-link {
			margin-left: 32px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	@include breakpoint(xl) {
		height: auto;

		&.home {
			height: auto;
		}
		.nav-header {
			position: static;
			background-color: unset;
			width: auto;
		}
		.nav-content {
			margin-top: unset;
		}
		.nav-menu {
			padding: 0;
		}

		.input-icon-container {
			input[type='text'] {
				width: 315px;
			}
		}

		.search-btn {
			width: 315px;
		}

		.nav-content {
			align-items: flex-end;

			.osf-logo {
				display: none;
			}
		}

		.nav-action-btn {
			&.get-care-btn {
				height: 39px;
			}
		}
		.nav-container {
			height: 116px;
		}
	}

	.card-list-item {
		list-style-type: none;
		max-width: 522px;
		margin: 0;
		padding: 0;
	}

	.card {
		display: flex;
		background-color: #f8f8f8;
		border-radius: 8px;
		overflow: hidden;

		.card-image {
			flex: 0 0 50%;
			max-height: 322px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.card-content {
			flex: 1;
			padding: 20px;
			display: flex;
			flex-direction: column;
		}

		.card-heading {
			color: var(--color-primary-dark);
			@include text(theta, 22);
			font-weight: $font-weight-regular;
			margin-top: 0;
			margin-bottom: 1.5rem;
		}

		.card-description {
			@include text(iota, 25);
			color: var(--color-body-dark);
			leading-trim: both;
			text-edge: cap;
			font-style: normal;
			margin-bottom: 1.5rem;
			font-weight: $font-weight-light;
			color: var(--color-body-dark);
		}

		.card-link {
			@include text(kappa, 16);
			color: var(--color-primary-dark) !important;
			text-decoration: none;
			font-weight: $font-weight-bold;
			&:after {
				content: ' >';
				font-size: 1.1em;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
	@include breakpoint(xxl) {
		.sub-nav-menu {
			width: calc(100% - (var(--spacing-base) * 24));
		}
		.nav-container {
			margin: 45px calc(var(--spacing-base) * 12) 36px calc(var(--spacing-base) * 12);
		}
	}
}

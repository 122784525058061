.overhauled {
	.hospital-location {
		border-radius: var(--br-md);
		padding-left: calc(var(--spacing-base) * 3);
		padding-right: calc(var(--spacing-base) * 3);

		@include breakpoint(md) {
			display: flex;
			flex-direction: row-reverse;
			column-gap: calc(var(--spacing-base) * 4);
		}

		.header {
			font-weight: var(--fw-bold);
			margin-bottom: calc(var(--spacing-base) * 2);
		}

		.address {
			font-weight: var(--fw-light);
			margin-bottom: var(--spacing-base);
		}

		.contact {
			margin-bottom: var(--spacing-base);

			p {
				font-weight: var(--fw-light);
			}
		}

		.m-0 {
			margin: 0;
		}

		@include breakpoint(md) {
			.location-details {
				flex: 1 1 40%;
			}
		}

		.location {
			border-radius: var(--br-md);
			overflow: hidden;

			@include breakpoint(md) {
				flex: 0 0 60%;
			}

			iframe {
				height: 100%;
			}

			&-section {
				align-items: center;
				display: flex;
				margin-bottom: calc(var(--spacing-base) * 2);

				@include breakpoint(md) {
					margin-bottom: 0;
				}

				span {
					font-size: var(--fs-label);
				}
			}
		}
	}
}

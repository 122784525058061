.media-carousel {
	margin: 0 auto;
	max-width: 470px;
	position: relative;

	&::after {
		background: rgb(2, 0, 36);
		background: linear-gradient(
			90deg,
			rgba(2, 0, 36, 0) 0%,
			rgba(255, 255, 255, 1) 35%
		);
		content: '';
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 75px;
		z-index: 1;
	}

	&::before {
		background: rgb(2, 0, 36);
		background: linear-gradient(
			270deg,
			rgba(2, 0, 36, 0) 0%,
			rgba(255, 255, 255, 1) 35%
		);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 75px;
		z-index: 1;
	}

	.image-container {
		display: flex;
		gap: 20px;
		height: 150px;
		margin: unset;
		overflow: auto;

		//hide scroll
		-ms-overflow-style: none;
		overflow-x: scroll;
		position: relative;
		scroll-snap-type: x mandatory;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}

		.img-wrapper {
			height: 150px;
			padding-right: 15px;
			position: absolute;
			scroll-snap-align: center;
			transition: all 0.5s;
			width: 165px;

			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}

	.control {
		display: flex;
		justify-content: space-between;
		left: 0;
		position: absolute;
		top: 57px;
		width: auto;

		.control-next,
		.control-prev {
			align-items: center;
			background-color: $color-neutral-lightest-2;
			border-radius: 50%;
			cursor: pointer;
			display: flex;
			height: 40px;
			justify-content: center;
			pointer-events: auto;
			width: 40px;

			&:hover {
				background-color: $color-blue-lightest-2;
			}

			&:focus {
				background-color: $color-green-mid-light;
			}
		}

		.control-prev {
			transform: rotate(180deg);
		}

		&.disabled {
			visibility: hidden;
		}
	}

	@include breakpoint(md) {
		max-width: 772px;
		position: relative;

		&::after,
		&::before {
			content: unset;
		}

		.image-container {
			height: 204px;
			margin: 0px 60px;
			overflow: hidden;

			.img-wrapper {
				height: 204px;
				padding-right: 20px;
				width: 225px;
			}
		}

		.control {
			top: 82px;
			width: 772px;

			&.disabled {
				visibility: visible;
			}
		}
	}
}

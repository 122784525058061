.overhauled {
	.link-group {
		.inner-container {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: calc(var(--spacing-base) * 4);
			text-align: center;

			@include breakpoint(md) {
				gap: calc(var(--spacing-base) * 3);
			}

			@include breakpoint(lg) {
				gap: calc(var(--spacing-base) * 6);
			}

			.heading {
				color: var(--color-primary-dark);
			}

			.wrapper {
				.link-items {
					column-gap: calc(var(--spacing-base) * 2);
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					row-gap: calc(var(--spacing-base) * 4);

					@include breakpoint(sm) {
						column-gap: calc(var(--spacing-base) * 3);
					}

					@include breakpoint(md) {
						column-gap: calc(var(--spacing-base) * 6);
						row-gap: calc(var(--spacing-base) * 3);
					}

					@include breakpoint(lg) {
						column-gap: calc(var(--spacing-base) * 3);
					}

					@include breakpoint(xxl) {
						column-gap: calc(var(--spacing-base) * 8);
					}

					.link-item {
						display: flex;
						flex: 0 0 calc(50% - var(--spacing-base));
						flex-direction: column;
						gap: var(--spacing-base);

						@include breakpoint(sm) {
							flex: 0 0 calc(50% - calc((var(--spacing-base) * 3) / 2));
						}

						@include breakpoint(md) {
							flex: 0 0 calc(50% - calc((var(--spacing-base) * 3)));
						}

						@include breakpoint(lg) {
							flex: 0 0 calc(25% - calc(((var(--spacing-base) * 3) * 3) / 4));
						}

						@include breakpoint(xxl) {
							flex: 0 0 calc(25% - calc(((var(--spacing-base) * 8) * 3) / 4));
						}

						.img-container {
							align-items: center;
							display: flex;
							flex-grow: 1;
							height: 180px;
							justify-content: center;
							max-height: 180px;
							overflow: hidden;

							img {
								height: 100%;
								object-fit: contain;
								width: auto;
								border-radius: var(--br-md);
							}
						}

						p {
							margin-bottom: 0;
						}

						.link {
							justify-content: center;
							text-align: left;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

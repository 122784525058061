.article-listing-filters {
	background-color: $color-neutral-lightest-2;
	margin-bottom: 2rem;
	padding: 2rem 1rem;

	.filter-header {
		border-bottom: 1px solid $color-light-black;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.25rem;
		padding: 0 0 1.25rem;
	}

	.filter-title {
		@include text(lambda, 20);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		letter-spacing: 1px;
		margin: 0;
		text-transform: uppercase;
	}

	.result-count {
		@include text(lambda, 20);
		color: $color-light-black;
		letter-spacing: 1px;
		text-transform: uppercase;
	}

	label {
		@include text(lambda, 20);
		color: $color-light-black;
		display: block;
		font-weight: $font-weight-medium;
		margin-bottom: 5px;
		margin-left: 10px;
	}

	.input-icon-container {
		margin-bottom: 1rem;
		position: relative;

		span {
			@include text(kappa);
			color: $color-neutral-darker;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.icon {
			position: absolute;
			right: 16px;
			stroke: $color-green;
			top: 50%;
			transform: translateY(-50%);
		}

		input {
			&::placeholder {
				color: $color-black;
				font-size: 16px;
			}
		}
	}

	.form-select-wrapper {
		background-color: $color-white;
		display: block;
		margin-bottom: 1rem;

		.chevron-down {
			top: 30%;
			position: absolute;
			stroke: #5a8127;
			right: 1rem;
		}

		select {
			color: $color-light-black;
		}

		.icon {
			stroke: $color-green;
			&.icon-chevron-down {
				top: 40%;
				position: absolute;
				stroke: $color-green;
				transform: rotate(90deg);
				right: 1rem;
			}
			&.icon-chevron-down:before {
				content: '';
			}
		}
	}

	@include breakpoint(md) {
		.article-filters {
			display: flex;
			justify-content: space-between;
		}
	}
}

.tooltip {
	display: block;
	position: relative;

	.color-neutral-lightest {
		background-color: $color-neutral-lightest;
		color: $color-neutral-darker;
	}

	.tooltip-heading {
		color: $color-black;
	}

	.tooltip-description {
		color: $color-white;
	}

	.content {
		border-radius: 5px;
		position: absolute;
		visibility: hidden;
		width: 280px;
		z-index: 1;

		.data-content {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			display: flex;
			justify-content: space-between;
			padding: 17px 20px 13px 20px;

			.text {
				@include text(kappa, lambda);
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;

				.link-text {
					color: $color-green-dark;
					padding: 20px 0;
				}

				p {
					@include text(kappa, lambda);
					font-family: $font-family-sans-serif;
					font-weight: $font-weight-regular;
				}
			}

			.x-icon {
				cursor: pointer;
				padding: 8px;
			}
		}

		.data-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 7px 20px;
			> div {
				@include text(kappa, lambda);
				background-color: $color-neutral-darker;
				color: $color-white;
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;
			}
		}
	}

	&:hover {
		.content {
			background-color: $color-neutral-darker;
			color: $color-white;
			visibility: visible;

			&:after {
				border-style: solid;
				border-width: 13px;
				content: ' ';
				margin-left: -13px;
				position: absolute;
			}

			.icon-white {
				stroke: $color-white;
			}

			.icon-black {
				stroke: $color-black;
			}

			&.arrow-top-right {
				&::after {
					border-color: transparent transparent $color-neutral-darker
						transparent;
					content: ' ';
					left: 85%;
					top: -26px;
				}
			}

			&.arrow-top-left {
				&::after {
					border-color: transparent transparent $color-neutral-darker
						transparent;
					content: ' ';
					left: 15%;
					top: -26px;
				}
			}

			&.arrow-bottom-right {
				&::after {
					border-color: $color-neutral-darker transparent transparent
						transparent;
					bottom: -26px;
					content: ' ';
					left: 85%;
				}
			}

			&.arrow-bottom-left {
				&::after {
					border-color: $color-neutral-darker transparent transparent
						transparent;
					bottom: -26px;
					content: ' ';
					left: 15%;
				}
			}

			.data-wrapper {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}

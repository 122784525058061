.search-page {
	min-height: 100vh;
	padding-top: 20px;

	div.sort-dropdown {
		border-radius: 10px;
		position: absolute;
		z-index: 10;
		background-color: white;
		min-width: 145px;
		right: 16px;
		box-shadow: 4px 3px 10px #ccc6c0;
		padding: 8px 0;
		top: 100%;

		input[type='radio'] {
			display: none;

			&:checked {
				+ label {
					background-color: $color-neutral-dark;
					color: $color-white;

					&:after {
						display: none;
					}
				}
			}
		}

		.radio-wrapper {
			&:hover {
				background-color: $color-green-lightest;
			}
		}

		label {
			background-color: transparent;
			font-weight: $font-weight-regular;
			justify-content: flex-end;
			padding: 6px 16px;
		}

		.radio-wrapper {
			align-items: center;
			margin-bottom: 0;
		}
	}

	.initial-search {
		.search-page-controls,
		.search-page-wrapper {
			display: none;
		}

		.search-landing {
			display: block;
		}
	}

	.sort-box {
		display: flex;

		svg {
			height: 9px;
			margin-left: 4px;
			width: 6px;
		}

		.icon-down {
			transform: rotate(90deg);
		}

		.icon-up {
			transform: rotate(-90deg);
		}
	}

	.search-page-controls,
	.search-page-wrapper {
		display: block;
	}

	.search-landing {
		display: none;
	}

	.search-page-controls {
		display: flex;
		flex-direction: column-reverse;

		.results-controls {
			order: 0;
		}

		.control-toggles {
			flex-grow: 1;
			order: 1;
		}
	}

	.active-filter-toggle {
		align-items: center;
		background-color: $color-neutral-dark;
		border-radius: 50%;
		display: flex;
		height: 40px;
		justify-content: center;
		min-width: 40px;
		transition: background-color 0.1s ease-in-out;
		width: 40px;

		span {
			color: $color-white;
		}

		.icon {
			height: 20px;
			stroke: $color-white;
			transition: stroke 0.1s ease-in-out;
			width: 20px;

			&:hover {
				stroke: $color-black;
			}
		}

		&:hover {
			background-color: $color-blue-lightest;
			cursor: pointer;

			span {
				color: $color-black;
			}
		}
	}

	.search-loaded & {
		min-height: 0;
	}

	.results-list {
		padding: 0 15px;

		li {
			margin: 0;

			&:before {
				display: none;
			}
		}
	}

	.map-container {
		height: 100%;
		min-height: 520px;
		position: relative;
		width: 100%;

		@include breakpoint(md) {
			border-radius: 10px;
		}

		@include breakpoint(lg) {
			min-height: unset;
		}

		.marker-label {
			bottom: 2px;
			position: relative;

			&.active-marker {
				bottom: 3px;
			}
		}
	}

	.controls-container {
		background-color: $color-white;
		border-radius: 10px;
		box-shadow: 0px 4px 40px -12px rgba(0, 0, 0, 0.18);

		@include breakpoint(md) {
			box-shadow: none;
			height: 100%;
		}

		height: 93vh;
		left: 0;
		opacity: 0;
		overflow: auto;
		padding: 20px 15px 16px;
		pointer-events: none;
		position: fixed;
		bottom: -100vh;
		transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
			bottom 0.2s ease-in-out;
		visibility: hidden;
		width: 100%;
		z-index: 9;

		.desktop-controls-open &,
		.mobile-controls-open & {
			display: block;
			opacity: 1;
			pointer-events: auto;
			bottom: 0;
			transition-delay: 0s;
			visibility: visible;
		}

		.control-panel {
			display: none;

			&.active {
				display: block;
			}
		}

		.available-filter-toggles {
			padding-bottom: 1.5rem;
		}

		.mobile-controls-results-btn {
			@include text(iota);
			background-color: $color-green-lightest;
			bottom: 0;
			box-shadow: 0px 5px 5px $color-neutral;
			font-weight: $font-weight-medium;
			left: 1em;
			position: fixed;
			width: calc(100% - 2em);

			.search-type-count {
				font-weight: $font-weight-regular;
				margin-left: auto;
				margin-right: 0.5rem;
			}

			.search-type-match {
				text-transform: capitalize;
			}

			.icon-arrow-up {
				fill: none;
				margin-left: 0;
				stroke: $color-black;
				transform: rotate(90deg);
			}

			&:hover,
			&:focus {
				background-color: $color-neutral-darker;
				color: $color-white;

				.icon-arrow-up {
					stroke: $color-white;
				}
			}
		}
		//controls container locations
		&.locations {
			flex: 0 0 100%;
			height: 70vh;
			max-width: 100%;

			div.filter-expanded-item {
				padding: 10px 0 0;
			}

			@include breakpoint(lg) {
				box-shadow: 0px 4px 40px -12px rgba(0, 0, 0, 0.18);
				justify-self: flex-end;
				right: 0;
				margin-right: 10px;
				max-width: 568px;
				position: absolute;
				top: 144px;

				.view-all-btn {
					display: inline-block;
				}
			}
		}
	}

	.clear-all-btn {
		color: $color-blue;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: $color-blue-dark;
			text-decoration: none;
		}
	}

	.controls-top {
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-weight: 400;

		&.only-mobile {
			.filter-clear-btn {
				padding: 16px 0 14px;
			}
		}

		&.locations {
			width: fit-content;

			.filter-clear-btn {
				padding: 0;
			}
		}
	}

	.view-mode-controls-container {
		align-items: flex-end;
		display: flex;
	}

	.view-btn-wrapper {
		background-color: $color-neutral-lightest-2;
		border-radius: 25px;
		margin-bottom: 8px;
		padding: 2px 3px;
		width: 60px;
		transition: all 0.5s ease;

		.view-btn {
			align-items: center;
			background-color: white;
			border-radius: 25px;
			color: #333230;
			cursor: pointer;
			display: flex;
			font-size: 12px;
			font-weight: $font-weight-medium;
			justify-content: center;
			left: 0;
			padding: 4px 8px;
			position: relative;
		}

		&.map {
			background-color: $color-blue;

			.view-btn {
				left: calc(100% - 37px);
			}
		}
	}

	.results-controls {
		border-bottom: 2px solid $color-neutral-lightest-2;
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 0 15px 18px 15px;
		padding-bottom: 18px;

		&.locations {
			display: block;

			.results-count {
				@include breakpoint(lg) {
					padding: 0;
				}
			}

			.count-clear-bar {
				align-items: center;
				align-self: flex-start;
				display: flex;
				flex-direction: row-reverse;
			}

			.controls-top {
				&:first-child > button {
					float: right;
				}

				.sort-box {
					display: none;
				}
			}
		}

		&.providers {
			.sort-box {
				display: none;

				@include breakpoint(lg) {
					display: block;
				}
			}
		}

		.count-clear-bar {
			width: 100%;
		}

		.selected-filters-list {
			padding: 0;

			span {
				font-size: 14px;
				font-weight: $font-weight-medium;
				line-height: 26px;
				margin-right: 2px;
			}

			.tag {
				background-color: $color-neutral-lightest-2;
				margin: 0 4px;
				padding: 2px 6px;

				.filter-value {
					color: #333230;
					font-size: 12px;
					line-height: 18px;
					margin-right: 5px;
				}

				.tag-remove-btn {
					display: flex;
					margin-left: 0;

					svg {
						height: 6px;
						stroke: #333230;
						width: 6px;
					}
				}
			}
		}

		.controls-bottom {
			display: flex;
			justify-content: space-between;

			.mobile-filters-toggle,
			.mobile-sort-toggle {
				@include text(lambda);
				background-color: transparent;
				border: none;
				color: $color-white;
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				padding: none;
			}
		}

		.sort-box {
			display: block;

			.icon {
				margin-left: 4px;
				height: 12px;
			}

			.radio-wrapper {
				margin-bottom: 0;
			}
		}
	}

	.clipboard-notification {
		margin-left: 1rem;
		opacity: 0;
		transition: opacity 0.4s;
		position: absolute;
		bottom: 96px;

		&.active {
			opacity: 1;
		}
	}

	.search-page-results {
		.results-controls {
			display: none;
		}

		.no-results-message {
			margin: 4rem auto;
			max-width: 650px;
			padding: 0 15px;

			@include breakpoint(md) {
				padding: 0;
			}

			.heading-1 {
				@include text(34, 38);
				margin-bottom: 1rem;
			}

			.heading-2 {
				@include text(delta);
			}

			li {
				@include text(iota);
			}
		}
	}

	.results-list-container {
		position: relative;

		&.loading {
			pointer-events: none;

			.loading-overlay {
				opacity: 1;
			}
		}
	}

	.contracted-link-container {
		display: none;
	}

	.contracted-link {
		@include text(lambda);
		color: $color-blue;
		display: none;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.filters-title {
		color: $color-green-dark;
		font-size: 19px;
		font-weight: $font-weight-bold;
		line-height: 34px;

		&.only-desktop {
			@include breakpoint(lg) {
				font-size: 18px;
				font-weight: $font-weight-medium;
				padding: 8px 0;
			}
		}
	}

	.filter-title-bar {
		align-items: center;
		display: flex;
		justify-content: space-between;

		.filter-btn {
			margin-bottom: 0;
		}

		.close-button {
			background-color: $color-neutral-lightest-2;
			padding: 4px;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			align-items: center;
			justify-content: center;

			&.only-mobile {
				display: flex;

				@include breakpoint(lg) {
					display: none;
				}
			}

			&.only-desktop {
				display: none;

				@include breakpoint(lg) {
					display: flex;
				}
			}

			svg {
				height: 14px;
				width: 14px;
			}
		}
	}

	.filter-heading {
		cursor: pointer;
		display: block;
		font-size: 14px;
		font-weight: $font-weight-medium;
		letter-spacing: 0.42px;
		line-height: 18px;
	}

	.filter-title {
		@include text(lambda);
		font-weight: 400;
		margin: 0.5rem 0 1rem 0.75rem;
	}

	.react-autosuggest__container {
		position: relative;
	}

	.react-autosuggest__input {
		position: relative;
		z-index: 5;
		font-weight: 400;

		&--open,
		&:focus {
			+ .react-autosuggest__suggestions-container {
				display: block;
			}
		}
	}

	.location-search-input {
		position: relative;
		z-index: 2;
		font-weight: 400;

		&:focus {
			+ .react-autosuggest__suggestions-container {
				display: block;
			}
		}

		+ .react-autosuggest__suggestions-container {
			z-index: 1;
		}
	}

	.react-autosuggest__suggestions-container {
		background-color: $color-white;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
		box-shadow: 0px -5px 23px 4px rgba($color-black, 0.15);
		display: none;
		margin-top: -20px;
		padding: 30px 10px 0;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 4;

		[aria-selected='true'] {
			.typeahead-suggestion {
				background-color: $color-green-lighter;
			}
		}

		.filter-title {
			color: $color-neutral-dark;
		}
	}

	.typeahead-suggestion {
		@include text(lambda, 20);
		background-color: rgba($color-white, 0.5);
		border-radius: 25px;
		display: flex;
		justify-content: space-between;
		margin: 0 0 0.75rem;
		padding: 0 0 0 0.5rem;
		padding: 0.625rem 1.25rem;
		width: 100%;

		&:hover,
		&:focus,
		&[aria-selected='true'] {
			background-color: $color-green-lighter;
			cursor: pointer;
		}

		mark {
			background-color: $color-green-lighter;
		}

		.suggestion-name {
			flex-grow: 1;
		}

		.suggestion-number {
			padding-left: 1rem;
		}
	}

	.filter-btn-container {
		display: flex;
		margin-bottom: 6px;

		@include breakpoint(lg) {
			margin-bottom: 10px;
		}

		.react-autosuggest__container {
			flex: 1;
		}

		.geolocate-btn {
			flex: 0 0 auto;
			margin-left: 0.75rem;
		}

		.use-location-btn {
			color: $color-neutral-darker;
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: $font-weight-medium;
			text-decoration: underline;
			align-self: flex-start;
			margin-top: 14px;

			&:hover {
				cursor: pointer;
			}

			svg {
				height: 15px;
				margin-right: 6px;
				width: 15px;
			}
		}
	}

	.geolocate-btn {
		align-items: center;
		color: $color-green-dark;
		display: flex;
		height: 40px;
		justify-content: center;
		min-width: 140px;
		width: auto;

		&:hover,
		&:focus {
			color: $color-black;
		}

		&.geolocating {
			justify-content: flex-start;
			text-decoration: none;

			&:after {
				-webkit-animation: ellipsis steps(4, end) 900ms infinite;
				animation: ellipsis steps(4, end) 900ms infinite;
				content: '\2026'; /* ascii code for the ellipsis character */
				display: inline-block;
				overflow: hidden;
				vertical-align: bottom;
				width: 0px;
			}

			@keyframes ellipsis {
				to {
					width: 12px;
				}
			}
		}
	}

	.distance-filter-wrapper {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}

	.distance-radio {
		display: flex;
		margin-top: 5px;

		.distance-radio-wrapper {
			height: 40px;
			width: 40px;
			position: relative;
			margin-right: 0.375rem;

			input[type='radio'] {
				height: 0;
				width: 0;

				&:checked {
					+ label {
						background-color: $color-green-dark;
						color: $color-white;
					}
				}

				&:focus,
				&:hover {
					+ label {
						background-color: $color-green-lighter;
					}
				}
			}

			label {
				@include text(mu);
				align-items: center;
				background-color: $color-neutral-lightest-2-b;
				border-radius: 25px;
				color: $color-black;
				display: flex;
				height: 30px;
				justify-content: center;
				position: absolute;
				top: 0;
				width: 30px;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.filter-btn-wrapper {
		display: flex;

		.filter-btn {
			background-color: rgba($color-white, 0.5);

			span {
				color: $color-green-dark;
				margin-left: auto;
			}

			&:hover {
				background-color: $color-blue-lightest;
				cursor: pointer;
			}

			&:hover span {
				color: $color-black;
				cursor: pointer;
			}
		}
	}

	.preferred-provider-info {
		display: none;
		padding: 0 1.25rem 1.5rem;

		&.active {
			display: block;
		}
	}

	.search-print {
		display: none;
	}

	.search-page-wrapper.locations {
		@include breakpoint(lg) {
			.results-list-container {
				height: calc(100% - 48px);
				margin-left: -40px;
				max-height: calc(100% - 48px);
				overflow-y: auto;
				padding-left: 40px;

				.results-list {
					border-top: 3px solid $color-neutral-lightest-2;
					margin: 0 10px 0 10px;
					padding: 18px 8px 0 0;
				}
			}
		}

		.only-desktop {
			.control-toggles,
			.control-toggle-btns,
			.mob-filter-wrapper {
				width: 100%;
			}
		}
	}

	@include breakpoint(md) {
		.search-page-controls {
			display: block;
		}

		.results-controls {
			margin-left: 15px;
			margin-bottom: 1rem;
		}

		.results-list {
			@media screen {
				&.view-mode-grid {
					display: flex;
					flex-wrap: wrap;

					.results-list-item {
						flex: calc(50% - 15px);
						margin-bottom: 3.25rem;
						max-width: calc(50% - 15px);

						&:nth-of-type(2n + 1) {
							margin-right: 15px;
						}

						&:nth-of-type(2n + 2) {
							margin-left: 15px;
						}
					}

					.search-result {
						display: flex;
						flex-direction: column;
						height: 100%;
						margin-bottom: 0;

						.result-top {
							height: 100%;
						}

						.result-details {
							display: flex;
							flex-direction: column;
						}

						.detail-bottom {
							border: none;
							margin-top: auto;
							padding-top: 0;
						}

						.ratings {
							height: 21px;
						}
					}
				}
			}
		}

		.view-mode-controls-container {
			display: flex;
			margin-right: 30px;
			margin-top: 1.5rem;
		}
	}

	.search-page-wrapper.locations {
		@include breakpoint(md) {
			.count-sort-bar {
				align-items: center;
				display: flex;
				margin: 10px 0 18px 0;

				.sort-wrapper {
					flex-shrink: 0;
					margin-right: 5px;
				}
			}

			.results-count {
				font-size: 12px;
				margin-bottom: 0;
			}

			.filter-expanded-item {
				align-self: flex-end;
				height: max-content;
				line-height: 14px;
				padding: 14px 0 14px 0;
				width: 75%;
			}

			.mob-filter-button {
				line-height: 14px;
				padding: 14px;
				height: max-content;
				align-self: flex-end;
				margin-bottom: 14px;
				width: 25%;
			}

			.view-mode-controls-container {
				display: none;
			}

			.mob-filter {
				display: none;
			}

			.search-page-results {
				flex: 0 0 100%;
				height: 100%;
				max-height: 100%;
				max-width: 100%;
			}

			.desktop-map {
				height: 100vh;
				margin-bottom: 24px;
				position: sticky;
				top: 0;
				width: 43%;
			}

			.left-col {
				.sort-box {
					position: relative;

					button {
						line-height: 30px;
					}
				}
			}
		}
	}

	@include breakpoint(lg) {
		@include make-container();

		.search-page-wrapper.locations {
			margin-bottom: 24px;
			max-height: 100vh;
			height: 100vh;

			.search-page-results {
				flex: 0 1 auto;
				overflow: hidden;
			}
		}

		.results-count {
			font-size: 18px;
			font-weight: $font-weight-medium;
			line-height: 25px;
			margin: 0 0 20px 0;

			strong > span {
				display: none;
			}

			.count {
				display: inline-block;
				margin-left: 0.5rem;
			}
		}

		.results-list {
			padding: 0;
		}

		.search-page-controls {
			position: relative;
		}

		.mobile-close-container,
		.mobile-filter-actions,
		.mobile-controls-results-btn {
			display: none;
		}

		.results-controls {
			align-items: center;
			background-color: transparent;
			border-bottom: none;
			color: $color-black;
			display: none;
			justify-content: space-between;
			margin-bottom: 0;
			margin-left: 0;
			padding: 0;
			position: relative;
			top: 0;
		}

		.sort-select-wrapper {
			display: block;
			margin-left: auto;
			margin-right: 1rem;
			position: relative;

			select {
				@include text(lambda);
				/* for Firefox */
				-moz-appearance: none;
				/* for Chrome */
				-webkit-appearance: none;
				background-color: transparent;
				border: 0;
				color: $color-black;
				padding-right: 1.5rem;
				text-align-last: right;
				/* For IE10 */
				&::-ms-expand {
					display: none;
				}

				&:hover {
					cursor: pointer;
				}
			}

			.icon {
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.search-page-wrapper {
			@include make-row();

			&.controls-closed {
				.controls-container {
					display: none;
				}

				.search-page-results {
					flex: 0 0 100%;
					max-width: 100%;
					padding-left: 10px;
				}

				.results-list {
					&.view-mode-grid {
						.results-list-item {
							flex: calc(33.333% - (60px / 3));
							max-width: calc(33.333% - (60px / 3));

							&:nth-of-type(3n + 1) {
								margin-left: 0;
								margin-right: 15px;
							}

							&:nth-of-type(3n + 2) {
								margin-left: 15px;
								margin-right: 15px;
							}

							&:nth-of-type(3n + 3) {
								margin-left: 15px;
								margin-right: 0;
							}
						}
					}
				}

				.list-result {
					flex-direction: row;
					min-height: 230px;

					.result-top {
						flex: 0 1 50%;
					}

					.location-scheduling {
						flex: 0 1 50%;
						margin-top: 0;
					}

					.profile-image-outer {
						flex: 0 1 50%;
					}

					.profile-image-container {
						width: 200px;
						height: 200px;
						border-radius: 10px;
						padding-bottom: 0;
					}

					.location {
						background-color: $color-green-lightest;
						flex: 2;

						&:hover {
							background-color: $color-blue-lightest;
						}

						&.not-available {
							background-color: $color-neutral-lightest;

							&:hover {
								background-color: $color-neutral-lightest;
							}
						}
					}

					.schedule-or-call {
						display: flex;
						flex: 3;
						flex-direction: row;

						.schedule-btn {
							background-color: $color-green-lighter;
							flex-basis: 60%;
							max-width: 60%;

							&:hover {
								background-color: $color-blue-lightest;
							}

							&:only-child {
								flex-basis: 100%;
								max-width: 100%;
							}
						}

						.call-link {
							background-color: $color-green-light;
							flex-basis: 40%;
							max-width: 40%;

							&:hover {
								background-color: $color-blue-lightest;
							}

							&:only-child {
								flex-basis: 100%;
								max-width: 100%;
							}
						}
					}
				}

				@media screen {
					.grid-result {
						.schedule-btn,
						.call-link {
							flex-basis: auto;
							flex-grow: 1;
						}
					}
				}

				.location-result {
					.location-scheduling {
						&.no-scheduling {
							.location {
								flex: 0 1 50%;
							}
						}

						.location {
							flex: 0 1 33.333%;

							&:only-child {
								flex-basis: 100%;
							}
						}

						.schedule-or-call {
							flex: 1;
							flex-direction: row;
						}
					}

					.profile-image-outer {
						flex: 0 1 50%;
					}

					.profile-image-container {
						height: 300px;
						padding-bottom: 0;
						width: 400px;
					}

					.result-details {
						display: flex;
						flex-direction: column;
						flex: 1 0 0%;
					}
				}

				.map-container {
					.map-result {
						.schedule-or-call {
							flex-direction: column;
						}
					}
				}
			}

			&.providers {
				.left-col {
					display: flex;
					width: 100%;
				}

				.filter-title-bar {
					justify-content: flex-end;
				}
			}

			&.locations {
				.only-desktop {
					.control-toggles,
					.control-toggle-btns,
					.mob-filter-wrapper {
						width: 100%;
					}

					.control-toggles {
						padding: 0 10px 0 10px;

						.control-toggle-btns {
							padding: 0;

							.clear-btn {
								display: flex;
								justify-content: flex-end;
								margin-top: 20px;
							}
						}
					}

					.section-title {
						margin-bottom: 0;
					}

					.mob-filter-button {
						margin-right: 0;
					}
				}

				.left-col {
					@include breakpoint(lg) {
						display: flex;
						flex-direction: column;
						height: 100vh;
						margin-left: -40px;
						margin-right: 10px;
						max-height: 100vh;
						overflow: hidden;
						padding-left: 40px;
						position: relative;
						width: calc(57% - 10px);
					}
				}
			}
		}

		.search-page-wrapper.providers {
			.controls-container {
				@include make-col-ready();
				@include make-col(3);
				min-width: 300px;
				background-color: transparent;
				left: 0;
				opacity: 1;
				overflow: visible;
				pointer-events: auto;
				position: relative;
				top: 0;
				transition: none;
				visibility: visible;
				z-index: 0;
			}
		}

		.controls-top {
			align-items: center;
		}

		.view-mode-controls-container {
			margin: 0;
		}

		.control-panel {
			margin-bottom: 4rem;
			padding: 0 7px 0 0;
		}

		.mobile-filters-toggle,
		.mobile-sort-toggle {
			display: none;
		}

		.search-page-results {
			flex: 1 1 auto;
			padding-left: 13px;

			.results-controls {
				display: flex;
				width: 100%;
			}
		}

		.results-list-container {
			&.search-result {
				.profile-image-container {
					height: 375px;
				}
			}
		}
	}

	.mob-filter-button {
		background-color: $color-green-lightest;
		border-radius: 5px;
		padding: 12px;
		font-size: 18px;
		font-weight: $font-weight-medium;
		flex: 1;
		line-height: 14px;
		margin-right: 12px;
	}

	.search-page-controls {
		.control-toggles {
			@include breakpoint(md) {
				margin-right: 0;
			}

			.control-toggle-btns {
				display: flex;
				width: 100%;
				padding: 0 12px;
				margin-top: -20px;

				.mob-filter-wrapper {
					h1 {
						margin: 0 -12px 10px -12px;
					}

					width: 100%;

					.mob-filter {
						display: flex;
						margin-top: 14px;
					}
				}
			}
		}

		.results-count {
			color: #333230;
			font-size: 14px;
			font-weight: $font-weight-medium;
			line-height: 18px;
		}
	}

	.search-page-wrapper {
		.sort-dropdown {
			position: absolute;
			z-index: 10;
			background-color: white;
			right: 0;
			box-shadow: 4px 3px 10px #ccc6c0;
		}
	}

	.search-share.control-panel.active {
		display: flex;
		flex-direction: row;
		margin-top: 56px;

		.search-share-button {
			color: $color-neutral-darker;
			display: flex;
			line-height: 50px;
			margin-right: 10px;
			cursor: pointer;
			text-decoration: underline;
			transition: 300ms ease-out;

			&:hover {
				color: #5d5c5b;
			}

			p {
				@include text(kappa);
				font-family: $font-family-sans-serif;
				line-height: 30px;
				margin-bottom: 0;
			}

			svg {
				margin-right: 6px;
				fill: $color-neutral-darker;
				stroke: $color-neutral-darker;
				position: relative;
				top: 8px;
			}

			.icon-print {
				height: 16px;
				width: 16px;
			}

			.icon-copy {
				height: 17px;
				stroke: transparent;
				width: 17px;
			}
		}
	}

	.view-all-btn {
		@include breakpoint(lg) {
			display: none;
		}

		font-size: 26px;
		font-weight: $font-weight-medium;
		letter-spacing: -0.286px;
		line-height: 40px;
		padding: 8px;
		margin-top: 30px;
		width: 100%;
	}

	.pagination-nav {
		margin-bottom: 24px;

		.pagination {
			margin-bottom: 0;
		}
	}
}

.filter-clear-btn {
	background-color: transparent;
	color: $color-green-dark;
	cursor: pointer;
	font-size: 13px;
	text-decoration: underline;

	&.only-mobile {
		font-size: 16px;
		padding-top: 22px;
	}
}

.search-page .only-mobile {
	display: block;
	@include breakpoint(lg) {
		display: none;
	}
}

.search-page .only-desktop,
.search-page div.only-desktop {
	display: none;
	@include breakpoint(lg) {
		display: block;
	}
}

.filter-sidebar {
	color: $color-neutral-darker;

	.section-title {
		background-color: $color-green-dark;
		color: $color-white;
		padding: 15px 15px 10px 15px;
		font-size: 25px;
		letter-spacing: normal;
		line-height: 28px;
		font-weight: 500;
		text-transform: none;

		@include breakpoint(md) {
			border-radius: 5px;
		}
	}

	.search-view-wrapper {
		display: flex;
		gap: 10px;
	}

	.no-mobile {
		display: none;

		@include breakpoint(lg) {
			display: block;
		}
	}

	.filter-label {
		font-family: $font-family-sans-serif;
		font-size: 16px;
		color: $color-green-dark;
		text-transform: uppercase;
		margin: 0;
		padding: 0 12px 8px;
		letter-spacing: 1.25px;
	}

	.filter-clear-btn {
		background-color: transparent;
		color: $color-green-dark;
		cursor: pointer;
	}

	.no-padding-left {
		padding-left: 0;
	}

	.filter-panel-title {
		color: $color-neutral-darker;
		font-size: 18px;
		font-weight: $font-weight-medium;
		line-height: 34px;
	}

	input[type='text'] {
		background-color: white;
		border: 1px solid $color-neutral-dark;
		color: $color-neutral-dark;
		border-radius: 5px;
		font-size: 18px;
		line-height: 34px;
		opacity: 1;
		padding: 4px 12px;

		&::placeholder {
			color: $color-neutral-dark;
		}
	}

	.active-filter-panel {
		display: flex;
		flex-direction: column;
	}

	.filter-expanded-item {
		width: 100%;
	}

	.filter-action-input {
		align-items: center;
		min-width: 100%;
		position: relative;

		&:focus,
		&:focus-within,
		&:focus-visible {
			.search-icon-wrapper {
				padding: 10px 14px;
				right: 2px;
			}
		}

		.input-icon-container {
			min-width: 100%;
		}

		.close-button {
			position: absolute;
			top: 14px;
			right: 65px;
			z-index: 5;

			svg {
				height: 14px;
				width: 14px;
			}
		}

		.search-icon-wrapper {
			align-items: center;
			background-color: $color-neutral-lightest-2;
			border-radius: 5px;
			display: flex;
			padding: 11px 14px;
			position: absolute;
			right: 1px;
			z-index: 5;

			&:hover {
				cursor: pointer;
			}

			.icon-search {
				height: 20px;
				min-width: 19px;
				position: relative;
			}

			&.locations {
				background-color: $color-green-lightest;

				@include breakpoint(lg) {
					background-color: $color-neutral-lightest-2;
				}
			}
		}
	}

	.filter-btn-container {
		flex-direction: column;

		.location-distance-panel {
			.location-panel {
				.close-button {
					position: absolute;
					top: 14px;
					right: 10px;
					z-index: 2;
				}
			}

			.distance-filter-wrapper {
				display: none;

				@include breakpoint(lg) {
					display: block;
				}
			}
		}

		.filter-btn-container {
			margin-bottom: 12px;
		}
	}

	.filter-preferred-provider {
		display: flex;
		flex-direction: row;
	}

	.accordion-item {
		border-radius: 5px;
		padding: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-neutral-lightest-2;
		margin-bottom: 4px;

		&:hover {
			cursor: pointer;
			background-color: #faf9f7;
		}

		p {
			font-family: $font-family-sans-serif;
			margin-bottom: 0;
			font-size: 1rem;
			font-weight: 500;
			line-height: 22px;
		}

		@include text(kappa);

		.icon {
			height: 12px;
			transition: all 0.25s ease;
		}

		.icon-up {
			transform: rotate(-90deg);
		}

		.icon-down {
			transform: rotate(90deg);
		}

		& ~ div > .expanded-filters {
			max-height: 0;
			transition: max-height 1s ease;
		}
	}

	.accordion-enabled {
		background-color: $color-neutral-lightest;
		color: $color-neutral-dark-1;
	}

	.radio-wrapper {
		align-items: center;
		margin-bottom: 0;

		&:hover {
			background-color: $color-green-lightest;
		}

		input[type='radio'] {
			height: 12px;
			margin: 1px;
			opacity: 1;
			width: 12px;
			border-radius: 50%;
			background: transparent;
			outline: 1px solid $color-neutral-dark;
			appearance: none;
			-webkit-appearance: none;

			&:checked {
				background-color: $color-green-dark;

				+ label {
					background-color: unset;
					color: $color-black;

					&:after {
						display: none;
					}

					&:hover {
						cursor: pointer;
					}

					.label-number {
						visibility: visible;
					}
				}
			}
		}

		label {
			@include text(lambda, 20);
			cursor: default;
			display: flex;
			font-weight: $font-weight-medium;
			margin: 0;
			width: 100%;
			padding: 0.325rem 0.625rem;
			background-color: unset;

			&:hover,
			&:focus,
			&[aria-selected='true'] {
				background-color: unset;
			}

			&:hover {
				cursor: pointer;
			}

			.label-display-name {
				+ .label-name {
					text-align: right;
				}
			}

			.label-name {
				flex-grow: 1;
			}

			.label-number {
				padding-left: 1rem;
			}
		}
	}

	.checkbox-wrapper {
		align-items: center;
		display: flex;
		justify-items: flex-start;
		margin-bottom: 4px;
		position: relative;
		padding: 0 12px;

		&:hover {
			background-color: $color-green-lightest;
		}

		&.disabled {
			pointer-events: none;

			input[type='checkbox'] {
				&:checked {
					+ label {
						&:after {
							display: none;
						}
					}
				}

				+ label {
					background-color: unset;
					display: block;
					padding: 0.625rem 2.5rem 0.625rem 1.25rem;
				}
			}
		}

		input[type='checkbox'] {
			height: 12px;
			margin: 1px;
			opacity: 1;
			width: 12px;
			background: transparent;
			outline: 1px solid $color-neutral-dark;
			appearance: none;
			-webkit-appearance: none;

			&:checked {
				background-color: $color-green-dark;

				+ label {
					background-color: unset;
					color: $color-black;

					.label-number {
						visibility: visible;
					}

					&::after {
						display: none;
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}

		label {
			@include text(kappa, kappa);
			font-weight: $font-weight-medium;
			display: flex;
			justify-content: space-between;
			margin: 0;
			padding: 0 0 0 0.5rem;
			width: 100%;
			background-color: unset;
			border-radius: 25px;
			padding: 0.325rem 0.625rem;
			transition: all 0.1s ease-in-out;

			&:hover {
				cursor: pointer;
				background-color: unset;
			}

			.label-display-name {
				+ .label-name {
					text-align: right;
				}
			}

			.label-name {
				flex-grow: 1;
			}

			.label-number {
				padding-left: 1rem;
			}
		}
	}

	.checkboxes-container {
		padding-top: 8px;

		.checkbox-wrapper {
			margin: 14px 0;
			position: relative;

			input[type='checkbox'] {
				height: 16px;
				width: 16px;
				border: 1px solid $color-green-dark;

				&:checked {
					+ label {
						color: $color-neutral-darker;
					}
				}
			}

			label {
				padding: 0 0 0 14px;
			}
		}
	}

	.view-all-no-accordion {
		height: 400px;
		margin-bottom: 16px;
		overflow: auto;
	}
}

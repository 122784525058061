.alert-listing-filters {
	background-color: $color-neutral-lightest-2;
	display: block;
	padding: 20px;
	position: relative;
	width: 100%;

	.filters-title {
		@include text(mu, 20);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	.filter-button-list {
		display: flex;
		flex-direction: column;
	}

	.filter-button {
		@include text(mu, 20);
		background: none;
		color: $color-green-dark;
		padding: 0;
		margin-bottom: 10px;

		&.active,
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.mobile-filter-close {
		align-items: center;
		display: flex;
		position: absolute;
		top: 23px;
		right: 20px;

		.icon {
			height: 12px;
			width: 12px;
		}
	}

	@include breakpoint(lg) {
	}
}

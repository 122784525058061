.overhauled {
	.ecard-form-wrapper {
		.form-title {
			color: var(--color-primary-dark);
		}
		.description {
			margin-bottom: 0;
		}

		.image-selection-container {
			margin-bottom: calc(var(--spacing-base) * 2);
			.image-selections {
				height: 185px;
			}
			&.desktop {
				display: none;
			}
		}

		@include breakpoint(md) {
			.columns-wrapper {
				display: flex;
				width: 100%;

				.column {
					flex: 0 0 65%;
					max-width: 65%;
					margin-bottom: 0;
					padding: 0 var(--spacing-base);

					&:first-child {
						flex: 0 0 35%;
						max-width: 35%;
					}
				}
			}
			.live-preview {
				&.mobile {
					display: none;
				}
				&.desktop {
					display: block;
				}
			}
			.image-selection-container {
				&.mobile {
					display: none;
				}
				&.desktop {
					display: flex;
					justify-content: unset;
					margin-bottom: var(--spacing-base);
				}
			}
		}
		.field-wrapper {
			margin: calc(var(--spacing-base) * 2) 0;

			&:first-child {
				margin-top: 0;
			}
		}

		.form-label,
		.live-preview {
			margin-left: 0;
			@include breakpoint(md) {
				margin-left: calc(var(--spacing-base) * 2);
			}
		}
		label {
			font-weight: var(--fw-medium);
		}
		input,
		select {
			margin-top: 0;
			opacity: 1;
		}

		textarea {
			background-color: var(--color-bg-secondary);
			border: none;
			border-radius: var(--br-md);
			display: flex;
			justify-content: space-between;
			margin: 0;
			min-height: 100px;
			padding: var(--spacing-base) calc(var(--spacing-base) * 2);
			transition: all 0.1s ease-in-out;
			opacity: 1;
			width: 100%;
		}

		.live-preview {
			position: relative;

			.title {
				@media screen and (max-width: 414px) {
					font-size: 14px;
				}
				text-transform: uppercase;
			}

			p {
				@media screen and (max-width: 414px) {
					font-size: 10px;
					margin-bottom: calc(var(--spacing-base) / 2);
				}
				margin-bottom: var(--spacing-base);
			}
			.message {
				@media screen and (max-width: 414px) {
					font-size: 10px;
				}
			}

			.green-banner {
				align-items: flex-end;
				background-image: url('../../images/ecard-bg.png');
				background-size: cover;
				display: flex;
				height: 100%;
				padding: calc(var(--spacing-base) * 6) 0 var(--spacing-base) 0;

				@include breakpoint(sm) {
					padding: calc(var(--spacing-base) * 12) 0 calc(var(--spacing-base) * 3);
				}
				@include breakpoint(md) {
					padding-top: calc(var(--spacing-base) * 12);
				}
				@include breakpoint(lg) {
					padding-top: calc(var(--spacing-base) * 17);
				}
				@include breakpoint(xl) {
					padding-top: calc(var(--spacing-base) * 15);
				}
			}

			.two-col {
				display: flex;
				gap: var(--spacing-base);
				margin: 0 auto;
				max-width: 90%;
				width: 90%;

				@include breakpoint(sm) {
					gap: calc(var(--spacing-base) * 2);
				}
				@include breakpoint(lg) {
					gap: calc(var(--spacing-base) * 4);
				}

				> div {
					flex: 1 1 calc(50% - calc(var(--spacing-base) * 2));
				}

				.left-col {
					img,
					.img {
						aspect-ratio: 1 / 1;
						position: relative;
						width: 100%;
					}
				}
			}
		}

		button {
			justify-content: center;
			margin: calc(var(--spacing-base) * 2) 0;
			width: 100%;
		}

		.error-msg {
			color: var(--color-errors);
			margin-bottom: 0;
		}
	}
}

.text-call-out {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	> div {
		background-color: $color-blue-lightest-2;
		color: $color-black;
		flex-grow: 1;
		padding: 20px;
		text-align: center;
		width: 30%;
	}
}

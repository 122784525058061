.overhauled {
	.additional-cards {
		background-color: var(--color-base);
		border-radius: var(--br-md);
		box-shadow: var(--btn-shadow);
		display: flex;
		flex-direction: column;
		gap: calc(var(--spacing-base) * 3);
		overflow: hidden;
		padding-bottom: calc(var(--spacing-base) * 3);
		padding-top: calc(var(--spacing-base) * 3);
		width: 100%;

		@include breakpoint(xl) {
			gap: calc(var(--spacing-base) * 4);
			padding-bottom: calc(var(--spacing-base) * 4);
			padding-top: calc(var(--spacing-base) * 4);
		}

		.card-img {
			border-radius: var(--br-sm);
			height: 187px;
			min-height: 187px;
			overflow: hidden;

			@include breakpoint(xl) {
				min-height: 292px;
				height: 292px;
			}

			img {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
		.content {
			.header {
				margin-bottom: calc(var(--spacing-base) * 3);
			}

			.description,
			.description p {
				margin-bottom: calc(var(--spacing-base) * 4);
			}

			:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(sm) {
			&.with-img {
				.content {
					a:nth-child(2) {
						margin-top: calc(var(--spacing-base) * 6);
					}
				}
			}

			&:not(.with-img) {
				.content {
					a:nth-child(2) {
						margin-top: calc(var(--spacing-base) * 4);
					}
				}
			}
		}
	}
	.jss-three-up-wrapper {
		.jss-three-up-headline {
			color: var(--color-primary-dark);
			padding-bottom: calc(var(--spacing-base) * 4);
		}
		div.jss-one-up {
			display: grid;
			gap: calc(var(--spacing-base) * 3);

			@include breakpoint(md) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include breakpoint(lg) {
				grid-template-columns: repeat(3, 1fr);
			}

			.additional-cards {
				width: 100%;
			}
		}
		div.inner-wrapper {
			display: grid;
			row-gap: calc(var(--spacing-base) * 3);

			@include breakpoint(md) {
				gap: calc(var(--spacing-base) * 3);
				grid-template-columns: repeat(auto-fit, minmax(calc(calc(50% - 12px)), 1fr));
				justify-content: space-between;
			}
			@include breakpoint(lg) {
				grid-template-columns: repeat(auto-fit, minmax(calc(33% - 16px), 1fr));
			}
			@include breakpoint(xl) {
				grid-template-columns: repeat(auto-fit, minmax(calc(25% - 18px), 1fr));
			}

			.link-card {
				text-decoration: none;

				&:hover {
					.btn-tertiary::after {
						right: 0;
						transition: all 300ms ease-out;
					}
				}
			}
		}
	}
}

.jss-action-button {
	height: 90px;

	.action-btn {
		min-height: 90px;
	}
}

.jss-six-up {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 40px;
	max-width: $medium-container-width;
	margin: 0 auto;
	flex-wrap: wrap;
	font-family: $font-family-sans-serif;
	min-height: 100px;
	.header {
		color: $color-light-black;
	}
	@include breakpoint(xs) {
		.additional-cards {
			width: 330px;
			margin-right: 10px;
			margin-top: 0px;
			img {
				height: 180px;
			}
		}
	}

	@include breakpoint(md) {
		margin: 0 auto;
		padding: 0px;
	}
	@include breakpoint(lg) {
		margin: 0 auto;
		padding: 0px;
	}
}

.jss-six-up-headline {
	margin: 0 auto;
	@include breakpoint(md) {
		margin: 0 auto;
		max-width: 1180px;
	}
}

.media-viewer {
	.modal {
		iframe {
			aspect-ratio: 16/9;
			position: relative;
		}
	}

	.active-media {
		width: 100%;

		img {
			height: 100%;
			max-height: calc(100vh - 200px);
			width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}

	.caption {
		text-align: center;
		margin-top: calc(var(--spacing-base) * 2);
	}

	img,
	iframe {
		width: 100%;
		height: 100%;
	}

	.media-btn {
		aspect-ratio: 16/9;
		display: block;
		margin-bottom: calc(var(--spacing-base) * 2);
		position: relative;
		width: 100%;

		img {
			height: 100%;
			min-height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}

		iframe {
			pointer-events: none;
		}

		&:hover,
		&:focus {
			cursor: pointer;
		}

		.icon-play-filled {
			height: calc(var(--spacing-base) * 6);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: calc(var(--spacing-base) * 6);
		}

		.icon-expand-fs {
			height: calc(var(--spacing-base) * 4);
			position: absolute;
			top: calc(var(--spacing-base) * 2);
			right: calc(var(--spacing-base) * 2);
			width: calc(var(--spacing-base) * 4);
		}
	}

	.media-btns-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.media-btn {
			flex: 0 1 calc(50% - var(--spacing-base));
			max-width: calc(50% - var(--spacing-base));
		}
	}

	@include breakpoint(lg) {
		.media-btn {
			margin-bottom: calc(var(--spacing-base) * 4);

			.icon-play-filled {
				height: calc(var(--spacing-base) * 10);
				width: calc(var(--spacing-base) * 10);
			}
		}

		.media-btns-container {
			&:after {
				content: ' ';
				flex: 0 1 calc(33.333% - calc(var(--spacing-base) * 3));
				max-width: calc(33.333% - calc(var(--spacing-base) * 3));
			}

			.media-btn {
				flex: 0 1 calc(33.333% - calc(var(--spacing-base) * 3));
				max-width: calc(33.333% - calc(var(--spacing-base) * 3));
			}
		}
	}
}

.overhauled {
	.article-summary-wrapper {
		width: 100%;

		.article-summary-outer {
			&.outer-vertical-spacing {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			@include breakpoint(md) {
				display: flex;
				justify-content: center;
			}
		}

		.article-summaries {
			display: flex;
			flex-direction: column;
		}

		.article-summary {
			display: flex;
			flex-direction: column;
			gap: calc(var(--spacing-base) * 3);
			width: 100%;

			@include breakpoint(md) {
				flex-direction: row;
				width: 100%;

				.summary-content {
					display: flex;
					flex: 1;
					flex-direction: column;
					justify-content: center;
				}
			}

			.summary-img {
				height: 207px;
				width: 100%;

				@include breakpoint(md) {
					height: 226px;
					width: 47%;
				}

				img {
					border-radius: var(--br-md);
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.date-and-location {
				display: flex;
				margin-bottom: calc(var(--spacing-base) * 2);

				span {
					position: relative;

					&:not(:first-of-type) {
						display: inline-block;
						margin-left: var(--spacing-base);
						padding-left: var(--spacing-base);

						&:before {
							content: '|';
							display: block;
							left: 0;
							position: absolute;
							transform: translateX(-50%);
						}
					}
				}
			}

			.summary-title {
				margin-bottom: calc(var(--spacing-base) * 4);
			}

			.summary-description {
				margin: calc(var(--spacing-base) * 4) 0;

				@include breakpoint(lg) {
					margin: calc(var(--spacing-base) * 4) 0;
				}
			}
		}

		.article-summaries {
			&:not(.vertical) {
				.article-summary-outer {
					.article-summary {
						@include breakpoint(md) {
							&.img-right {
								flex-direction: row-reverse;
							}
						}
						.summary-img {
							@include breakpoint(lg) {
								height: 290px;
							}

							@include breakpoint(xl) {
								height: 370px;
								width: 40%;
							}
						}
					}
				}
			}
			&.vertical {
				@include breakpoint(lg) {
					display: grid;
					gap: calc(var(--spacing-base) * 3);
					grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));

					.article-summary-outer {
						margin: 0;

						.article-summary {
							flex-direction: column;
							padding: 0;

							.summary-img {
								height: 207px;
								width: 100%;
							}
							.summary-content {
								display: block;
								flex: unset;
							}
						}
					}
				}
				@include breakpoint(xl) {
					.article-summary-outer {
						.article-summary {
							.summary-img {
								height: 311px;
							}
						}
					}
				}
			}
		}
	}
}

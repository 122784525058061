.content-card {
	background-color: $color-white;
	box-shadow: $box-shadow;
	display: block;
	height: auto;
	margin-bottom: 2rem;
	min-height: 250px;
	padding: 25px;
	position: relative;
	width: 100%;

	&:hover {
		background-color: $color-green-lightest;
		text-decoration: none;
	}

	&.mammo-card {
		min-height: 320px;

		.right-rail & {
			.card-title {
				color: $color-neutral-darker;
			}
			.card-text {
				@include text(epsilon);
			}
		}

		.card-title {
			@include text(beta);
			color: $color-green-dark;
			font-weight: $font-weight-light;
			margin-bottom: 1.125rem;
		}

		.card-text {
			@include text(epsilon);
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-light;
			margin-bottom: 1.125rem;
		}
		.card-footer {
			background: none;
			border-top: none;
		}
	}

	&.bg-white-90 {
		background-color: $color-white-90;

		&:hover {
			background-color: $color-white;
		}
	}

	&.bg-green-lightest {
		background-color: $color-green-lightest;

		&:hover {
			background-color: $color-white;
		}
	}

	.card-title {
		@include text(delta);
		color: #5a8127;
		font-weight: $font-weight-light;
	}

	.card-footer {
		align-items: center;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		left: 0;
		padding: 1.5rem;
		position: absolute;
		width: 100%;
		border-top: none;
		background: none;
	}

	.card-text {
		@include text(gamma);
	}

	.link-text {
		@include text(zeta);
		color: $color-neutral-darker;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-light;
	}

	p {
		@include text(epsilon);
		color: $color-neutral-darker;
		font-weight: 300;
		margin-bottom: 0;
	}

	@include breakpoint(sm) {
		height: auto;

		h1 {
			@include text(gamma);
		}

		.card-text {
			@include text(epsilon);
		}

		.link-text {
			@include text(eta);
		}
	}
}

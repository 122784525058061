/*
 *
 * COLOR VARIABLES
 *
 */

/* Monochromes */
$color-black: #000;
$color-light-black: #333333;
$color-neutral-darkest: #000;
$color-neutral-darker: #333230;
$color-neutral-dark: #666461;
$color-neutral: #999590;
$color-neutral-dark-1: #bcb5af;
$color-neutral-light: #ccc6c0;
$color-neutral-lighter: #e3dbd1;
$color-neutral-lightest: #f0ebe6;
$color-neutral-lightest-2: #f2efeb;
$color-neutral-lightest-2-b: #f3efeb;

$color-neutral-lightest-3: #f8f7f7;
$color-neutral-lightest-4: #fafbfc;
$color-neutral-with-opacity: rgba($color-neutral-lightest-2, 0.5);
$color-white: #fff;
$color-white-90: rgba($color-white, 0.9);

/* Greens */
$color-green-dark: #4e8209;
$color-green: #64a70b;
$color-green-light: #7cba25;
$color-green-lighter: #a1d45f;
$color-green-lightest: #c7e6a1;
$color-green-darker: #5a8027;
$color-green-darker-2: #508117;
$color-green-darker-3: #7db338;
$color-green-mid-light: #e3f2d0;

/* Blues */
$color-blue-dark: #007f9b;
$color-blue: #00a9ce;
$color-blue-light: #2bb9d9;
$color-blue-lighter: #74d3e8;
$color-blue-lightest: #aae4f2;
$color-blue-lightest-2: #d9f8ff;

/* Other */
$color-brown: #6e3527;
$color-dark-purple: #bf1a93;
$color-purple: #ff24c5;
$color-red: #e72d1e;
$color-red-light: #ff7566;
$color-critical: #e52b24;
$color-warning: #ffd905;
$color-warning-2: #dd6e16;

/* Deprecated */
$color-light-gray: #dddddd;

//NEW OSF - Colors
[data-theme='osfhealthcare'] {
	--color-primary-light: #64a70b;
	--color-primary-dark: #4e8209;
	--color-secondary-light: #00a9ce;
	--color-secondary-dark: #007f9b;
	--color-tertiary: #64a70b;
	--color-quaternary: #00a9ce;
	--color-body-dark: #333230;
	--color-overlay-base: #666461;
	--color-overlay-base-gradient: #f0efef;
	--color-overlay-dark: #25545e;
	--color-overlay-light-gradient: #cce5eb;
	--color-text-highlight: #d9f8ff;
	--color-shadows: #e3dbd1;
	--color-bg-secondary: #f2efeb;
	--color-link: #00576b;
	--color-bg-primary: #faf9f7;
	--color-base: #ffffff;
	--color-errors: #e52b24;
	//Not in figma
	--color-btn-hover-shadow: rgba(153, 149, 144, 1);
	--color-btn-hover-shadow-light: var(--color-active-secondary);
	--color-active-primary: #365a07;
	--color-active-secondary: #f5f5f5;
	--color-text-hover: #5d5c5b;
	--color-overlay-opacity: rgba(102, 100, 97, 0.7);
}

//NEW CHOI - Colors
[data-theme='choi'] {
	--color-primary-light: #00a9ce;
	--color-primary-dark: #007f9b;
	--color-secondary-light: #64a70b;
	--color-secondary-dark: #4e8209;
	--color-tertiary: #da291c;
	--color-quaternary: #ef9600;
	--color-body-dark: #333230;
	--color-overlay-base: #666461;
	--color-overlay-base-gradient: #f0efef;
	--color-overlay-dark: #25545e;
	--color-overlay-light-gradient: #cce5eb;
	--color-text-highlight: #d9f8ff;
	--color-shadows: #e3dbd1;
	--color-bg-secondary: #f2efeb;
	--color-link: #00576b;
	--color-bg-primary: #faf9f7;
	--color-base: #ffffff;
	--color-errors: #e52b24;
	//Not in figma
	--color-btn-hover-shadow: rgba(153, 149, 144, 0.5);
	--color-btn-hover-shadow-light: var(--color-active-secondary);
	--color-active-primary: #00576b;
	--color-active-secondary: #f5f5f5;
	--color-text-hover: #5d5c5b;
	--color-overlay-opacity: rgba(102, 100, 97, 0.7);
}

//NEW SACON - Colors
[data-theme='sacon'] {
	--color-primary-light: #3b5073;
	--color-primary-dark: #002554;
	--color-secondary-light: #a4d233;
	--color-secondary-dark: #002554;
	--color-tertiary: #0076ce;
	--color-quaternary: #0076ce;
	--color-body-dark: #333230;
	--color-overlay-base: #666461;
	--color-overlay-base-gradient: #f0efef;
	--color-overlay-dark: #25545e;
	--color-overlay-light-gradient: #cce5eb;
	--color-text-highlight: #d9f8ff;
	--color-shadows: #e3dbd1;
	--color-bg-secondary: #f2efeb;
	--color-link: #3b5073;
	--color-bg-primary: #faf9f7;
	--color-base: #ffffff;
	--color-errors: #e52b24;
	//Not in figma
	--color-btn-hover-shadow: rgba(153, 149, 144, 0.5);
	--color-btn-hover-shadow-light: var(--color-active-secondary);
	--color-active-primary: #3b5073;
	--color-active-secondary: #f5f5f5;
	--color-text-hover: #5d5c5b;
	--color-overlay-opacity: rgba(102, 100, 97, 0.7);
}

//NEW SFCON - Colors
[data-theme='sfcon'] {
	--color-primary-light: #3b5073;
	--color-primary-dark: #002554;
	--color-secondary-light: #ae841f;
	--color-secondary-dark: #002554;
	--color-tertiary: #ffcd00;
	--color-quaternary: #ffcd00;
	--color-body-dark: #333230;
	--color-overlay-base: #666461;
	--color-overlay-base-gradient: #f0efef;
	--color-overlay-dark: #25545e;
	--color-overlay-light-gradient: #cce5eb;
	--color-text-highlight: #d9f8ff;
	--color-shadows: #e3dbd1;
	--color-bg-secondary: #f2efeb;
	--color-link: #3b5073;
	--color-bg-primary: #faf9f7;
	--color-base: #ffffff;
	--color-errors: #e52b24;
	//Not in figma
	--color-btn-hover-shadow: rgba(153, 149, 144, 0.5);
	--color-btn-hover-shadow-light: var(--color-active-secondary);
	--color-active-primary: #3b5073;
	--color-active-secondary: #f5f5f5;
	--color-text-hover: #5d5c5b;
	--color-overlay-opacity: rgba(102, 100, 97, 0.7);
}

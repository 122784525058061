.osfhealthcare-page {
	transform: translateX(0%);
	transition: transform 0.2s ease;

	&.mobile-nav-open {
		position: fixed;
		transform: translateX(-100%);
	}

	@include breakpoint(md) {
		&.mobile-nav-open {
			transform: translateX(-50%);
		}
	}

	@include breakpoint(lg) {
		&.mobile-nav-open {
			transform: translateX(0%);
		}
	}
}

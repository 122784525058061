.centered-column {
	@include make-container();

	@include breakpoint(md) {
		.centered-column-inner {
		}

		.centered-column-content {
			max-width: 1440px;
		}
	}

	@include breakpoint(lg) {
		.centered-column-content {
			max-width: 1440px;
		}
	}
}

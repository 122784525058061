@mixin gradient-background {
	background-image: linear-gradient(90deg, rgb(85, 128, 56) 0%, #367d98 100%);
}
@mixin gradient-background-light {
	background-image: linear-gradient(90deg, rgb(125, 179, 56) 66%, rgb(75, 167, 202) 100%);
}

//Overhaul Gradient
//Solid BG Gradients
@mixin osf-gradient-bg-light($rotation: 180deg) {
	background-image: linear-gradient(
		$rotation,
		var(--color-primary-light),
		var(--color-secondary-light)
	);
}

@mixin osf-gradient-bg-dark($rotation: 180deg) {
	background-image: linear-gradient(
		$rotation,
		var(--color-primary-dark) 0%,
		var(--color-secondary-light) 100%
	);
}

//Overlay Gradients
@mixin osf-overlay-base-gradient($rotation: 180deg) {
	background-image: linear-gradient(
		$rotation,
		var(--color-overlay-base) 64%,
		var(--color-overlay-base-gradient)
	);
}

@mixin osf-overlay-dark($rotation: 180deg) {
	background-image: linear-gradient(
		$rotation,
		var(--color-overlay-dark) 64%,
		var(--color-secondary-dark)
	);
}

@mixin osf-overlay-secondary-dark($rotation: 180deg) {
	background-image: linear-gradient(
		$rotation,
		var(--color-secondary-dark) 64%,
		var(--color-overlay-light-gradient)
	);
}

#site-footer {
	font-family: 'Lato';
	background-color: #008bbf;
	color: #fff;
	display: table;
	table-layout: fixed;
	padding: 12px 0 96px;
	width: 100%;

	margin: 0 auto;
	.inner-wrap {
		max-width: 1440px;
		margin: 0 auto;
	}
	* {
		margin-bottom: 0;
	}

	a {
		color: inherit;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}
	p {
		font-family: 'Lato';
		padding-inline-start: 40px;
	}
	em {
		font-family: 'Lato';
		@include text(lambda, 21);
	}

	.phone-number {
		font-size: 24px;
		font-weight: bold;
	}

	address {
		font-size: 14px;
		font-style: normal;
		margin: 1em 0;
	}

	.footer-hd,
	address strong {
		color: inherit;
		font-size: 15px;
		font-weight: bold;
	}

	.footer-col {
		display: table-cell;
		padding: 12px 20px 0;
		vertical-align: top;
		width: 25%;
	}

	nav.footer-col .footer-hd {
		margin-bottom: 4px;
		text-transform: uppercase;
	}

	nav.footer-col ul {
		margin-bottom: 2em;
	}

	nav.footer-col li {
		list-style-type: none;
		margin-bottom: 2px;
	}

	nav.footer-col a {
		display: block;
		padding: 0;
	}

	#footer-legal {
		background: #fff;
		position: absolute;
		bottom: 0;
		font-size: 0.9em;
		padding: 1em;
		width: 100%;
		left: 0;
		text-align: center;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	#footer-legal ul,
	#footer-legal li {
		display: inline-block;
		margin: 0;
	}

	#footer-legal ul {
		margin-left: 0.5em;
	}

	#footer-legal a {
		border-left: 1px solid;
		display: block;
		color: inherit;
		line-height: 0.9em;
		margin-bottom: 0.2em;
		padding: 0 0.5em;
	}

	#footer-logo {
		margin-bottom: 1em;
		height: auto;
	}
	table {
		font-size: 12px;
		text-align: left;
		border-collapse: collapse;
		border: 1px solid #dedede;
		width: 100%;
		-moz-box-shadow: 1px 1px 8px #ccc;
		-webkit-box-shadow: 1px 1px 8px #ccc;
		box-shadow: 1px 1px 8px #ccc;
	}

	table thead tr th {
		font-weight: bold;
		color: #fff;
		background: #008bbf;
		padding: 5px;
		border-collapse: collapse;
	}

	table td,
	table th {
		padding: 5px;
	}

	table tr:nth-child(even) {
		background-color: #eaf5f9;
	}

	table.plain,
	form table {
		border: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: inherit;
	}

	table.plain th,
	form table th {
		padding: 8px 1em 8px 0;
		text-align: right;
		vertical-align: top;
	}

	table.plain td,
	form table td {
		color: inherit;
	}

	table.plain td input,
	form table td input {
		padding: 2px 4px;
	}

	table.plain tr:nth-child(even),
	form table tr:nth-child(even) {
		background-color: inherit;
		color: inherit;
	}

	table.details th,
	table.details td {
		padding: 0 0.5em 0.5em 0;
		text-align: left;
		vertical-align: top;
	}

	table.details th:last-child,
	table.details td:last-child {
		padding-right: 0;
	}

	@media screen and (max-width: 960px) {
		display: table;
		.footer-col {
			display: block;
			float: left;
			padding: 12px 5%;
			width: 90% !important;
		}
	}
}

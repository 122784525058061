.related-card-container {
	@include make-container();
	.rotate0 {
		transform: rotate(-90deg);
	}
	.additional-cards {
		.content .header {
			min-height: unset;
			margin-bottom: 0;
		}

		@include breakpoint(md) {
			width: 100%;
			img {
				height: 180px;
			}

			.content {
				.header {
					margin-bottom: 10px;
				}

				.description {
					margin-bottom: 10px;
				}
			}
		}
	}
	.action-btn {
		min-height: unset;
	}

	.card-header {
		h1 {
			color: $color-black;
			font-size: 14px;
			text-transform: uppercase;
			text-align: left;
			padding: 1.25rem 0;
			font-weight: 600;
		}
	}

	.view-all-link {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1.5rem;
		a {
			color: $color-green-dark;
			text-decoration: none;
			cursor: pointer;
			font-size: 1.1rem;
		}
	}
}

.related-cards {
	display: grid;
	grid-template-columns: 1fr;
	gap: calc(var(--spacing-base) * 3);
	margin-top: calc(var(--spacing-base) * 4.25);
	@include breakpoint(smd) {
		grid-template-columns: repeat(2, 1fr);
	}

	.article-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: calc(var(--spacing-base) * 3);
		gap: calc(var(--spacing-base) * 2);
		border-radius: var(--br-md);
		background: var(--color-base);
		box-shadow: 0px 3px 12px 0px var(--color-shadows);
		text-decoration: none;

		&:hover {
			transform: translateY(-4px);
			transition: transform 0.2s ease;
		}
	}

	.article-image {
		width: 100%;
		img {
			width: 100%;
			height: 220px;
			object-fit: cover;
		}
	}
	.article-content {
		h3 {
			margin-bottom: 0;
		}
	}
	.article-date {
		color: var(--color-overlay-base);
		font-size: var(--fs-label);
		margin-bottom: calc(var(--spacing-base) * 2);
	}

	.article-summary {
		color: var(--color-overlay-base);
		font-size: var(--fs-body);
		text-decoration: none;
		line-height: var(--lh-extra);
		font-family: var(--ff-serif);
	}
}

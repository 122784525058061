@mixin jss-inner-container {
	display: flex;
	flex-direction: column;
	margin: 0 calc(var(--spacing-base) * 4);

	@include breakpoint(xl) {
		margin: 0 calc(var(--spacing-base) * 20);
	}
	@include breakpoint(xxl) {
		margin: 0 calc(var(--spacing-base) * 30);
	}
}

@mixin inner-side-spacing {
	padding-left: calc(var(--spacing-base) * 3);
	padding-right: calc(var(--spacing-base) * 3);
}

@mixin full-inner-spacing {
	padding: calc(var(--spacing-base) * 3);

	@include breakpoint(md) {
		padding: calc(var(--spacing-base) * 5);
	}
	@include breakpoint(xl) {
		padding: calc(var(--spacing-base) * 8);
	}
}

@mixin outer-vertical-spacing {
	margin-bottom: calc(var(--spacing-base) * 2);
	margin-top: calc(var(--spacing-base) * 2);

	@include breakpoint(md) {
		margin-bottom: calc(var(--spacing-base) * 3);
		margin-top: calc(var(--spacing-base) * 3);
	}

	@include breakpoint(xl) {
		margin-bottom: calc(var(--spacing-base) * 5);
		margin-top: calc(var(--spacing-base) * 5);
	}
}

@mixin inner-vertical-spacing {
	padding-bottom: calc(var(--spacing-base) * 2);
	padding-top: calc(var(--spacing-base) * 2);

	@include breakpoint(md) {
		padding-bottom: calc(var(--spacing-base) * 3);
		padding-top: calc(var(--spacing-base) * 3);
	}
	@include breakpoint(xl) {
		padding-bottom: calc(var(--spacing-base) * 5);
		padding-top: calc(var(--spacing-base) * 5);
	}
}

@mixin jss-two-column {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@include breakpoint(lg) {
		flex-wrap: nowrap;
		gap: calc(var(--spacing-base) * 10);
		margin-top: calc(var(--spacing-base) * 4);
	}

	@include breakpoint(xl) {
		margin-top: calc(var(--spacing-base) * 9);
	}

	.left-column {
		width: 100%;
		height: 100%;
		order: 1;
	}

	.right-column {
		order: 0;
		width: 100%;

		@include breakpoint(xs) {
			height: 100%;
		}

		@include breakpoint(sm) {
			height: 100%;
		}

		@include breakpoint(md) {
			height: 100%;
		}

		@include breakpoint(lg) {
			max-width: 250px;
		}
	}

	.left-column,
	.right-column {
		.jss-inner-container {
			&:not(.modal-container) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.bg-primary,
		.bg-secondary,
		.bg-color-base {
			border-radius: var(--br-md);
			.jss-inner-container:not(.full-inner-spacing) {
				@include inner-side-spacing();
			}
		}
	}

	@include breakpoint(lg) {
		.left-column {
			order: 0;
		}

		.right-column {
			order: 1;
		}
	}
}

.pill-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: calc(var(--spacing-base) * 5);
	.pill {
		@include osf-body();
		border: 1px solid var(--color-bg-secondary);
		background-color: var(--color-bg-secondary);
		border-radius: 20px;
		color: var(--color-body-dark);
		display: inline-block;
		padding: 5px 20px;
		margin-right: 1.25rem;
		margin-bottom: 0.75rem;
		transition: all 0.1s ease-in-out;
		height: auto;

		&:hover,
		&:focus {
			background-color: $color-blue-lightest-2;
			border-color: $color-blue-lightest-2;
			color: $color-black;
		}

		&.active {
			background-color: $color-blue-lightest-2;
			border-color: $color-blue-lightest-2;
			color: $color-black;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

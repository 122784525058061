.overhauled {
	.hero-paragraph {
		h1,
		h2,
		h3,
		h4 {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

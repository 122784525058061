.pagination-controls {
	@include make-container();
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.container & {
		padding: 0;
	}

	.limit-dropdown {
		@include text(kappa, 20);
		align-items: center;
		color: $color-light-black;
		display: inline-flex;
		flex: 0 0 auto;
		font-family: $font-family-sans-serif;
		margin-bottom: 50px;

		.form-select-wrapper {
			height: 35px;
			margin-left: 5px;
			width: auto;

			.icon {
				height: 12px;
				left: 1rem;
				right: auto;
				stroke: $color-neutral-dark;
				width: 12px;
			}

			select {
				color: $color-light-black;
				padding-left: 34px;
				border-radius: 30px;
				padding-right: 12px;
				text-align: center;
				width: auto;
			}
		}
	}

	.pagination {
		order: 1;
	}

	.results-count {
		@include text(kappa, 20);
		color: $color-black;
		font-family: $font-family-sans-serif;
		margin-bottom: 50px;
		flex: 0 0 auto;
	}

	@include breakpoint(md) {
		flex-wrap: nowrap;
		margin-bottom: 2rem;

		.limit-dropdown,
		.results-count {
			margin-bottom: 0;
		}

		.pagination {
			margin-bottom: 0;
			order: unset;
		}
	}
}

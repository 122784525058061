.e-card-container {
	position: relative;
	width: 100%;
	margin-top: 2rem;

	.e-card-outer-container {
		width: 100%;
		padding: 15px;
	}

	.card-summary {
		padding: 1.5rem 0;
		p {
			color: $color-light-black;
		}
	}

	@include breakpoint(md) {
		.e-card-outer-container {
			opacity: 1;
			overflow: visible;
			pointer-events: auto;
		}
	}

	@include breakpoint(lg) {
		.card-container {
			@include make-container();
		}
		.e-card-outer-container {
			padding: 0;
		}
	}
}

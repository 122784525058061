.search-wrapper {
	@include jss-inner-container();
}

.search-bar.overhauled {
	margin-bottom: calc(var(--spacing-base) * 3);
	margin-top: calc(var(--spacing-base) * 7.5);

	form {
		width: 100%;
	}

	.search-input {
		display: flex;
		flex-direction: column;
		gap: calc(var(--spacing-base) * 2);
		margin-bottom: calc(var(--spacing-base) * 3.75);
		width: 100%;

		.input-wrapper {
			position: relative;
			width: 100%;

			.search-icon {
				position: absolute;
				left: calc(var(--spacing-base) * 2);
				top: 50%;
				transform: translateY(-50%);
				width: calc(var(--spacing-base) * 2);
				height: calc(var(--spacing-base) * 2);
				stroke: var(--color-overlay-base);
				stroke-width: 2;
			}

			input {
				padding: calc(var(--spacing-base) * 1.75) calc(var(--spacing-base) * 5);
				border-radius: var(--br-sm);
				border: 2px solid var(--color-overlay-base);
				background-color: transparent;
				font-family: var(--ff-sans-serif);
				font-size: var(--fs-body);
				width: 100%;
				opacity: 1;

				&::placeholder {
					color: var(--color-overlay-base);
				}
			}
		}

		.btn-primary {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			&:after {
				display: none;
			}
		}

		@include breakpoint(md) {
			flex-direction: row;

			.input-wrapper {
				width: 80%;
			}

			.btn-primary {
				width: 20%;
			}
		}
	}
}

.image-selection-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	.form-label {
		margin: 0.25rem 0px 0px 20px;
	}

	.image-selector {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.image-container {
		background: $color-neutral-lightest-2;
		display: flex;
		justify-content: center;
		align-items: center;

		.ecard-image {
			width: 100%;
			height: inherit;
		}
	}

	.pagination-left-icon {
		cursor: pointer;
		width: 30px;
	}

	.pagination-right-icon {
		cursor: pointer;
		width: 30px;
	}

	.image-container-large {
		width: 132px;
		height: 132px;
	}

	.image-container-medium {
		width: 136px;
		height: 136px;
	}

	@media screen and (max-width: 1024px) {
		margin-left: 0rem;
		.image-container-large {
			width: 95px;
			height: 95px;
		}
		.form-label {
			margin-left: 1.25rem;
		}
	}

	@media screen and (max-width: 375px) {
		margin-left: 0rem;
		.image-container-medium {
			width: 100px;
			height: 100px;
		}
	}
}

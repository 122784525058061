.care-schedule-and-chat {
	width: 100%;
	background-color: $color-white;
	box-shadow: 3px 5px 6px rgba($color-black, 0.29);
	margin-bottom: 2.25rem;
	padding: 20px;

	.care-heading {
		@include text(kappa);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	.care-link-list {
		margin-bottom: 3.25rem;
	}

	.care-link {
		@include text(kappa, 20);
		color: $color-green-dark;
		display: inline-block;
		margin-bottom: 10px;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.chat-section {
		align-items: center;
		display: flex;
		flex-direction: column;

		p {
			@include text(iota);
			color: $color-light-black;
			margin-bottom: 20px;
		}
	}

	.chat-img {
		height: 85px;
		margin-bottom: 20px;
		width: 85px;
	}

	.chat-link {
		@include text(kappa, 20);
		color: $color-green-dark;
		display: inline-flex;
		cursor: pointer;
		margin-bottom: 1rem;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		.icon {
			margin-left: 10px;
			margin-top: 2px;
			stroke: $color-green-dark;

			.icon-arrow-go g {
				stroke: $color-green-dark;
			}
		}
	}

	@include breakpoint(md) {
		.care-link-list {
			columns: 2;
		}

		.chat-section {
			align-items: flex-start;
			flex-direction: row-reverse;
		}

		.chat-img {
			margin-bottom: 0;
			margin-left: 2rem;
		}

		.chat-content {
			flex: 1;
		}
	}
}

.overhauled {
	.one-up {
		border-radius: var(--br-md);
		display: flex;
		flex-direction: column;
		gap: calc(var(--spacing-base) * 3);
		overflow: hidden;
		padding: calc(var(--spacing-base) * 2) calc(var(--spacing-base) * 3);

		@include breakpoint(sm) {
			padding: calc(var(--spacing-base) * 3) calc(var(--spacing-base) * 3)
				calc(var(--spacing-base) * 5) calc(var(--spacing-base) * 3);
		}

		@include breakpoint(md) {
			flex-direction: row;
			gap: 0;
			padding: 0;
		}

		&.img-right {
			@include breakpoint(md) {
				flex-direction: row-reverse;
			}
		}

		.one-up-img {
			height: 177px;
			position: relative;

			@include breakpoint(sm) {
				height: 228px;
			}

			@include breakpoint(md) {
				height: 297px;
				width: 35%;
			}
			@include breakpoint(lg) {
				height: 322px;
				width: 40%;
			}
			@include breakpoint(xl) {
				height: 369px;
				width: 41%;
			}

			img {
				border-radius: var(--br-md);
				height: 100%;
				object-fit: cover;
				object-position: center;
				width: 100%;
			}
		}

		.modal-open-btn {
			cursor: pointer;
			left: calc(50% - calc(var(--spacing-base) * 4));
			position: absolute;
			top: calc(50% - calc(var(--spacing-base) * 4));

			&:hover {
				svg {
					background-color: var(--color-overlay-opacity);
					box-shadow: var(--btn-shadow);
				}
			}

			svg {
				background-color: rgba(51, 50, 48, 0.4);
				border-radius: 100%;
				fill: var(--color-base);
			}
		}

		.one-up-content {
			display: flex;
			flex: 1;
			flex-direction: column;
			gap: calc(var(--spacing-base) * 3);
			justify-content: center;

			@include breakpoint(md) {
				padding: calc(var(--spacing-base) * 3);
			}

			p {
				margin: 0;
			}

			.btn-primary {
				justify-content: center;
				width: 100%;

				@include breakpoint(md) {
					width: fit-content;
				}
			}
		}
	}
}

.five-up {
	background-color: $color-neutral-lightest-2;
	display: flex;
	margin-bottom: 2rem;

	.content-container {
		max-width: 100%;
		padding: 0 $sm-grid-outer-padding;
		position: relative;
	}

	.card-wrapper {
		display: flex;
		flex-direction: row;
		gap: 30px;
		justify-content: flex-start;
		overflow-x: scroll;
		padding: 30px 0 2.5rem;
		scroll-snap-type: x mandatory;
	}

	.five-up-card {
		min-width: 100%;
		scroll-snap-align: start;

		.five-up-img {
			margin-bottom: 20px;
			width: 100%;
			cursor: pointer;
			position: relative;

			img {
				height: 100%;
				object-fit: cover;
				pointer-events: none;
				width: 100%;
			}
		}

		.five-up-card-heading {
			@include text(kappa);
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-bold;
			color: $color-light-black;
			text-transform: uppercase;
		}

		.five-up-card-cta {
			display: none;
		}

		.five-up-card-link {
			color: $color-green-dark;
			display: block;
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
			margin-top: 10px;
		}

		.five-up-card-description {
			@include text(kappa);
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
			margin-bottom: 0;
			margin-top: 15px;
		}
	}

	.control {
		display: flex;
		justify-content: space-between;
		left: 0;
		right: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 2;
		position: absolute;
	}

	.control-next,
	.control-previous {
		align-items: center;
		background: rgba($color-black, 0.3);
		cursor: pointer;
		display: flex;
		justify-content: center;
		pointer-events: auto;
		width: 40px;

		&.disabled {
			visibility: hidden;
		}
	}

	.control-previous {
		transform: rotate(180deg);
	}

	@include breakpoint(md) {
		margin-bottom: 4rem;
		min-height: 400px;

		.content-container {
			margin: 0 auto;
			max-width: $medium-container-width;
			padding-left: 15px;
			padding-right: 15px;

			&.expanded {
				.card-wrapper {
					gap: 0;
				}

				.five-up-card {
					&.active {
						flex-basis: 300px;
						max-width: 300px;

						img {
							filter: none;
						}

						.five-up-content {
							left: 10px;
							position: absolute;
							top: 175px;
							width: 100%;
						}

						.five-up-card-heading,
						.five-up-card-link,
						.five-up-card-description {
							display: block;
						}
					}

					img {
						filter: grayscale(100%);
					}

					.five-up-card-cta,
					.five-up-card-description,
					.five-up-card-link,
					.five-up-card-heading {
						display: none;
					}
				}
			}
		}

		.control {
			display: none;
		}

		.card-wrapper {
			overflow-x: hidden;
		}

		.card-wrapper {
			gap: 30px;
			justify-content: space-evenly;
			overflow-x: hidden;
			scroll-snap-type: unset;
		}

		.five-up-card {
			flex: 1;
			min-width: 0;
			scroll-snap-align: unset;

			.five-up-img {
				height: 125px;
				position: relative;
				cursor: pointer;

				img {
					height: 100%;
					width: 100%;
				}
			}

			.five-up-card-cta {
				color: $color-green-dark;
				display: block;
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;
				cursor: pointer;

				&:active {
					color: #5a8127;
				}

				&:hover {
					text-decoration: underline;
					color: #007f9b;
				}
			}

			.five-up-card-description,
			.five-up-card-link {
				display: none;
			}
		}
	}

	@include breakpoint(lg) {
		.content-container {
			&.expanded {
				.five-up-card {
					&.active {
						.five-up-content {
							top: 250px;
						}
					}
				}
			}
		}

		.five-up-card {
			.five-up-img {
				height: 150px;
			}
		}
	}
}

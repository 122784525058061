.alert-listing-page {
	background-color: $color-white;
	display: flex;
	position: relative;
	padding: 20px 0 40px;

	.alert-listing-page-container {
		@include make-container();
	}

	.alert-listing-page-title {
		@include text(zeta, 30);
		color: $color-green-dark;
		font-family: $font-family-serif;
		font-weight: $font-weight-regular;
	}

	.title-filter-toggle-container {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 0 0 20px;
	}

	.mobile-filter-toggle {
		@include text(lambda, 14);
		align-items: center;
		background-color: $color-neutral-lightest-2;
		border-radius: 20px;
		color: $color-black;
		display: inline-flex;
		height: 40px;
		justify-content: center;
		width: 126px;

		.icon {
			fill: $color-neutral-dark;
			height: 12px;
			margin-right: 14px;
			transform: rotate(90deg);
			width: 12px;
		}
		.icon-chevron-down {
			transform: rotate(0deg);
			height: 15px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.alert-listing-filter-container {
		display: none;
	}

	.mobile-filters-container {
		box-shadow: 0px 3px 6px rgba($color-black, 0.29);
		display: block;
		right: 15px;
		position: absolute;
		top: 20px;
		width: calc(100% - 30px);
		z-index: 1;
	}

	@include breakpoint(md) {
		.alert-listing-row {
			@include make-row();
		}

		.title-filter-toggle-container {
			@include make-col-ready();
			margin-bottom: 20px;
		}

		.alert-listing-page-title {
			font-size: 34px;
			line-height: 38px;
		}

		.alert-listing-filter-container {
			@include make-col-ready();
			flex: 0 0 260px;
			display: block;
		}

		.alert-listing-groups {
			@include make-col-ready();
			flex: 1;
			margin-left: 10px;
		}

		.mobile-filter-toggle {
			display: none;
		}

		.mobile-filters-container {
			display: none;
		}
	}
}

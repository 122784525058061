.list-filters {
	align-items: center;
	border-bottom: 1px solid #dfdcd8;
	display: flex;
	gap: 25px;
	justify-content: space-between;
	margin-bottom: 50px;
	padding: 30px 0;
	flex-wrap: wrap;
	padding-top: 0;

	@include breakpoint(md) {
		padding: 30px 0;
	}

	.list-search {
		align-items: center;
		background-color: $color-neutral-lightest-2;
		border-radius: 30px;
		display: flex;
		padding: 10px 15px 10px 20px;
		width: 100%;

		@include breakpoint(md) {
			flex-basis: 40%;
		}

		input {
			background: transparent;
			border: none;
			border-radius: unset;
			flex-grow: 1;
			font-size: unset;
			padding: 0 10px;

			&:focus {
				border: 0;
				box-shadow: none;
				outline: 0;
			}
		}

		svg {
			fill: $color-green;
			stroke: $color-green;
		}
	}

	.list-select {
		flex-grow: 1;

		select {
			appearance: none;
			background: $color-neutral-lightest-2
				url('/assets/images/media-library/innovation/caret-down.png')
				no-repeat right 20px center;
			background-size: 12px !important;
			border: none;
			border-radius: 30px;
			cursor: pointer;
			flex-grow: 1;
			padding: 10px 18px;
			position: relative;
			width: 100%;

			&:focus {
				border: 0;
				box-shadow: none;
				outline: 0;
			}
		}
	}
}

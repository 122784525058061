@use 'sass:string';

@mixin breakpoint($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@if ($breakpoint == 'mobile') {
			@media (max-width: map-get($breakpoints, $breakpoint)) {
				@content;
			}
		} @else {
			@media (min-width: map-get($breakpoints, $breakpoint)) {
				@content;
			}
		}
	} @else if (string.index(#{$breakpoint}, 'px')) {
		@media (min-width: $breakpoint) {
			@content;
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
	}
}

/* Global (reset, functions, variables, mixins, helpers) */
@import 'global';

/* Atoms (basic HTML elements e.g. inputs, labels, buttons) */
@import 'atoms';

/* Molecules (e.g. Hero, Primary Navigation, Search) */
@import 'molecules';

/* Organisms (e.g. Header, Footer, Article, SitecoreComponent) */
@import 'organisms';

/* Pages (e.g. Search Page, Article Page) */
@import 'pages';

/* Print (e.g. Provider Print, Location Print) */
@import 'print';

// Lato font family
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

html {
	scroll-behavior: smooth;
}

#r2-main-plac {
	margin-bottom: 30px;
}

#bb-checkout-wrapper {
	top: 150px !important;
}

:hover {
	transition: all 0.25s ease;
}

iframe[src*='google.com/maps'] {
	width: 100% !important;
}

iframe[src*='youtube.com/embed'] {
	width: 100% !important;
	margin-bottom: 16px;
	height: 420px;
}

.mx-0 {
	margin-left: 0px;
	margin-right: 0px;
}

.px-0 {
	padding-left: 0px;
	padding-right: 0px;
}

sup {
	vertical-align: top;
	position: relative;
	top: -0.5em;
	font-size: 0.7rem;
}
/* Better desplay on mobile */
@media (max-width: 574px) {
	tr {
		display: block;
		margin: 8px 0px;
	}
}

::selection {
	background-color: #d9f8ff;
}
.layout-wrapper {
	background-color: $color-white;
}
.jss-container {
	padding: 0;
	margin: 0;
	max-width: none;
	position: relative;
}

.jss-base-container {
	max-width: $medium-container-width;
	margin: 0 auto;
}

@include breakpoint(md) {
	.custom-richtext h4 {
		font-size: 14px;
		padding-bottom: 0px;
		display: block;
		font-weight: bold;
	}
}

div.custom-richtext:not(.overhauled div.custom-richtext) {
	padding: 1.5em 0 0.5em 0;
	min-height: 100px;
	font-family: $font-family-sans-serif;
	overflow: hidden;

	& > h2 {
		@include heading-2();
		font-weight: 500;
		margin-bottom: 10px;
	}

	& > h3 {
		margin-bottom: 10px;
	}

	& > p {
		font-size: 16px;
		color: $color-neutral-darker;

		& > iframe {
			max-width: 100% !important;
			margin-bottom: 16px;
		}
	}
}

/* Shared styles for ul */
ul:not(.breadcrumb ul) {
	margin-bottom: 22px;
	margin-right: 0;
	@include breakpoint(lg) {
		margin-right: -8px;
	}
}
.custom-richtext ul,
.accordion-item ul {
	padding-inline-start: 6%;
}
/* Shared styles for ul li */
li:not(li li, .breadcrumb li .overhauled li) {
	margin-bottom: 8px;
	margin-right: 8px;
}

/* Shared styles for ul li ul */
.custom-richtext ul li ul,
.accordion-item ul li ul {
	margin-bottom: 0;
}
/* Shared styles for ul li ul li */
.custom-richtext ul li ul li,
.accordion-item ul li ul li {
	margin-top: 8px;
}
/* Shared styles for accordion content */
.accordion .accordion-content a:not(.osf-green-cta),
.accordion .accordion-content p,
.accordion .accordion-content span {
	margin-bottom: 0.75rem;
}
/* Shared styles for accordion header */
.accordion .accordion-header span.accordion-label {
	font-size: 1.05rem;
}

div.hero-content-paragraph {
	a:not(.osf-green-cta) {
		&:active {
			color: #5a8127;
		}

		&:hover {
			text-decoration: underline;
			color: #007f9b;
		}
	}
}

@media screen and (max-width: 575px) {
	td {
		display: table-row;
	}
}

.full-bg {
	overflow: auto;
}

hr {
	border-color: #e2dbd2;
	margin-top: 20px;
	margin-bottom: 20px;
	border-style: solid;
	justify-content: center;

	@include breakpoint(md) {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	@include breakpoint(xl) {
		max-width: $medium-container-width;
		margin-top: 40px;
		margin-bottom: 40px;
		padding: 0;
	}
}

.events-list {
	ul {
		padding-left: 0;
	}
}

.jss-additional-padding {
	@include breakpoint(xs) {
		padding: 20px;
	}
}

.jss-video {
	height: 100%;

	img,
	source,
	canvas {
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	video {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	picture {
		height: 438px;
		background: #f2efeb 0% 0% no-repeat padding-box;
		opacity: 1;
	}

	.jss-image {
		width: 780px;
	}

	.jss-video-caption {
		padding: 0 20px;
	}

	@include breakpoint(xs) {
		margin: 20px;
	}
	@include breakpoint(md) {
		margin: 0 auto;
	}
}

.jss-three-column {
	margin: 0 auto;
	max-width: 1340px;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@include breakpoint(lg) {
		flex-wrap: nowrap;
	}

	.three-column-left {
		order: 1;
		padding: 0 20px;
		width: 100%;

		@include breakpoint(lg) {
			order: 0;
			width: unset;
		}
	}

	.three-column-middle {
		width: 100%;
		height: 100%;
		order: 2;

		@include breakpoint(lg) {
			max-width: 790px;
			padding-right: 25px;
			order: 1;
		}
	}

	.three-column-right {
		order: 0;
		width: 100%;

		@include breakpoint(xs) {
			height: 100%;
		}

		@include breakpoint(sm) {
			height: 100%;
		}

		@include breakpoint(md) {
			height: 100%;
		}

		@include breakpoint(lg) {
			max-width: 250px;
			order: 2;
		}
	}

	@include breakpoint(lg) {
		.left-column {
			order: 0;
		}

		.right-column {
			order: 1;
		}
	}
}

.sixup {
	margin: 50px auto;

	.text-container {
		padding: 0;
		margin-top: 2rem;

		.paragraph-intro {
			font-family: $font-family-sans-serif;
		}
	}

	.sixup-list {
		display: flex;
		flex-wrap: wrap;
		.sixup-item {
			margin: 10px 0;

			a {
				color: $color-green-darker-2;
			}
		}

		.additional-cards {
			margin-bottom: 30px;

			a.jss-link {
				font-size: 1.05rem;
			}

			@include breakpoint(xs) {
				width: 100%;
				margin: 5px 0 30px 0;
			}

			@include breakpoint(md) {
				width: 240px;
			}

			@include breakpoint(lg) {
				width: 330px;
			}
		}

		.additional-cards div.content {
			background-color: unset;
			padding: unset;

			h5.header {
				min-height: unset;
				text-transform: uppercase;
			}

			div.description {
				font-size: 1.05rem;
				min-height: unset;
			}
		}
	}
}

.related-links {
	padding: 2.25rem 1.25rem 1.25rem 1.25rem;
	background-color: $color-neutral-lightest-2;
	h4 {
		margin-bottom: 0.625rem;
	}
	ul {
		padding: 0;
	}
	li {
		margin-bottom: 0.625rem;
		list-style: none;
		a {
			color: $color-green-darker-2;
		}
	}
	h3.section-title {
		margin-bottom: 0;
	}
}

.text-box {
	margin: 2rem auto;
}

.osf-green-cta {
	background-color: $color-green-dark;
	border: 1px solid $color-green-dark;
	border-radius: 20px;
	color: $color-white;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-regular;
	padding: 8px 15px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 20px;
	display: inline-block;
	@include text(theta, lambda);

	&:hover {
		background-color: $color-blue-lightest-2;
		border: 1px solid $color-blue-lightest-2;
		cursor: pointer;
		color: $color-black;
	}

	@include breakpoint(xs) {
		padding: 8px 15px;
		@include text(theta, lambda);
	}
	@include breakpoint(md) {
		@include text(theta, lambda);
	}
}

p:has(a.osf-green-cta) {
	display: inline-block;
}

.osf-list-items {
	ol {
		font-family: $font-family-serif;
		@include text(iota, 20);
		margin: 10px 0;
	}

	li {
		margin-bottom: 10px;
	}
}

.osf-center-paragraph {
	text-align: center;
}
.osf-add-padding {
	padding: 10px;
}

.osf-table,
.osf-table-stripped {
	overflow: auto;
	table {
		border: 1px solid #d6d1cc;
		border-collapse: collapse;

		&.non-striped tr:nth-child(even) td {
			background: transparent;
		}
	}
	th,
	tr:first-of-type td {
		border-bottom: 1px solid #d6d1cc;
		padding: 15px;
	}

	td {
		padding: 15px;
	}

	&-stripped {
		tr {
			&:nth-child(even) td {
				background-color: #f2efeb;
			}
		}
	}
}

.osf-three-column {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	a {
		color: $color-green-darker-2;
		font-family: $font-family-serif;
		padding: 10px 0;
		width: 100%;
		justify-content: left !important;
		@include breakpoint(sm) {
			width: 50%;
		}
		@include breakpoint(md) {
			width: 33%;
		}
	}
}

.jss-container {
	.custom-richtext {
		.osf-image-right {
			max-width: 50%;
			margin-left: 16px;
			margin-bottom: 16px;
			margin-top: 16px;
			float: right;
		}

		.osf-image-left {
			max-width: 50%;
			margin-right: 16px;
			margin-bottom: 16px;
			margin-top: 16px;
			float: left;
		}
	}
}

.osf-image-right {
	max-width: 50% !important;
	margin-left: 16px !important;
	margin-bottom: 16px !important;
	margin-top: 16px !important;
	float: right !important;
	height: auto !important;
}

.osf-image-left {
	max-width: 50% !important;
	margin-right: 16px !important;
	margin-bottom: 16px !important;
	margin-top: 16px !important;
	float: left !important;
	height: auto !important;
}

.osf-load-more {
	display: flex;
	justify-content: center;
}

.osf-loading {
	justify-content: center;
	display: flex;
	color: $color-neutral-darker;
	p {
		@include text(theta, beta);
		font-family: $font-family-sans-serif;
		margin-left: 10px;
	}
}

.event-details {
	.osf-no-results {
		margin-left: 20px;
		list-style-type: disc;
	}
}

.gic-page-navigation {
	a:hover {
		text-decoration: none;
		color: unset;
	}
}

.gic-right-rail {
	.mammo-card {
		background-color: #c7e6a1;
		&:hover {
			background-color: #fff;
		}
	}
}

//CSS Overrides of the Aha Provider Data & Patient Education Connectors//
.featured-providers {
	max-width: 1050px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 16px !important;
}

.featured-providers,
.featured-providers p {
	font-family: $font-family-sans-serif !important;

	.featured-providers-input,
	div.inner-container,
	.inner-container .featured-providers-result {
		background-color: $color-neutral-lightest-2;
	}

	.featured-providers-input {
		border-bottom: 1px solid $color-neutral-lightest-2 !important;
		border-radius: 10px 10px 0 0;
		padding: 24px 20px;

		&.react-autosuggest-container {
			&.input-icon-container {
				svg {
					height: 22px;
					left: 33px;
					top: 58px;
					width: 18px;
				}

				label {
					color: #000000;
					display: block;
					font-weight: $font-weight-medium;
					left: 0;
					padding-bottom: 8px;
					position: relative;
					top: 0;
				}

				input {
					background-color: rgba(255, 255, 255, 0.5);
					border-radius: 20px;
					font-weight: $font-weight-medium;
					height: unset;
					opacity: unset;
					padding: 4px 44px;

					&::placeholder {
						color: #595959;
						opacity: 73%;
					}
				}
			}
		}
	}

	.inner-container {
		.search-results-container {
			display: flex;
			flex-direction: column;

			.featured-providers-result {
				border-radius: 5px;

				div.result-top {
					flex-direction: row;

					a.profile-image-outer {
						.profile-image-container {
							height: 100%;
							min-height: 180px;
						}
					}
				}

				@include breakpoint(lg) {
					div.result-top {
						flex-direction: column;

						a.profile-image-outer {
							width: 100%;
						}

						.profile-image-bg,
						.profile-image,
						.profile-image-container {
							height: 180px;
							min-height: 180px;
						}

						.profile-image-outer {
							height: 180px;
							min-height: 180px;
						}
					}
				}

				.detail-scheduling-container {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					position: relative;
				}

				a.result-details {
					display: flex;
					flex-direction: column;
					height: auto;
					margin-bottom: 28px;
					padding: 0 16px 22px 16px;

					&:hover {
						.accepting {
							color: $color-green-dark;
						}
					}

					.distance {
						color: #595959;
						font-size: 12px;
						font-weight: $font-weight-medium;
						line-height: 18px;
						padding: 16px 0 8px 0;
					}

					.result-name {
						color: #000000;
						font-size: 20px;
						font-weight: $font-weight-medium;
						line-height: 28px;
						margin-bottom: 32px;
						text-decoration: underline;
					}

					.accepting {
						color: $color-green-dark;
						font-size: 16px;
						font-weight: $font-weight-medium;
					}

					.medical-group {
						background-color: $color-neutral-lightest-2;
						border-radius: 14px;
						color: #000000;
						font-size: 12px;
						line-height: 18px;
						margin-top: 8px;
						max-width: fit-content;
						order: 4;
						padding: 2px 12px 2px 8px;
					}

					.new-patient {
						margin-top: auto;
						order: 3;

						&-text {
							line-height: 24px;
						}
					}

					.upcoming-availability {
						margin: 0;
					}
				}
			}

			.action-cta {
				background-color: $color-green-dark;
				font-size: 18px;
				line-height: 28px;

				&:hover {
					background-color: $color-green-lightest;
				}
			}
		}
	}

	a.action-cta,
	a.view-all-call-link,
	a.cta-secondary {
		border-radius: 5px;
		font-size: 18px;
		font-weight: $font-weight-medium;
		line-height: 28px;
		padding: 8px 32px 6px 18px;

		@include breakpoint(lg) {
			font-size: 20px;
			line-height: 30px;
		}
	}

	a.action-cta {
		margin-bottom: 14px;

		&:hover {
			color: #000000;

			&::after {
				filter: invert(1);
			}
		}
	}
	//SIDE RAIL
	&.single {
		div.featured-providers-input {
			&.react-autosuggest-container {
				margin-bottom: 0;
				padding: 16px;

				svg {
					height: 20px;
					left: 30px;
					top: 46px;
					transform: unset;
				}

				input {
					border-bottom: 0;
					font-size: 1.11rem;
					line-height: 20px;
				}
			}
		}

		.inner-container {
			border-radius: 0 0 10px 10px;
			padding: 16px;

			.search-results-container {
				.featured-providers-result {
					margin-bottom: 0;
					min-height: 420px;

					@media screen and (min-width: 600px) {
						padding: 0;
					}

					.result-top {
						.profile-image-container {
							border-radius: 5px 0 0 5px;
							padding-bottom: calc(80% - 28px);
						}

						@include breakpoint(md) {
							.profile-image-outer,
							.profile-image-bg,
							.profile-image {
								max-height: 250px;
							}
						}

						@include breakpoint(lg) {
							.profile-image-outer,
							.profile-image-bg,
							.profile-image {
								height: 100%;
								max-height: unset;
							}
						}

						@include breakpoint(lg) {
							.profile-image-container {
								border-radius: 0;
								min-height: 100%;
							}
						}

						.profile-image-outer {
							flex: 0 1 45%;
							margin-bottom: 28px;
							max-width: unset;

							@include breakpoint(lg) {
								margin-bottom: 0;
							}
						}
					}

					.detail-scheduling-container {
						flex-direction: column;

						@include breakpoint(lg) {
							column-gap: 16px;
						}

						a.result-details {
							background-color: #ffffff;
							border-radius: 0 5px 5px 0;
							flex-grow: 1;
							margin-bottom: 28px;

							@include breakpoint(md) {
								padding: 0 20px 22px 30px;
							}

							@include breakpoint(lg) {
								border-radius: 0 0 5px 5px;
								margin-bottom: 24px;
								padding: 0 16px 20px;
							}

							.distance,
							.result-name {
								padding-left: 6px;
							}

							.distance {
								font-size: 16px;
								line-height: 24px;
							}

							.result-name {
								font-size: 1.11rem;
								line-height: 20px;
								margin-bottom: 25px;
							}

							.new-patient {
								.new-patient-text {
									font-size: 1.11rem;
									line-height: 20px;
									padding-top: 20px;
								}
							}

							.medical-group {
								align-items: center;
								display: flex;
								font-size: 16px;
								line-height: 24px;
								padding: 4px 10px;
							}
						}

						.location-scheduling {
							left: -82%;
							padding: 0 4px 32px 4px;
							position: absolute;
							top: 100%;
							width: 182%;

							@include breakpoint(md) {
								left: -82%;
								width: 182%;
							}

							@include breakpoint(lg) {
								left: 0;
								padding: 0 0 32px 0;
								position: relative;
								width: 100%;
							}

							.cta-secondary {
								background-color: $color-green-lightest;
								color: #000000;

								&:hover {
									background-color: #e3f3d0;
								}

								&:after {
									background-image: url('../images/icons/chevron-right-white.svg');
									background-repeat: no-repeat;
									content: '';
									filter: invert(1);
									height: 16px;
									margin: 18px 0;
									position: absolute;
									right: 20px;
									width: 8px;
								}
							}

							.action-cta {
								@include breakpoint(lg) {
									font-size: 20px;
									line-height: 28px;
								}
							}
						}
					}
				}
			}
		}
	}
	//GALLERY + SPEED BUMP
	&.multi {
		.featured-providers-input {
			&.react-autosuggest-container.input-icon-container {
				@include breakpoint(lg) {
					padding-bottom: 0;
					padding-top: 24px;
				}

				svg {
					transform: unset;

					@include breakpoint(md) {
						top: 68px;
					}

					@include breakpoint(lg) {
						top: 55px;
					}
				}

				@include breakpoint(md) {
					label {
						padding-bottom: 12px;
					}
				}

				@include breakpoint(lg) {
					label {
						padding-bottom: 4px;
					}
				}

				input {
					font-size: 23px;
					line-height: 32px;

					@include breakpoint(md) {
						font-size: 18px;
						line-height: 26px;
						padding: 8px 48px;
					}
				}
			}
		}

		.inner-container {
			display: flex;
			flex-direction: column;

			.search-results-container {
				column-gap: 16px;
				flex-wrap: wrap;

				@include breakpoint(sm) {
					flex-direction: row;
					flex-wrap: wrap;
					width: 100%;
				}

				.featured-providers-result {
					margin: 0;
					max-width: unset;

					@include breakpoint(lg) {
						flex: calc(33.333333% - 11px) 0;
					}

					.result-top {
						.result-details {
							padding-bottom: 0;
							width: 100%;

							.distance {
								font-size: 12px;
								line-height: 18px;
							}

							.result-name {
								font-size: 20px;
								line-height: 28px;
							}

							.accepting {
								font-size: 16px;
								line-height: 24px;
							}

							.medical-group {
								font-size: 12px;
								line-height: 18px;
							}
						}
					}
				}
			}

			div.need-more-help {
				max-width: unset;

				&:has(.view-all-call-link[href='tel:']) {
					columns: 1;

					@include breakpoint(md) {
						width: 50%;
					}
				}

				@include breakpoint(md) {
					columns: 2;
					column-gap: 42px;
				}

				@include breakpoint(lg) {
					column-gap: 28px;
				}

				@media screen and (min-width: 875px) {
					flex: unset;
					max-width: unset;
				}

				h5,
				p {
					color: #000000;
					font-size: 16px;
					font-weight: $font-weight-regular;
					line-height: 24px;

					@include breakpoint(lg) {
						font-size: 20px;
						line-height: 28px;
					}
				}

				h5 {
					margin-bottom: 4px;
				}

				p {
					margin-bottom: 10px;
				}

				@include breakpoint(lg) {
					h5,
					p {
						margin-bottom: 8px;
					}
				}

				a {
					position: relative;
					width: 100%;

					&:after {
						content: '';
						margin: 11px 0;
						position: absolute;
						right: 20px;
					}
				}

				.action-cta {
					background-color: $color-green-dark;
					position: relative;

					&:hover {
						background-color: $color-green-lightest;
					}

					@include breakpoint(md) {
						margin-bottom: 0;
					}

					&:after {
						background-image: url('../images/icons/chevron-right-white.svg');
						background-repeat: no-repeat;
						height: 16px;
						width: 8px;
					}
				}

				.view-all-call-link {
					background-color: $color-green-lightest;
					display: block;
					text-decoration: none;

					&:after {
						background-image: url('../images/icons/phone.svg');
						filter: invert(0);
						height: 20px;
						top: 0;
						width: 20px;
					}

					&:hover {
						background-color: #e3f3d0;
					}

					&[href='tel:'] {
						display: none;
					}
				}
			}
		}
		//STRICTLY GALLERY
		&:not(.horizontal) {
			.featured-providers-input {
				&.react-autosuggest-container.input-icon-container {
					padding: 24px 12px 12px;

					@include breakpoint(md) {
						padding: 28px 16px 12px;
					}

					@include breakpoint(lg) {
						padding: 24px 42px 0 30px;
					}

					svg {
						@include breakpoint(lg) {
							left: 47px;
							top: 55px;
						}
					}

					label {
						@include breakpoint(md) {
							padding-left: 24px;
						}

						@include breakpoint(lg) {
							padding-left: 6px;
						}
					}
				}
			}

			.inner-container {
				padding: 0 12px 16px;

				@include breakpoint(md) {
					padding: 0 16px 40px;
				}

				@include breakpoint(lg) {
					padding: 16px 32px 32px;
				}

				.search-results-container {
					column-gap: 10px;
					flex-direction: row;
					flex-wrap: wrap;
					margin-bottom: 28px;
					row-gap: 14px;

					@include breakpoint(md) {
						column-gap: 8px;
						margin-bottom: 42px;
					}

					@include breakpoint(lg) {
						column-gap: 16px;
						margin-bottom: 24px;
						row-gap: 16px;
					}

					.featured-providers-result {
						flex: calc(50% - 5px) 0;

						@include breakpoint(md) {
							flex: calc(25% - 6px) 0;
						}

						@include breakpoint(lg) {
							flex: calc(33.333333% - 11px) 0;
						}

						.result-top {
							flex-direction: column;
							height: 100%;

							a.profile-image-outer {
								width: 100%;
							}

							.detail-scheduling-container {
								a.result-details {
									align-items: center;
									margin-bottom: 18px;
									padding: 0 24px;

									@include breakpoint(md) {
										align-items: flex-start;
										padding: 0 14px;
									}

									@include breakpoint(lg) {
										padding: 0 16px;
									}

									.result-name {
										font-size: 16px;
										line-height: 24px;
										margin-bottom: 10px;
										text-align: center;

										@include breakpoint(md) {
											font-size: 20px;
											line-height: 28px;
											text-align: left;
										}
									}

									.new-patient-text {
										font-size: 12px;
										line-height: 18px;
										text-align: center;

										@include breakpoint(md) {
											font-size: 14px;
											line-height: 20px;
											text-align: left;
										}

										@include breakpoint(lg) {
											font-size: 16px;
											line-height: 24px;
										}
									}

									.medical-group {
										padding: 2px 8px;
										text-align: center;

										@include breakpoint(md) {
											font-size: 14px;
										}

										@include breakpoint(lg) {
											font-size: 12px;
										}
									}
								}
							}
						}
					}
				}

				@include breakpoint(md) {
					.need-more-help {
						padding: 0 18px;

						h5 {
							padding-top: 6px;
						}
					}
				}

				@include breakpoint(lg) {
					.need-more-help {
						padding: 0 4px;

						h5 {
							padding-top: 0;
						}
					}
				}
			}
		}
		//SPEED BUMP
		&.horizontal {
			.featured-providers-input {
				@include breakpoint(md) {
					padding: 28px 16px 26px;
				}

				@include breakpoint(lg) {
					padding-left: 38px;
					padding-right: 32px;
				}

				label {
					@include breakpoint(md) {
						padding-left: 6px;
					}

					@include breakpoint(lg) {
						padding-left: 8px;
					}
				}

				&.react-autosuggest-container.input-icon-container {
					svg {
						@include breakpoint(lg) {
							left: 55px;
						}
					}
				}

				+ .inner-container {
					padding: 0 16px 18px;

					@include breakpoint(md) {
						padding-bottom: 24px;
					}

					@include breakpoint(lg) {
						padding: 16px 32px 34px;
					}

					.search-results-container {
						flex-direction: column;
						flex-wrap: nowrap;
						justify-content: unset;
						margin-bottom: 22px;
						row-gap: 22px;

						@include breakpoint(md) {
							flex-direction: row;
							flex-wrap: wrap;
							margin-bottom: 24px;
							column-gap: 42px;
						}

						@include breakpoint(lg) {
							column-gap: 16px;
							row-gap: 16px;
						}

						.featured-providers-result {
							flex-direction: row;

							@include breakpoint(md) {
								flex: calc(50% - 21px) 0;
							}

							@include breakpoint(lg) {
								flex: calc(33.333333% - 11px) 0;
							}

							div.result-top {
								width: 100%;

								a.profile-image-outer {
									flex: 0 1 45%;
								}

								.result-details {
									margin-bottom: 22px;

									@include breakpoint(md) {
										padding: 0 20px 0 30px;
									}

									@include breakpoint(lg) {
										margin-bottom: 16px;
										padding: 0 16px;
									}

									.result-name {
										margin-bottom: 32px;
									}

									.medical-group {
										padding: 2px 12px 2px 8px;
									}
								}
							}
						}
					}

					.need-more-help {
						padding: 0 4px;
					}

					@include breakpoint(md) {
						.need-more-help {
							padding: 0;

							h5 {
								padding-top: 6px;
							}
						}
					}

					@include breakpoint(lg) {
						.need-more-help {
							h5 {
								padding-top: 0;
							}
						}
					}
				}
			}
		}
	}
}

//NEW OSF - OVERHAUL STYLES
.overhauled {
	display: flex;
	flex-direction: column;
	:active,
	:hover,
	:focus,
	:focus-visible {
		transition: all 300ms ease-out;
	}

	::selection {
		background-color: var(--color-text-highlight);
	}

	.jss-inner-container {
		@include jss-inner-container();
	}

	.inner-side-spacing {
		@include inner-side-spacing();
	}

	.full-inner-spacing {
		@include full-inner-spacing();
	}

	.outer-vertical-spacing {
		@include outer-vertical-spacing();
	}

	.inner-vertical-spacing {
		@include inner-vertical-spacing();
	}

	.jss-two-column {
		@include jss-two-column();

		//Updates For Components Needing Layout Changes In Two-Col Format
		.jss-three-up-wrapper {
			div.inner-wrapper {
				@include breakpoint(xl) {
					grid-template-columns: repeat(
						auto-fit,
						minmax(calc(33% - calc(var(--spacing-base) * 2)), 1fr)
					);
				}
				.content {
					word-break: break-word;
				}
			}
		}
	}

	table {
		width: 100%;

		tbody,
		tr,
		td {
			display: block;
			text-align: left;
		}

		tr {
			border-bottom: calc(var(--spacing-base) / 4) solid var(--color-bg-secondary);
		}

		td {
			@include breakpoint(mobile) {
				padding-left: calc(var(--spacing-base) * 3);
				padding-right: calc(var(--spacing-base) * 3);
			}
		}

		tr:first-of-type {
			display: none;
		}

		td:last-child {
			margin-bottom: calc(var(--spacing-base) * 4);
		}

		td:before {
			content: attr(data-label);
			display: block;
			@include osf-h4();
			margin-bottom: calc(var(--spacing-base) * 1.5);
			margin-top: calc(var(--spacing-base) * 4);
		}

		@include breakpoint(lg) {
			margin-bottom: calc(var(--spacing-base) * 3);
			border-collapse: collapse;

			tbody {
				display: table-row-group;
			}

			tr {
				display: table-row;

				td:first-child {
					padding-left: calc(var(--spacing-base) * 3);
				}
				td:last-child {
					padding-right: calc(var(--spacing-base) * 3);
				}
			}

			tr:first-of-type {
				display: table-row;

				td {
					@include osf-h4();
					border-color: hsla(0, 0%, 100%, 0);
					background-color: var(--color-bg-secondary);
					padding: calc(var(--spacing-base) * 3) var(--spacing-base) calc(var(--spacing-base) * 3)
						var(--spacing-base);

					&:first-child {
						padding-left: calc(var(--spacing-base) * 3);
					}
					&:last-child {
						padding-right: calc(var(--spacing-base) * 3);
					}
				}
			}

			tbody tr:first-of-type td:first-child {
				border-top-left-radius: var(--br-md);
				border-bottom-left-radius: var(--br-md);
			}

			tbody tr:first-of-type td:last-child {
				border-top-right-radius: var(--br-md);
				border-bottom-right-radius: var(--br-md);
			}

			td {
				padding: calc(var(--spacing-base) * 3) var(--spacing-base) calc(var(--spacing-base) * 3)
					var(--spacing-base);
				border-bottom: calc(var(--spacing-base) / 4) solid var(--color-bg-secondary);
				border-color: var(--color-bg-secondary);
				@include osf-body();
				display: table-cell;

				&:before {
					display: none;
				}
			}
		}
	}
}

.filter-btn {
	@include text(lambda, 20);
	background-color: rgba($color-white, 0.5);
	border-radius: 25px;
	color: $color-black;
	display: flex;
	margin: 0;
	margin-bottom: 0.75rem;
	padding: 0 0 0 0.5rem;
	padding: 0.625rem 1.25rem;
	position: relative;
	transition: all 0.1s ease-in-out;
	width: 100%;

	&.disabled {
		color: $color-neutral;
	}

	&.filter-btn-small {
		border-radius: 1.5625rem;
		height: 1.5625rem;
		line-height: 1;
		padding: 0.375rem 0.5rem;
		white-space: nowrap;
		width: auto;

		.filter-label {
			color: $color-neutral-dark;
		}

		.icon {
			height: 0.625rem;
			margin: 0 0.25rem 0 0.5rem;
			stroke: $color-neutral-dark;
			width: 0.625rem;

			&.icon-check {
				height: 0.75rem;
				width: 0.75rem;
			}
		}
	}

	&.filter-btn-highlight {
		background-color: $color-green-lightest;
	}

	.filter-label {
		color: $color-neutral;
		margin-left: 0.25rem;
	}

	.icon {
		align-self: center;
		fill: $color-green-dark;
		height: 1.2rem;
		margin-left: auto;
		stroke: $color-green-dark;
		width: 1.2rem;

		&.icon-search {
			height: 1rem;
			width: 1rem;
		}

		&.rotate-left {
			transform: rotate(-90deg);
		}
	}

	&:hover,
	&:focus {
		&:not(.disabled) {
			background-color: $color-blue-lightest;
			cursor: pointer;
		}

		.filter-label {
			color: $color-black;
		}

		.icon {
			fill: $color-black;
			stroke: $color-black;
		}
	}
}

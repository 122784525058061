.text-banner {
	@include text(lambda);
	background-color: $color-white;
	box-shadow: 3px 5px 6px rgba($color-black, 0.29);
	font-weight: $font-weight-bold;
	margin-bottom: 2.25rem;
	padding: 20px;
	text-transform: uppercase;

	p {
		@include text(lambda);
		color: $color-light-black;
		font-family: $font-family-sans-serif;
	}

	a {
		color: $color-blue-dark;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	:last-child {
		margin-bottom: 0;
	}

	@include breakpoint(md) {
		padding: 20px 3rem;
	}
}

/* Box Shadow */
$box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
$nav-inner-shadow: inset 0 -10px 6px -6px rgba(0, 0, 0, 0.25);
$footer-inner-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.59);
$no-box-shadow: none;

[data-theme='osfhealthcare'] {
	--btn-shadow: 0px 4px 11px -2px var(--color-btn-hover-shadow);
	--btn-shadow-light: 0px 4px 11px -2px var(--color-btn-hover-shadow-light);
}

[data-theme='choi'] {
	--btn-shadow: 0px 4px 11px -2px var(--color-btn-hover-shadow);
	--btn-shadow-light: 0px 4px 11px -2px var(--color-btn-hover-shadow-light);
}

[data-theme='sacon'] {
	--btn-shadow: 0px 4px 11px -2px var(--color-btn-hover-shadow);
	--btn-shadow-light: 0px 4px 11px -2px var(--color-btn-hover-shadow-light);
}

[data-theme='sfcon'] {
	--btn-shadow: 0px 4px 11px -2px var(--color-btn-hover-shadow);
	--btn-shadow-light: 0px 4px 11px -2px var(--color-btn-hover-shadow-light);
}

body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
footer {
	margin-top: auto;
}
#site-header.www2-header {
	background-color: $color-white;
	font-family: 'Lato';
	position: relative;
	z-index: 1;

	@include breakpoint(lg) {
		z-index: 10;
	}

	.menu-container {
		.has-children {
			&.sfHover {
				ul {
					visibility: visible !important;
					opacity: 1 !important;
					width: 100% !important;
				}
			}

			ul {
				opacity: 0;
				padding: 0.5rem 0 0;
				transition: opacity 0.1s ease-in;
				visibility: hidden;
				width: 100%;
			}
		}

		@media screen and (max-width: 1023px) {
			.nav.container {
				padding: 0;
			}
			&.mobileOpen {
				display: block;
				.has-children {
					.no-href {
						padding: 5px !important;
					}
					ul {
						display: none;
					}
					&.sfHover {
						ul {
							display: block;
							padding: 0 0 1em 5%;
						}
						ul li {
							visibility: visible !important;
							opacity: 1 !important;
							width: 100% !important;
							height: 100% !important;
						}
						ul ul {
							padding-top: 0 !important;
						}
					}

					ul li {
						opacity: 0;
						padding: 0.5rem 0 0;
						transition: opacity 0.1s ease-in;
						visibility: hidden;
						width: 100%;
						height: 0;
					}
				}
			}
		}
	}

	.toggle {
		cursor: pointer;
		font-size: 0.9em;
	}
	.toggle .arrow {
		display: inline-block;
		line-height: 1;
		transition: 0p5s transform;
		vertical-align: middle;
	}
	.toggle.open .arrow {
		transform: rotate(90deg);
	}
	.toggle-plusminus {
		display: inline-block;
		margin: 0.5em 0;
	}
	.toggle-plusminus:before {
		border: 1px solid;
		border-radius: 2px;
		content: '+';
		display: inline-block;
		font-family: verdana, sans-serif;
		height: 10px;
		width: 10px;
		line-height: 10px;
		margin-right: 4px;
		text-align: center;
	}
	.toggle-plusminus.open:before {
		content: '-';
	}

	#main-nav div > ul {
		padding-left: 0;
	}

	body,
	table {
		font-size: 14px;
		letter-spacing: 0.1px;
	}
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li {
		margin-bottom: 10px;
	}

	h1,
	h2 {
		color: #73a333;
		margin-bottom: 10px;
	}
	h3,
	h4,
	h5,
	h6 {
		color: #434343;
	}
	h1 {
		margin-bottom: 0.5em;
	}
	h1.alt {
		color: #008bbf;
		font-weight: 100;
		text-transform: uppercase;
	}
	h2,
	.entry-title {
		border-bottom: 1px solid;
		font-size: 17px;
		font-weight: normal;
	}
	h2 a,
	.entry-title a {
		color: inherit;
		text-decoration: none !important;
	}
	.alt-hd {
		background-color: #008bbf;
		border: none;
		color: #fff !important;
		display: block;
		font-size: 16px !important;
		font-weight: bold;
		margin: 0;
		padding: 6px 8px;
	}
	h2.alt-hd,
	h2.alt-hd-light {
		padding: 4px 12px;
	}
	h2.alt-hd-light {
		font-weight: normal;
	}
	h2.no-clear {
		clear: none !important;
		overflow: hidden;
	}
	h3 {
		font-size: 15px;
	}
	h4 {
		font-size: 13px;
	}
	h5 {
		font-size: 13px;
	}
	h6 {
		font-size: 13px;
		font-weight: normal;
	}
	a {
		color: #008bbf;
		text-decoration: none;
	}
	a:hover,
	a:focus {
		text-decoration: underline;
	}
	table {
		font-size: 12px;
		text-align: left;
		border-collapse: collapse;
		border: 1px solid #dedede;
		width: 100%;
		-moz-box-shadow: 1px 1px 8px #ccc;
		-webkit-box-shadow: 1px 1px 8px #ccc;
		box-shadow: 1px 1px 8px #ccc;
	}
	table thead tr th {
		font-weight: bold;
		color: #fff;
		background: #008bbf;
		padding: 5px;
		border-collapse: collapse;
	}
	table td,
	table th {
		padding: 5px;
	}
	table tr:nth-child(even) {
		background-color: #eaf5f9;
	}
	table.plain,
	form table {
		border: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: inherit;
	}
	table.plain th,
	form table th {
		padding: 8px 1em 8px 0;
		text-align: right;
		vertical-align: top;
	}
	table.plain td,
	form table td {
		color: inherit;
	}
	table.plain td input,
	form table td input {
		padding: 2px 4px;
	}
	table.plain tr:nth-child(even),
	form table tr:nth-child(even) {
		background-color: inherit;
		color: inherit;
	}
	table.details th,
	table.details td {
		padding: 0 0.5em 0.5em 0;
		text-align: left;
		vertical-align: top;
	}
	table.details th:last-child,
	table.details td:last-child {
		padding-right: 0;
	}
	.st-head-row.st-head-row-main {
		font-size: 1.5em;
	}
	.stacktable.small-only {
		display: none;
	}

	p {
		line-height: 1.3em;
	}
	img {
		border: none;
		display: inline-block;
		height: auto;
		max-width: 100%;
	}
	input,
	textarea,
	select {
		font-family: 'Lato', sans-serif;
	}
	input[type='date'] {
		height: 1em;
	}
	button[disabled],
	form.disabled,
	a.disabled {
		opacity: 0.25;
		pointer-events: none;
	}

	.container:after,
	.clearfix:after,
	#content:after {
		content: '';
		display: table;
		clear: both;
	}
	.title {
		display: block;
	}

	#site-header {
		background-color: #fff;
	}
	#site-header * {
		margin-bottom: 0;
	}
	.nav-toggle {
		cursor: pointer;
		display: none;
	}
	#main-nav-row {
		background-color: #008bbf;
		position: relative;
		z-index: 4;
	}
	#main-nav ul {
		font-size: 14px;
	}
	#main-nav li {
		list-style-type: none;
		margin: 0;
		padding: 0;
		overflow: visible;
	}
	#main-nav ul a {
		display: block;
		color: #fff;
		padding: 1px;
		text-decoration: none;
	}
	#main-nav #doctor-link a,
	#main-nav .button-link a {
		background-color: #f7911d;
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		border-radius: 6px;
		color: #fff !important;
		display: block;
		font-weight: 600;
		max-width: 300px;
		text-decoration: none;
		font-size: 14px;
		margin: 0.75em 0px 0.75em 0.5em;
		min-height: 22px;
		line-height: 24px;
		padding: 0px 20px;
		position: relative;
	}
	#main-nav #doctor-link a:hover,
	#main-nav #doctor-link a:focus,
	#main-nav .button-link a:hover,
	#main-nav .button-link a:focus {
		background-color: #73a333 !important;
	}
	#main-nav div > ul ul .callout-button {
		width: auto !important;
		padding-right: 28px !important;
		margin-top: 0 !important;
	}
	.columns {
		min-width: 50%;
	}
	.columns > ul {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		-o-column-count: 2;
		-ms-column-count: 2;
		column-count: 2;
	}
	.columns33 .columns {
		min-width: 33.3%;
	}
	#search-toggle {
		clear: none;
		float: right;
		position: relative;
	}
	#search-toggle a {
		color: #fff;
		display: block;
		margin: 8px 0 7px 8px;
	}

	#mast {
		height: 92px;
		overflow: hidden;
		// padding: 20px 0;
		text-align: center;
		position: relative;
	}
	#phone-social {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: center;
		float: right;
		min-height: 72px;
		max-width: 66%;
		min-width: 150px;
		padding: 10px 0 10px 10px;
	}
	#phone-social .phone-number {
		color: #6d3220;
		display: block;
		font-size: 26px;
		font-weight: bold;
		letter-spacing: 1px;
		line-height: 1.3em;
		margin-bottom: 6px;
		text-align: right;
		text-decoration: none;
	}
	#phone-social .phone-number a {
		color: inherit;
	}
	#social {
		float: right;
	}
	#social p {
		float: left;
	}
	[class^='icon-'],
	[class*=' icon-'],
	#social a {
		font-family: inherit !important;
	}
	[class^='icon-']:before,
	[class*=' icon-']:before,
	#social a:before {
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.icon-rotate {
		animation: rotate 4000ms infinite linear;
		display: inline-block;
	}
	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	#social a {
		background: #cce8f2;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		color: #fff;
		float: left;
		font-size: 0;
		height: 26px;
		letter-spacing: normal;
		line-height: 26px;
		margin-left: 4px;
		overflow: hidden;
		width: 26px;
	}
	#social a:hover,
	#social a:focus {
		background: #008bbf;
		text-decoration: none;
	}
	#social a:before {
		font-size: 16px;
	}
	.icon-twitter-x:before {
		height: 16px;
		width: 16px;
	}
	.alert {
		border: solid 1px #dae1e7;
		margin-bottom: 20px;
	}
	.alert-head {
		font-size: 1em;
		padding: 1em;
	}
	.alert-head.open,
	.alert-head:hover {
		background: #eaf5f9;
		cursor: pointer;
	}
	.alert-title {
		color: inherit;
		font-weight: normal;
		margin: 0 0 0.25em;
	}
	.alert-title:after {
		color: #008bbf;
		content: 'More';
		float: right;
		font-size: 0.8em;
		padding: 2px;
	}
	.open .alert-title:after {
		content: 'Less';
	}
	.alert-body {
		border-top: 1px solid #dae1e7;
		display: none;
		font-size: 1em;
		padding: 1em;
	}
	#nav-system-area {
		border-bottom: 1px solid #dae1e7;
		margin: 0 -8px 0.5em;
		padding: 0 0 0.5em;
	}
	#nav-system-area a {
		margin: 0 8px;
	}
	#nav-all-areas {
		margin-top: 1em;
	}
	a.top-alert:link,
	a.top-alert:visited {
		display: inline-block;
		text-align: center;
		width: 100%;
		padding: 5px 0px 10px;
		background: #73a333;
		color: white;
		text-transform: uppercase;
		letter-spacing: 2px;
		line-height: 18px;
	}
	a.top-alert span:nth-child(2) {
		font-size: 24px;
		position: relative;
		top: 2px;
		left: 5px;
	}
	@media screen and (max-width: 480px) {
		a.top-alert {
			letter-spacing: 1px;
		}
		a.top-alert span:nth-child(2) {
			font-size: 20px;
			position: relative;
			top: 1px;
			left: 1px;
		}
	}
	a.top-alert:hover,
	a.top-alert:active {
		background: #557a23;
		text-decoration: none;
	}

	.pagination {
		clear: both;
		color: #6d3321;
		line-height: 20px;
		margin: 20px 0;
	}
	.pagination .pages {
		border-color: #aaa;
		border-style: solid;
		border-width: 1px 1px 1px 0;
		float: right;
	}
	.pagination .pages a,
	.pagination .pages span {
		border-left: 1px solid #aaa;
		color: inherit;
		float: left;
		height: 20px;
		text-align: center;
		width: 20px;
	}
	.pagination .pages span,
	.pagination .pages a:hover,
	.pagination .pages a:focus {
		background-color: #f2ecea;
		text-decoration: none;
	}
	.blocker {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		z-index: 100000;
		box-sizing: border-box;
		background-color: rgb(0, 0, 0);
		background-color: rgba(0, 0, 0, 0.75);
		text-align: center;
	}
	.blocker:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.05em;
	}
	.blocker.behind {
		background-color: transparent;
	}
	.modal {
		display: none;
		vertical-align: middle;
		position: relative;
		z-index: 2;
		height: 645px;
		max-width: 725px;
		box-sizing: border-box;
		background: #fff;
		padding: 32px 0 0;
		text-align: left;
		width: 100%;
	}
	.modal a.close-modal {
		font-size: 1.1em;
		padding: 0.5em;
		position: absolute;
		top: 0;
		right: 0;
		display: block;
	}
	@media screen and (max-width: 700px) {
		.modal-blocked,
		.modal-blocked body {
			height: 100%;
			overflow: hidden;
			position: fixed;
			width: 100%;
		}
		.blocker {
			background: white;
			position: absolute;
		}
		.modal {
			height: 90vh;
			overflow: auto;
			padding-top: 44px;
			-webkit-overflow-scrolling: touch;
			vertical-align: top;
		}
		.modal a.close-modal {
			font-size: 1.5em;
		}
	}
	@media screen and (max-width: 1023px) {
		#main-nav ul {
			font-size: 13px;
		}
		#main-nav div > ul > li > a {
			padding: 15px 8px;
		}
		#main-nav > ul > li > ul > li {
			padding: 0 8px;
		}
		#main-nav .button-link a {
			font-size: 13px;
		}
		#main-nav .button-link a:before {
			display: none !important;
		}
		#search-toggle a {
			margin: 8px 6px 7px;
		}
		.phone-number {
			font-size: 22px !important;
		}
		.home-section {
			padding: 16px 1%;
		}
		.home-section-2x {
			width: 64%;
		}
		#callout-section {
			padding: 16px 1%;
			width: 30%;
		}
		#site-footer {
			display: block;
		}
		#site-footer .footer-col {
			display: block;
			float: left;
		}
	}
	@include breakpoint(lg) {
		body.app:not(.entity) #content,
		body.interior #content,
		body.interior-wide #content,
		body.app #site-sidebar,
		body.interior #site-sidebar,
		body.interior-wide #site-sidebar {
			padding-top: 0;
		}
		#slideshow .slide-overlay {
			left: 9%;
		}
		#slideshow .slide-focus-left .slide-overlay {
			left: auto;
			right: 9%;
		}
		.menu-container {
			display: block !important;
		}

		#main-nav.nav.container {
			padding-right: 23px;
		}

		#main-nav-row {
			position: relative;
		}
		#main-nav > div {
			width: 100%;
		}
		#main-nav div > ul {
			float: left;
			text-transform: uppercase;
		}
		#main-nav div > ul > li {
			float: left;
		}
		#main-nav div > ul > li > a {
			font-weight: 600;
			padding: 15px 12px;
		}
		#main-nav div > ul > li.sfHover > a,
		#main-nav div > ul > li > a:hover,
		#main-nav div > ul > li > a:focus {
			background-color: #00587c;
		}
		#main-nav div > ul > li > ul {
			background-color: #00587c;
			-moz-box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.6);
			-webkit-box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.6);
			box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.6);
			display: table;
			table-layout: fixed;
			left: 0;
			padding: 16px 10% 36px;
			position: absolute;
			visibility: hidden;
			width: 80.5%;
			z-index: 100;
		}
		#main-nav div > ul > li > ul:after {
			background-color: #008bbf;
			border-top: 1px solid #d7dbde;
			bottom: 0;
			content: '';
			height: 18px;
			left: 0;
			position: absolute;
			width: 100%;
		}
		#main-nav div > ul > li > ul > li {
			display: table-cell;
			padding: 0 20px;
		}
		#main-nav div > ul > li > ul > li > a {
			border-bottom: 1px solid;
			display: inline-block;
			font-size: 18px;
			margin-bottom: 0.2em;
		}
		#main-nav div > ul > li.sfHover ul,
		#main-nav div > ul > li:hover ul,
		#main-nav div > ul > li:focus ul {
			display: table;
		}
		#main-nav div > ul ul ul {
			display: block !important;
			font-size: 13px;
			letter-spacing: 0.4px;
			text-transform: none;
		}
		#main-nav div > ul ul ul a {
			margin: 0.6em 0 0;
		}
		#main-nav div > ul ul ul a:hover,
		#main-nav div > ul ul ul a:focus {
			text-decoration: underline;
		}
		#main-nav div > ul > li.no-menu-hierarchy ul {
			box-sizing: border-box;
			-ms-column-width: 240px;
			-moz-column-width: 240px;
			-o-column-width: 240px;
			-webkit-column-width: 240px;
			column-width: 240px;
			display: block;
			padding-right: calc(10% + 200px);
			width: 100%;
		}
		#main-nav div > ul > li.no-menu-hierarchy .button-link {
			position: absolute;
			right: 10%;
		}
		#main-nav div > ul > li.no-menu-hierarchy li,
		#main-nav div > ul > li.no-menu-hierarchy li ul {
			display: inline-block;
		}
		#main-nav div > ul > li.no-menu-hierarchy li a {
			border-bottom: none;
			display: inline-block;
			font-size: 12px;
			text-transform: none;
			width: 200px;
		}
		#main-nav div > ul > li > ul > li > a {
			border-bottom: 1px solid;
			width: 85%;
		}
		#main-nav div > ul ul ul {
			padding-top: 0.5em;
		}
		#main-nav div > ul ul ul a {
			margin: 0 0 0.5em;
		}
		#main-nav div > ul ul .button-link {
			float: right;
		}
		#main-nav div > ul > li > ul {
			table-layout: auto;
		}
		#main-nav div > ul > li.no-menu-hierarchy li a:hover,
		#main-nav > ul > li.no-menu-hierarchy li a:focus {
			text-decoration: underline;
		}
		.secondary-nav {
			background-color: #e0eff4;
			float: left;
			list-style-type: none;
			margin-bottom: 0;
			position: relative;
			width: 100%;
		}
		.secondary-nav li {
			margin: 0;
		}
		.secondary-nav a {
			outline: none;
		}
		.secondary-nav > li {
			color: #454545;
			float: left;
			position: relative;
		}
		.secondary-nav > li > a {
			color: inherit;
			display: block;
			padding: 10px 20px;
			position: relative;
			text-decoration: none !important;
		}
		.secondary-nav > li.first > a {
			border-left: none;
		}
		.secondary-nav > li.sfHover > a {
			z-index: 2;
		}
		.secondary-nav > li > a:hover,
		.secondary-nav > li > a:focus,
		.secondary-nav > li.sfHover > a {
			background-color: #008bbf;
			color: #fff;
		}
		.secondary-nav > li.sfHover > a {
			padding-left: 19px;
			padding-right: 19px;
			border-left: 1px solid #00587c;
			border-right: 1px solid #00587c;
			margin-bottom: -1px;
		}
		.secondary-nav > li > ul {
			background-color: #008bbf;
			border: 1px solid #00587c;
			color: #fff;
			list-style-type: none;
			padding: 1em;
			position: absolute;
			min-width: 140px;
			z-index: 1;
			visibility: hidden;
		}
		.secondary-nav > li:last-child.dropdown-right > ul {
			right: 0;
		}
		.secondary-nav > li > ul.triple {
			-moz-column-count: 3;
			-webkit-column-count: 3;
			-o-column-count: 3;
			-ms-column-count: 3;
			column-count: 3;
			width: 410px;
		}
		.secondary-nav > li > ul.double {
			-moz-column-count: 2;
			-webkit-column-count: 2;
			-o-column-count: 2;
			-ms-column-count: 2;
			column-count: 2;
			width: 270px;
		}
		.secondary-nav > li > ul a {
			color: inherit;
			display: inline-block;
			min-width: 120px;
			padding: 0.5em 5%;
			width: 90%;
		}
		.secondary-nav > li > ul a:hover,
		.secondary-nav > li > ul a:focus {
			background: #00587c;
			text-decoration: none;
		}
	}
	@media screen and (max-width: 1023px) {
		padding-top: 72px;

		#mast {
			height: 72px;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		#logo {
			background-position: bottom left;
			height: 52px;
			margin: 10px;
			max-width: 36%;
		}

		#phone-social {
			min-height: 52px;
			padding: 8px 10px 10px;
		}
		.phone-number {
			font-size: 18px !important;
		}
		#section-heading {
			font-size: 20px;
			height: 72px;
		}
		#wrapper > h1,
		#page-heading > * {
			font-size: 16px;
			padding: 6px 12px;
			text-align: center;
		}
		#page-content,
		#page-sidebar {
			display: block;
			right: auto;
		}
		#main-nav .button-link {
			display: block;
		}
		#main-nav .button-link a {
			border-radius: 0;
			margin: 0;
			max-width: none;
			font-size: 18px;
		}
		#search-container {
			text-align: center;
		}
		#search-toggle {
			position: static;
		}
		#search-toggle a {
			margin: 0;
			padding: 6px;
			position: relative;
			text-align: center;
			z-index: 4;
		}
		#search-toggle a > * {
			font-weight: normal;
			vertical-align: middle;
		}
		.search-text {
			float: none;
			min-width: 50%;
			-webkit-appearance: none;
			-webkit-border-radius: 0;
			border-radius: 0;
		}
		.search-text,
		.search-submit {
			display: inline-block;
			vertical-align: top;
		}
		#search {
			padding: 16px;
		}
		#search-results .search-result {
			margin-bottom: 2em;
		}
		.nav-toggle {
			color: #fff;
			display: block;
			font-size: 18px;
			padding: 12px 8px 11px;
			position: relative;
			text-decoration: none !important;
			z-index: 3;
		}
		.secondary-nav a {
			color: #6d3321;
		}
		.nav div > .secondary-nav > li > ul {
			background: #ebebeb !important;
		}
		.nav div > .secondary-nav > li > a {
			background: #dddddd !important;
		}
		.secondary-nav-toggle {
			background-image: linear-gradient(to bottom, #d5d5d5 25%, #f8f8f8 90%);
			color: #6d3321;
			overflow: hidden;
		}
		.secondary-nav-toggle span {
			background-position: -327px -2px;
		}
		.secondary-nav-toggle a {
			color: inherit;
			text-decoration: none;
		}
		.secondary-nav .home-link {
			display: block !important;
		}
		.menu-container {
			display: none;
		}
		#main-nav ul {
			font-size: inherit;
		}
		#main-nav div > ul ul .button-link {
			display: inline-block !important;
			width: auto !important;
		}
		#main-nav div > ul ul .button-link a {
			margin-left: 0 !important;
		}
		#main-nav #home-link a {
			background: inherit;
			height: auto;
			margin-right: 0;
			opacity: 1 !important;
			width: auto;
			text-indent: 0;
			position: static;
		}
		.nav {
			font-size: 18px;
			padding: 0;
		}
		.nav ul {
			margin: 0;
		}
		.nav div > ul,
		.nav div > ul > li,
		.nav .column {
			clear: both;
			float: none !important;
		}
		.nav .column {
			width: auto !important;
		}
		.nav div > ul > li {
			border-bottom: 1px solid #fff;
			margin: 0;
			position: relative;
		}
		.nav div > ul > li > a {
			display: block !important;
			padding: 10px 20px !important;
			text-decoration: none !important;
			text-transform: uppercase;
		}
		.nav div > ul > li.has-children > a:after {
			content: '+';
			display: block;
			font-size: 22px;
			line-height: 22px;
			font-weight: bold;
			position: absolute;
			right: 10px;
			text-align: center;
			top: 10px;
			width: 24px;
		}
		.nav div > ul > li.sfHover > a {
			background: #00587c;
			font-style: italic;
		}
		.nav div > ul > li.sfHover > a:after {
			content: '-';
			font-style: normal;
		}
		.nav div > ul ul .button-link {
			float: none;
			padding: 0 0.5em;
			width: 100%;
		}
		.nav div > ul > li > ul {
			background: #00587c !important;
			border-top: 1px solid #999;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
			position: relative !important;
			width: 86% !important;
		}
		.nav div > ul > li > ul:after {
			display: none;
		}
		.nav div > ul > li > ul > li {
			display: block !important;
			opacity: 1 !important;
		}
		.nav div > ul > li > ul > li > a {
			border-bottom: none !important;
			width: auto !important;
		}
		.nav div > ul ul ul {
			padding: 0 0 1em 5%;
		}
		.nav div > .secondary-nav ul ul {
			padding-bottom: 0;
		}
		.nav li.no-menu-hierarchy > ul,
		.nav div > ul ul ul,
		.secondary-nav > li > ul {
			font-size: 15px !important;
		}
		.nav li.no-menu-hierarchy > ul li,
		.nav div > ul ul ul li,
		.secondary-nav > li > ul li {
			margin: 0.25em 0 !important;
		}
		#page-heading {
			display: none;
		}
		.hide-md-down {
			display: none;
		}
		.show-md-down {
			display: block;
		}
	}
	@media screen and (max-width: 680px) {
		table {
			table-layout: fixed;
		}
		.home-section {
			clear: both;
			float: none !important;
			margin: 0 20px 40px !important;
			min-height: 0;
			padding: 16px;
			width: auto !important;
		}
		#callout-section {
			margin: -20px 0 20px !important;
			padding: 16px 36px;
		}
		#home-help-links {
			width: auto;
		}
		#home-help-links a {
			margin: 1em auto;
		}
		#main-nav {
			padding-left: 0;
			padding-right: 0;
		}
		#offset {
			margin: 0;
		}
		#content,
		#site-sidebar {
			clear: both;
			float: none;
			margin: 0;
			padding: 20px;
			width: auto;
		}
		.hide-mobile {
			display: none !important;
		}
		.show-mobile {
			display: block;
		}
	}

	@media screen and (max-width: 480px) {
		#section-heading {
			display: none;
		}
		.hub-col {
			display: block;
			float: none;
			margin: 20px 0;
			padding: 0 !important;
			width: auto;
		}
		.hub-col:first-child {
			margin-bottom: 40px;
		}
		.blue-theme #logo {
			display: none;
		}
		.blue-theme #specialty-logo {
			position: absolute;
			left: 0;
			width: 125px;
			padding-top: 20px;
		}
	}
}

.page-grid {
	// ensure no whitespace below footer
	min-height: calc(100vh - #{$gic-footer-height-xxs});
	padding-top: $gic-header-height-xxs;

	.content {
		@include make-container();
		flex-wrap: wrap;
	}

	.list-block-col {
		display: none;
	}

	.top-row {
		display: none;
	}

	.close-icon-wrapper {
		display: flex;
		justify-content: flex-end;
		left: 0px;
		padding: 25px 15px;
		position: absolute;
		top: 0px;
		width: 100%;
		z-index: 3;

		.icon {
			stroke: $color-green;
		}
	}

	.overview-link {
		align-items: center;
		color: $color-neutral-darker;
		display: flex;
		padding: 2rem 1rem;

		.icon {
			margin-right: 15px;
		}
	}

	.main-content-col {
		@include make-row();
		margin: 0;
		margin-bottom: 1rem;
		position: relative;
	}

	.text-content {
		background-color: white;
		padding: 20px 0;
		width: 100%;

		h1 {
			@include content-title();
		}

		h2 {
			@include content-subtitle();
		}

		ul {
			margin-bottom: 1rem;
		}

		p,
		li {
			@include text(iota);
			color: $color-neutral-darker;
			font-family: $font-family-serif;
		}

		p {
			margin-bottom: 1.25rem;
		}

		li {
			line-height: 1.5;
			padding-bottom: 0.75rem;
		}
	}

	@include breakpoint(sm) {
		.video-content-wrapper {
			margin-top: 4rem;
		}
	}

	@include breakpoint(md) {
		background-color: $color-neutral-lightest;
		// ensure no whitespace below footer
		min-height: calc(100vh - #{$gic-footer-height-md});
		padding-top: $gic-header-height-md;

		.main-content-col {
			margin-bottom: 2rem;
		}

		.top-row {
			display: block;
		}

		.close-icon-wrapper {
			display: none;
		}

		.text-content {
			padding: 20px;
		}

		.video-content-wrapper {
			margin-top: 0;
		}
	}

	@include breakpoint(lg) {
		.list-block-col {
			display: block;
			margin-bottom: 1rem;
		}
	}
}

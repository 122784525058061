.expandable-parent {
	display: flex;
	flex-direction: column;
}
.expandable-content {
	display: flex;
	flex-direction: column;

	.expandable-content-wrapper {
		display: flex;
		flex-direction: column;
		position: relative;

		.img-wrapper {
			height: fit-content;

			img {
				border-radius: var(--br-sm);
				object-fit: cover;
				width: 100%;
				height: auto;
				float: left;
				margin-bottom: calc(var(--spacing-base) * 4);
			}
		}

		.content {
			max-height: 250px;
			overflow: hidden;
			transition: max-height 0.3s ease;

			&.expanded {
				max-height: fit-content;
			}
			&.expanded:after {
				display: none;
			}

			span {
				text-transform: capitalize;
				text-align: left;
			}

			div:not(.img-wrapper),
			p {
				margin-top: calc(var(--spacing-base) * 2);
				text-align: left;
			}
		}
	}

	.icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: calc(var(--spacing-base) * 3);

		&:hover {
			background-color: var(--color-text-highlight);
		}

		img {
			cursor: pointer;
		}

		svg {
			width: 22px;
		}

		.collapsible {
			height: 15px;
			width: 20px;
		}
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: column;

		.expandable-content-wrapper {
			flex-direction: row;
			gap: calc(var(--spacing-base) * 4);

			.img-wrapper {
				margin-bottom: unset;
				height: fit-content;
				& img {
					width: 300px;
					margin: 0 calc(var(--spacing-base) * 3) var(--spacing-base) 0;
				}
			}

			.content {
				max-height: 250px;
			}
		}

		.icon-wrapper {
			cursor: pointer;
		}
	}

	@include breakpoint(lg) {
		.expandable-content-wrapper {
			.img-wrapper {
				width: 45%;
				& img {
					width: 100%;
				}
			}
		}
	}
}

.expandable-divider {
	background-color: var(--color-shadows);
	height: 2px;
}

.expandable-parent .expandable-content:last-child {
	margin-bottom: calc(var(--spacing-base) * 2);
}

.image-selector {
	align-items: center;
	display: flex;
	justify-content: center;

	.image-selections {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
		grid-gap: calc(var(--spacing-base) * 3);
		justify-items: center;
		overflow-y: scroll;
		padding: calc(var(--spacing-base) * 3);
		width: 100%;

		@media screen and (max-width: 520px) {
			padding: calc(var(--spacing-base) * 3) var(--spacing-base);
			grid-gap: calc(var(--spacing-base) * 2);
		}

		.img-selector {
			border: 2px solid transparent;
			position: relative;

			&.active {
				border: 2px solid var(--color-primary-light);
			}

			input[type='radio'] {
				appearance: none;
				cursor: pointer;
				display: block;
				position: absolute;
				width: 80px;
				height: 80px;
				left: 0;
				margin: 0;
				z-index: 1;
			}
		}
	}
	.active {
		border: 2px solid var(--color-primary-light);
	}
}

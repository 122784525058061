//NEW OSF Variables
[data-theme='osfhealthcare'] {
	//Border Radius
	--br-sm: 5px;
	--br-md: 10px;

	//Spacing
	--spacing-base: 8px;
}

//NEW CHOI Variables
[data-theme='choi'] {
	//Border Radius
	--br-sm: 24px;
	--br-md: 32px;

	//Spacing
	--spacing-base: 8px;
}

//NEW SACON Variables
[data-theme='sacon'] {
	//Border Radius
	--br-sm: 5px;
	--br-md: 10px;

	//Spacing
	--spacing-base: 8px;
}

//NEW SFCON Variables
[data-theme='sfcon'] {
	//Border Radius
	--br-sm: 5px;
	--br-md: 10px;

	//Spacing
	--spacing-base: 8px;
}

.overhauled {
	.intro-paragraph {
		.bg-primary {
			border-radius: var(--br-md);
			margin-top: calc(var(--spacing-base)* 1.25);
		}

		.content-wrapper {
			.inner-content {
				.headline {
					color: var(--color-primary-dark);
				}

				.text-content {
					margin-top: calc(var(--spacing-base) * 3);

					@include breakpoint(lg) {
						display: flex;
					}

					.left-content {
						flex: 1 1 34%;

						@include breakpoint(xl) {
							flex-basis: 38%;
						}
					}

					.divider {
						background: var(--color-shadows);
						height: 3px;
						margin: calc(var(--spacing-base) * 4) 0;
						width: 100%;

						@include breakpoint(lg) {
							flex: 0 0 3px;
							height: auto;
							margin: 0 calc(var(--spacing-base) * 5);
						}

						@include breakpoint(xl) {
							margin: 0 calc(var(--spacing-base) * 7);
						}
					}

					.right-content {
						flex: 1 1 66%;

						@include breakpoint(xl) {
							flex-basis: 62%;
						}

						p:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

//NEW OSF - Typography Classes
.overhauled,
.custom-richtext > .overhauled {
	h1 {
		@include osf-display-h1();
	}

	h2 {
		@include osf-h2();
	}

	h3 {
		@include osf-h3();
	}

	h4 {
		@include osf-h4();
	}

	p {
		@include osf-body();
	}

	div,
	span,
	p {
		color: var(--color-body-dark);
	}

	label {
		@include osf-label();
	}

	a:not(.btn-primary, .btn-secondary, .btn-tertiary, .action-btn, .osf-green-cta, .rail-wrapper, .nav-action-btn, .article-card) {
		@include osf-link();
	}

	.text-hover {
		@include text-hover();
	}

	.osf-home-h1 {
		@include osf-home-h1();
	}

	.osf-display-h1 {
		@include osf-display-h1();
	}

	.osf-h2 {
		@include osf-h2();
	}

	.osf-inner-h2 {
		@include osf-inner-h2();
	}

	.osf-h3 {
		@include osf-h3();
	}

	.osf-inner-h3 {
		@include osf-inner-h3();
	}

	.osf-h4 {
		@include osf-h4();
	}

	.osf-overview {
		@include osf-overview();
	}

	.osf-intro-copy,
	.osf-intro-copy > p {
		@include osf-intro-copy();
	}

	.osf-body {
		@include osf-body();
	}

	.osf-label {
		@include osf-label();
	}

	.osf-link {
		@include osf-link();
	}

	.osf-nav-body-copy {
		@include osf-nav-body-copy();
	}

	.osf-nav-menu-titles {
		@include osf-nav-menu-titles();
	}

	.osf-nav-section-titles {
		@include osf-nav-section-titles();
	}

	.osf-nav-top-level {
		@include osf-nav-top-level();
	}
}

//Overriding Styles for New Sites
[data-theme='choi'] {
	.overhauled,
	.custom-richtext > .overhauled {
		.osf-home-h1 {
			font-weight: var(--fw-black);
		}
		h1,
		.osf-display-h1 {
			font-weight: var(--fw-bold);
		}

		h2 {
			font-weight: var(--fw-bold);
		}
	}
}

.overhauled {
	.r2-footer {
		.footer-inner {
			background-color: var(--color-bg-secondary);
			padding: calc(var(--spacing-base) * 6) 0;
			display: flex;
			flex-direction: column;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 11px;
			@include gradient-background-light();
		}

		.footer-container {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			position: relative;
		}

		.footer-logo-container {
			margin-bottom: calc(var(--spacing-base) * 3);
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.osf-logo {
				margin-bottom: calc(var(--spacing-base) * 3);

				img {
					height: auto;
				}
			}
		}

		.social-links {
			margin-top: auto;
			display: flex;
			margin-bottom: calc(var(--spacing-base) * 2);
			justify-content: flex-start;

			.social-link {
				height: 35px;
				margin-right: calc(var(--spacing-base));
				width: 35px;

				.icon {
					&.icon-linkedin:before,
					&.icon-twitter:before {
						content: '';
					}

					&:hover {
						svg > :first-child {
							fill: var(--color-secondary-light);
						}
					}

					&.icon-twitter-x {
						background-color: var(--color-secondary-dark);
						border-radius: 50%;

						&.icon-twitter-x:before {
							height: 50%;
							width: 50%;
						}
					}
				}
			}
		}

		.mission-text {
			margin-bottom: auto;
			font-size: var(--fs-btn-text);
			line-height: var(--lh-extra);
			color: var(--color-body-dark);
			text-align: left;
			padding-bottom: calc(var(--spacing-base) * 4);
		}

		.footer-link-block {
			margin-bottom: calc(var(--spacing-base) * 4);
		}

		.footer-link-title {
			text-transform: uppercase;
			margin: 0 0 calc(var(--spacing-base) * 4);
		}

		.footer-link {
			text-decoration: none;
			font-weight: var(--fw-regular);

			&:hover {
				text-decoration: underline;
				color: var(--color-body-dark);
			}
		}

		.footer-link-list .link-content .externalLink-icon {
			padding-left: calc(var(--spacing-base) / 2);
		}

		.footer-legal {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			position: relative;
			margin: 0 auto;

			.legal-links {
				.legal-link {
					display: inline-block;
					position: relative;

					&:hover {
						text-decoration: underline;
					}

					&:not(:first-of-type) {
						border-left: 1px solid;
						margin-left: calc(var(--spacing-base));
						padding-left: calc(var(--spacing-base));
					}

					a {
						font-size: var(--fs-label);
						line-height: var(--lh-extra);
						color: var(--color-body-dark);
						letter-spacing: var(--ls-small);
						text-decoration: none;
						font-weight: var(--fw-regular);
					}
				}
			}
		}
		/* Breakpoints */
		@include breakpoint(xs) {
			.footer-legal {
				flex-direction: column;
				align-items: flex-start;
				margin: 0 calc(var(--spacing-base) * 4);
			}

			.footer-container {
				margin: 0 calc(var(--spacing-base) * 4);
			}

			.footer-logo-container {
				order: 1;
			}

			.link-blocks-container {
				order: 2;
			}

			.social-links {
				order: 3;
			}

			.legal-links {
				margin-left: 0;
			}

			.footer-link-header {
				display: flex;
				align-items: center;
				gap: 12px;
				margin: 0 0 calc(var(--spacing-base) * 4);

				.footer-link-title {
					margin: 0;
				}

				.arrow-collapse {
					display: inline-block;
					cursor: pointer;
					transition: transform 0.3s ease;
					stroke-width: 2px;

					&.rotated {
						transform: rotate(180deg);
					}
				}
			}

			.footer-link-list {
				height: 0;
				opacity: 0;
				overflow: hidden;
				transition: max-height 0.3s ease, opacity 0.3s ease;

				&.expanded {
					height: auto;
					opacity: 1;
				}
			}
		}

		@include breakpoint(md) {
			.social-links {
				order: 2;
				padding-bottom: calc(var(--spacing-base) * 5);
			}

			.mission-text {
				padding-bottom: calc(var(--spacing-base) * 4);
			}

			.footer-legal {
				flex-direction: row;
				align-items: flex-start;
				margin-top: calc(var(--spacing-base) * 3);
				font-size: 0.875rem;
				line-height: 1.2857142857;
			}

			.legal-links {
				margin-left: calc(var(--spacing-base) / 2);
				padding-left: calc(var(--spacing-base));
				border-left: 1px solid;
			}

			.link-blocks-container {
				order: 3;
				display: flex;
				flex-direction: row;
			}

			.link-blocks-container .arrow-collapse {
				display: none;
			}

			.footer-link-list {
				height: auto;
				opacity: 1;
				overflow: visible;
			}

			.footer-link-block {
				display: flex;
				flex-direction: column;
				flex: 0 1 33.333%;
				padding: calc(var(--spacing-base) / 2) calc(var(--spacing-base) * 3);
				position: relative;

				&:first-of-type {
					padding-left: unset;
				}

				&:before {
					outline: 1px solid var(--color-body-dark);
					content: '';
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					transform: translateX(-50%);
				}

				&:first-of-type {
					&:before {
						display: none;
					}
				}
			}
		}

		@include breakpoint(lg) {
			.footer-legal {
				flex-direction: row;
				align-items: center;
				width: auto;
			}

			.footer-container {
				flex-direction: row;
				justify-content: space-between;
			}

			.footer-logo-container {
				align-items: flex-start;
				margin-bottom: 0;
				padding-right: calc(var(--spacing-base) * 5);
				flex: 0 1 30%;
			}

			.footer-logo-container .mission-text {
				line-height: initial;
			}

			.social-links {
				order: 3;
				position: absolute;
				bottom: 0;
				align-self: flex-start;
				padding-bottom: 0;
			}

			.link-blocks-container {
				order: 2;
				display: flex;
				flex: 0 1 70%;
				justify-content: space-between;
			}

			.footer-link-block {
				&:first-of-type {
					padding-left: calc(var(--spacing-base) * 3);
				}

				&:first-of-type:before {
					display: block;
				}
			}

			.footer-link-title {
				text-align: left;
			}
		}

		@include breakpoint(xl) {
			.footer-container {
				margin: 0 calc(var(--spacing-base) * 8);
			}

			.footer-link-block {
				padding: calc(var(--spacing-base) / 2) calc(var(--spacing-base) * 8);

				&:first-of-type {
					padding-left: calc(var(--spacing-base) * 8);
				}
			}

			.footer-legal {
				padding-top: calc(var(--spacing-base) * 6);
				margin: 0 calc(var(--spacing-base) * 8);
			}
		}
	}
}

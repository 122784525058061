.blog-subscribe {
	background-color: $color-neutral-lightest-2;
	margin-bottom: 2rem;

	.form-content {
		padding: 20px;
	}

	.blog-subscribe-heading {
		@include text(mu);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	.email-input {
		@include text(lambda);
		background-color: rgba($color-white, 0.5);
		border: 0;
		border-radius: 20px;
		color: $color-neutral;
		height: 40px;
		padding: 10px 15px;
		width: 100%;
		&::placeholder {
			color: $color-neutral;
		}
	}

	.subscribe-btn {
		@include text(iota, 20);
		color: $color-light-black;
		background-color: $color-green-lightest;
		display: flex;
		height: 65px;
		padding: 12px 20px;
		position: relative;
		transition: all 0.1s ease-in-out;
		width: 100%;

		&:hover,
		&:focus {
			background-color: $color-blue-lightest;
			cursor: pointer;
		}

		.icon {
			height: 17px;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			width: 17px;
		}
	}
}

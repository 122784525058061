.all-services {
	background-color: $color-white;
	padding-bottom: 2rem;

	.services-container {
		@include make-container();
	}

	.mobile-filters-toggle {
		@include text(kappa, 23);
		align-items: center;
		background-color: $color-green-lightest;
		display: flex;
		font-weight: $font-weight-medium;
		height: 60px;
		justify-content: space-between;
		padding: 0 15px;
		top: 0;
		width: 100%;
		z-index: 6;

		&:focus:not(:focus-visible),
		&:focus-visible {
			outline: 2px solid $color-white;
		}

		.icon-chevron-right {
			transform: rotate(90deg);
		}

		&:hover {
			cursor: pointer;
		}

		.mobile-filters-title {
			color: $color-black;
		}
	}

	.services-filters {
		background-color: $color-white;
		display: none;
		opacity: 0;
		overflow: auto;
		padding: 15px;
		pointer-events: none;

		&.mobile-filters-open {
			animation: fade-in 0.4s;
			display: block;
			height: 100vh;
			left: 0;
			opacity: 1;
			pointer-events: auto;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 9;

			@keyframes fade-in {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
		}

		&.closing {
			animation: fade-out 0.4s;
			opacity: 0;

			@keyframes fade-out {
				from {
					opacity: 1;
				}
				to {
					opacity: 0;
				}
			}
		}

		.mobile-filters-toggle {
			margin: -15px -15px 2rem -15px;
			position: relative;
			width: calc(100% + 30px);

			.icon-chevron-right {
				transform: rotate(180deg);
				margin-right: 1rem;
			}

			span {
				color: $color-black;
				align-items: center;
				display: flex;
			}
		}
	}

	.filter-header {
		@include text(iota, 20);
		color: $color-light-black;
		font-weight: $font-weight-medium;
		margin: 0 0 10px;

		&.browse-all {
			font-weight: $font-weight-bold;
			margin: 0 0 20px;
		}
	}

	.filter-section {
		margin-bottom: 30px;
	}

	.input-icon-container {
		display: flex;
		position: relative;

		label {
			@include text(lambda, 14);
			color: $color-black;
			left: 20px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		input[type='text'] {
			@include text(lambda, 14);
			background-color: $color-neutral-lightest-2;
			color: $color-black;
			padding: 10px 40px 10px 68px;
		}

		.icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			stroke: $color-green-dark;
			stroke-width: 2px;
		}
	}

	.tag {
		display: flex;

		.tag-description {
			margin-bottom: 10px;
		}

		.close-btn {
			margin-bottom: 10px;

			&:hover,
			&:focus {
				background-color: $color-blue-lightest;
				cursor: pointer;
			}
		}
	}

	.view-only-btn {
		@include text(kappa, 16);
		align-items: center;
		background-color: $color-neutral-lightest-2;
		border-radius: 20px;
		color: $color-neutral-darker;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 10px;
		min-height: 40px;
		padding: 10px 20px;
		width: 100%;

		&:hover,
		&:focus {
			background-color: $color-blue-lightest;
			cursor: pointer;
		}
	}

	.starting-letters {
		display: flex;
		flex-wrap: wrap;
	}

	.filter-letter-btn-wrapper {
		flex: 0 1 16.666%;
	}

	.filter-letter-btn {
		@include text(kappa, 16);
		align-items: center;
		background-color: $color-neutral-lightest-2;
		border-radius: 20px;
		color: $color-neutral-darker;
		display: flex;
		height: 40px;
		justify-content: center;
		margin-bottom: 5px;
		transition: all 0.1s ease-in-out;
		width: 40px;

		&.active {
			background-color: $color-neutral-dark;
			color: $color-white;

			&:focus {
				background-color: $color-neutral-dark;
				color: $color-white;
			}
		}

		&[disabled] {
			background-color: $color-neutral-lightest-4;
			color: $color-neutral-light;
			pointer-events: none;
		}

		&:hover {
			background-color: $color-blue-lightest;
			cursor: pointer;
		}
	}

	.services-title {
		@include text(zeta, 30);
		color: $color-green-dark;
		font-family: $font-family-serif;
		font-weight: $font-weight-regular;
		margin: 30px 0 20px;
	}

	.services-section {
		margin-bottom: 30px;
	}

	.services-section-letter {
		@include text(kappa);
		color: $color-light-black;
		font-family: $font-family-sans-serif;
		margin: 0 0 10px;
	}

	.services-list {
		a {
			@include text(kappa);
			color: $color-green-dark;
			display: inline-block;
			margin: 0 0 10px;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	mark {
		background-color: $color-green-mid-light;
	}

	@include breakpoint(md) {
		padding-top: 2rem;

		.mobile-filters-toggle {
			display: none;
		}

		.services-row {
			@include make-row();
		}

		.services-filters {
			@include make-col-ready();
			display: block;
			flex: 0 0 260px;
			opacity: 1;
			pointer-events: auto;
		}

		.services-listings {
			@include make-col-ready();
			flex: 1;
			margin-left: 20px;
		}

		.services-title {
			font-size: 34px;
			line-height: 38px;
			margin-top: 56px;
		}

		.services-list {
			columns: 3;
		}
	}
}

.tab-group {
	.nav-tabs,
	.nav-content {
		display: none;
	}

	.nav-tabs {
		.nav-link {
			&:hover:not(.active) {
				background-color: var(--color-bg-primary);
			}
		}
	}

	.nav-tabs-mobile {
		.nav-link {
			align-items: center;
			border-radius: var(--br-md);
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			margin-bottom: var(--spacing-base);
			padding: var(--spacing-base) calc(var(--spacing-base) * 2);
			white-space: nowrap;
			width: 100%;

			&.active {
				background-color: var(--color-base);
				font-weight: var(--fw-bold);
			}

			&:hover:not(.active) {
				background-color: var(--color-bg-primary);
			}

			.icon-down {
				transform: rotate(90deg);
			}

			.icon-up {
				transform: rotate(-90deg);
			}
		}
	}

	.nav-content-mobile {
		margin-bottom: var(--spacing-base);

		.nav-pane-mobile {
			background-color: vvar(--color-base);
			padding: calc(var(--spacing-base) * 2);
		}

		& img {
			max-width: 100%;
			margin: 0px !important;
			height: auto !important;
		}
	}

	@include breakpoint(md) {
		.nav-tabs {
			border-radius: var(--br-md);
			display: flex;
			flex-wrap: nowrap;
			gap: calc(var(--spacing-base) / 2);
			overflow: hidden;

			.nav-link {
				cursor: pointer;
				flex-grow: 1;
				padding: var(--spacing-base) calc(var(--spacing-base) * 2);
				white-space: nowrap;

				&.active {
					background-color: var(--color-base);
					font-weight: var(--fw-bold);
				}
			}
		}

		.nav-tabs-mobile {
			display: none;
		}

		.nav-content {
			display: block;
			margin-top: calc(var(--spacing-base) / 2);

			.nav-pane {
				background-color: var(--color-base);
				padding: calc(var(--spacing-base) * 2);
			}
		}
	}
}

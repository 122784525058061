.overhauled {
	.action-btn-group {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		top: -22px;

		.action-btn-content {
			align-items: center;
			display: flex;
			gap: var(--spacing-base);
		}

		.action-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			padding: calc(var(--spacing-base) * 2) var(--spacing-base);
			background-color: var(--color-base);
			color: var(--color-primary-dark);
			cursor: pointer;
			position: relative;
			box-sizing: border-box;
			border-radius: 5px;
			box-shadow: 0px 4px 16px 0px var(--color-shadows);
			overflow: visible;
			justify-content: center;
			margin-bottom: calc(var(--spacing-base) * 2);

			@include breakpoint(sm) {
				padding: calc(var(--spacing-base) * 2);
			}

			.icon {
				color: var(--color-primary-light);
				flex: 0 0 auto;
				height: auto;
				max-width: 50px;
				overflow: visible;
				position: relative;
				width: max-content;

				@include breakpoint(lg) {
					max-width: unset;
				}

				svg {
					height: 100%;
					width: 100%;
					fill: var(--color-primary-light);
					stroke: none;
					max-height: 32px;
				}
			}

			.action-btn-text {
				align-items: center;
			}

			.action-text {
				display: flex;
				flex-direction: column;
				align-items: center;
				color: var(--color-primary-dark);
				margin-bottom: 0;
				text-align: center;
			}

			&:hover {
				background-color: var(--color-bg-secondary);
			}
		}
	}

	@include breakpoint(lg) {
		.action-btn-group {
			background-color: var(--color-base);
			flex-direction: row;
			max-width: calc(100% - (var(--spacing-base) * 2));
			min-height: 200px;
			border-radius: 10px;
			box-shadow: 0px 4px 16px 0px var(--color-shadows);
			top: -60px;

			.action-btn-content {
				box-sizing: border-box;
				flex-direction: column;
				gap: calc(var(--spacing-base) * 2);
				width: 100%;
			}

			.action-btn {
				display: inline-grid;
				flex-direction: column;
				flex: 1 1 33%;
				position: relative;
				box-shadow: none;
				border: none;
				min-height: 200px;
				box-sizing: border-box;
				margin-bottom: 0;

				&:not(:first-child):before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 2px;
					background-color: var(--color-shadows);
				}

				&:hover {
					background-color: var(--color-bg-secondary);
				}

				.icon {
					svg {
						max-height: 60px;
					}
				}

				.action-text {
					align-items: center;
					margin-left: unset;
					text-align: center;
					margin-bottom: unset;
				}
			}
		}
	}

	@include breakpoint(xl) {
		.action-btn-group {
			margin: 0 auto;
			max-width: 1130px;
			top: -70px;
		}

		.action-btn {
			height: 150px;

			.action-btn-content {
				gap: calc(var(--spacing-base) * 3);
			}

			.icon {
				svg {
					max-height: 75px;
				}
			}
		}
	}
}

.in-page-message {
	text-align: center;
	.icon-container {
		margin-bottom: 1rem;
		text-align: center;
		img {
			display: block;
			max-width: unset;
			margin: auto;
		}
	}

	.icon {
		height: 40px;
		width: 40px;
	}
	.in-page-message-heading {
		@include text(iota, 20);
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	.in-page-message-description {
		@include text(kappa, 24);
		margin-bottom: 20px;
	}

	.action-btns-container {
		margin: 0 -1rem -2.25rem;
	}

	.action-btn {
		align-items: center;
		justify-content: center;
		min-height: 0;
		width: 100%;

		&.action-btn-secondary {
			background-color: $color-neutral-lightest;

			&:hover {
				background-color: $color-blue-lightest;
			}
		}
	}

	@include breakpoint(md) {
		.action-btns-container {
			display: flex;
			justify-content: center;
			margin: 0;
		}

		.action-btn {
			flex: 0 1 auto;
			min-width: 230px;

			&:not(:last-child) {
				margin-right: 15px;
			}

			&:not(:first-child) {
				margin-left: 15px;
			}
		}
	}

	@include breakpoint(lg) {
		.action-btns-container {
			display: flex;
			margin: 0;
		}

		.action-btn {
			width: auto;
		}
	}
}

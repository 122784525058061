ol,
ul {
	&.osf-bullets-2col {
		list-style: disc;
	}
	@include breakpoint(md) {
		&.two-columns,
		&.three-columns,
		&.four-columns,
		&.osf-bullets-2col {
			columns: 2;
			column-gap: 1rem;
		}
	}

	@include breakpoint(lg) {
		&.three-columns,
		&.four-columns {
			columns: 3;
			column-gap: 1rem;
		}
	}

	@include breakpoint(xl) {
		&.four-columns {
			columns: 4;
			column-gap: 1rem;
		}
	}
}

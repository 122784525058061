.overhauled {
	.content-listing {
		display: flex;
		.bg-wrapper {
			width: 100%;

			.inner-content {
				display: flex;
				flex-direction: column;
				gap: calc(var(--spacing-base) * 3);

				@include breakpoint(md) {
					flex-direction: row;
				}
				@include breakpoint(lg) {
					gap: calc(var(--spacing-base) * 6);
				}
			}

			.listing-img {
				height: 207px;
				position: relative;

				@include breakpoint(md) {
					flex: 0 0 35%;
					height: auto;
					min-height: 207px;
				}

				@include breakpoint(lg) {
					flex-basis: 41%;
					height: 322px;
				}

				@include breakpoint(xl) {
					height: 370px;
				}

				img {
					border-radius: var(--br-md);
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.listing-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				row-gap: calc(var(--spacing-base) * 3);

				p {
					margin-bottom: var(--spacing-base);
				}

				p:last-child {
					margin-bottom: 0;
				}
			}

			h2.listing-heading {
				color: var(--color-primary-dark);
			}

			@include breakpoint(md) {
				&.img-right {
					.inner-content {
						flex-direction: row-reverse;
						justify-content: start;
					}
				}
			}
		}
		.modal-open-btn {
			cursor: pointer;
			left: calc(50% - calc(var(--spacing-base) * 4));
			position: absolute;
			top: calc(50% - calc(var(--spacing-base) * 4));

			&:hover {
				svg {
					background-color: var(--color-overlay-opacity);
					box-shadow: var(--btn-shadow);
				}
			}

			svg {
				background-color: rgba(51, 50, 48, 0.4);
				border-radius: 100%;
				fill: var(--color-base);
			}
		}
	}
}

.care-actions {
	width: 100%;
	background-color: $color-white;
	box-shadow: 3px 5px 6px rgba($color-black, 0.29);
	margin-bottom: 2.25rem;
	padding: 20px;

	.care-action-heading {
		@include text(kappa);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	.care-action {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		.icon {
			height: 58px;
			fill: $color-neutral-darker;
			margin-bottom: 10px;
			width: 58px;

			&.icon-virtual-visit {
				height: 50px;
				width: 50px;
			}
			&.icon-chat {
				height: unset;
			}
		}
	}

	.care-action-link {
		@include text(theta, 28);
		align-items: center;
		background-color: $color-green-lightest;
		color: $color-black;
		display: flex;
		justify-content: center;
		padding: 1rem;
		text-decoration: none;
		transition: background-color 0.1s ease-in-out;
		width: 100%;
		text-align: center;

		&:hover,
		&:focus {
			background-color: $color-blue-lightest;
		}
	}

	@include breakpoint(md) {
		.care-action {
			flex-direction: row;

			.icon {
				margin-bottom: 0;
				margin-right: 20px;

				&.icon-virtual-visit {
					margin-left: 4px;
					margin-right: 24px;
				}
			}
		}
	}
}

@import '../mixins/breakpoint';
/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */

/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */

$font-size-scale: (
	alpha: 60,
	beta: 48,
	gamma: 40,
	delta: 30,
	epsilon: 26,
	zeta: 24,
	eta: 22,
	theta: 20,
	iota: 18,
	kappa: 16,
	lambda: 14,
	mu: 12,
);

$line-height-scale: (
	alpha: 60,
	beta: 48,
	gamma: 40,
	delta: 30,
	epsilon: 32,
	zeta: 32,
	eta: 32,
	pie: 36,
	theta: 30,
	iota: 24,
	kappa: 22,
	lambda: 20,
	mu: 18,
);

$font-family-sans-serif: brandon-grotesque, sans-serif;
$font-family-serif: chaparral-pro, serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

//NEW OSF - CSS Typography Variables
[data-theme='osfhealthcare'] {
	//Font Families
	--ff-sans-serif: brandon-grotesque, sans-serif;
	--ff-serif: chaparral-pro, serif;

	//Font Weights
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-bold: 700;

	//Line Heights
	--lh-full: 100%;
	--lh-extra: 120%;

	//Letter Spacing
	--ls-small: 0.42px;

	//Font Sizes
	--fs-home-h1: 50px;
	--fs-display-h1: 44px;
	--fs-h2: 36px;
	--fs-inner-h2: 30px;
	--fs-h3: 30px;
	--fs-inner-h3: 18px;
	--fs-h4: 22px;
	--fs-overview: 24px;
	--fs-intro-copy: 22px;
	--fs-body: 18px;
	--fs-label: 14px;
	--fs-nav-body-copy: 18px;
	--fs-nav-menu-titles: 18px;
	--fs-nav-section-titles: 20px;
	--fs-nav-top-level: 18px;
	--fs-btn-text: 16px;

	@include breakpoint(md) {
		--fs-home-h1: 72px;
		--fs-display-h1: 58px;
		--fs-h2: 46px;
		--fs-inner-h2: 40px;
		--fs-h3: 36px;
		--fs-inner-h3: 22px;
		--fs-h4: 24px;
	}

	@include breakpoint(xl) {
		--fs-home-h1: 96px;
		--fs-display-h1: 70px;
		--fs-h2: 56px;
		--fs-inner-h2: 50px;
		--fs-h3: 40px;
		--fs-inner-h3: 26px;
		--fs-h4: 28px;
		--fs-overview: 32px;
		--fs-intro-copy: 28px;
		--fs-body: 20px;
	}
}

//NEW CHOI - CSS Typography Variables
[data-theme='choi'] {
	//Font Families
	--ff-sans-serif: brandon-grotesque, sans-serif;
	--ff-serif: brandon-grotesque, sans-serif;

	//Font Weights
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-bold: 700;
	--fw-black: 900;

	//Line Heights
	--lh-full: 100%;
	--lh-extra: 120%;

	//Letter Spacing
	--ls-small: 0.42px;

	//Font Sizes
	--fs-home-h1: 50px;
	--fs-display-h1: 44px;
	--fs-h2: 36px;
	--fs-inner-h2: 30px;
	--fs-h3: 30px;
	--fs-inner-h3: 18px;
	--fs-h4: 22px;
	--fs-overview: 24px;
	--fs-intro-copy: 22px;
	--fs-body: 16px;
	--fs-label: 14px;
	--fs-nav-body-copy: 18px;
	--fs-nav-menu-titles: 18px;
	--fs-nav-section-titles: 20px;
	--fs-nav-top-level: 18px;
	--fs-btn-text: 16px;

	@include breakpoint(md) {
		--fs-home-h1: 72px;
		--fs-display-h1: 58px;
		--fs-h2: 46px;
		--fs-inner-h2: 40px;
		--fs-h3: 36px;
		--fs-inner-h3: 22px;
		--fs-h4: 24px;
		--fs-body: 18px;
	}

	@include breakpoint(xl) {
		--fs-home-h1: 96px;
		--fs-display-h1: 70px;
		--fs-h2: 56px;
		--fs-inner-h2: 50px;
		--fs-h3: 40px;
		--fs-inner-h3: 26px;
		--fs-h4: 28px;
		--fs-overview: 32px;
		--fs-intro-copy: 28px;
		--fs-body: 20px;
	}
}

//NEW SACON - CSS Typography Variables
[data-theme='sacon'] {
	//Font Families
	--ff-sans-serif: brandon-grotesque, sans-serif;
	--ff-serif: chaparral-pro, serif;

	//Font Weights
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-bold: 700;

	//Line Heights
	--lh-full: 100%;
	--lh-extra: 120%;

	//Letter Spacing
	--ls-small: 0.42px;

	//Font Sizes
	--fs-home-h1: 50px;
	--fs-display-h1: 44px;
	--fs-h2: 36px;
	--fs-inner-h2: 30px;
	--fs-h3: 30px;
	--fs-inner-h3: 18px;
	--fs-h4: 22px;
	--fs-overview: 24px;
	--fs-intro-copy: 22px;
	--fs-body: 16px;
	--fs-label: 14px;
	--fs-nav-body-copy: 18px;
	--fs-nav-menu-titles: 18px;
	--fs-nav-section-titles: 20px;
	--fs-nav-top-level: 18px;
	--fs-btn-text: 16px;

	@include breakpoint(md) {
		--fs-home-h1: 72px;
		--fs-display-h1: 58px;
		--fs-h2: 46px;
		--fs-inner-h2: 40px;
		--fs-h3: 36px;
		--fs-inner-h3: 22px;
		--fs-h4: 24px;
		--fs-body: 18px;
	}

	@include breakpoint(xl) {
		--fs-home-h1: 96px;
		--fs-display-h1: 70px;
		--fs-h2: 56px;
		--fs-inner-h2: 50px;
		--fs-h3: 40px;
		--fs-inner-h3: 26px;
		--fs-h4: 28px;
		--fs-overview: 32px;
		--fs-intro-copy: 28px;
		--fs-body: 20px;
	}
}

//NEW SFCON - CSS Typography Variables
[data-theme='sfcon'] {
	//Font Families
	--ff-sans-serif: brandon-grotesque, sans-serif;
	--ff-serif: chaparral-pro, serif;

	//Font Weights
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-bold: 700;

	//Line Heights
	--lh-full: 100%;
	--lh-extra: 120%;

	//Letter Spacing
	--ls-small: 0.42px;

	//Font Sizes
	--fs-home-h1: 50px;
	--fs-display-h1: 44px;
	--fs-h2: 36px;
	--fs-inner-h2: 30px;
	--fs-h3: 30px;
	--fs-inner-h3: 18px;
	--fs-h4: 22px;
	--fs-overview: 24px;
	--fs-intro-copy: 22px;
	--fs-body: 16px;
	--fs-label: 14px;
	--fs-nav-body-copy: 18px;
	--fs-nav-menu-titles: 18px;
	--fs-nav-section-titles: 20px;
	--fs-nav-top-level: 18px;
	--fs-btn-text: 16px;

	@include breakpoint(md) {
		--fs-home-h1: 72px;
		--fs-display-h1: 58px;
		--fs-h2: 46px;
		--fs-inner-h2: 40px;
		--fs-h3: 36px;
		--fs-inner-h3: 22px;
		--fs-h4: 24px;
		--fs-body: 18px;
	}

	@include breakpoint(xl) {
		--fs-home-h1: 96px;
		--fs-display-h1: 70px;
		--fs-h2: 56px;
		--fs-inner-h2: 50px;
		--fs-h3: 40px;
		--fs-inner-h3: 26px;
		--fs-h4: 28px;
		--fs-overview: 32px;
		--fs-intro-copy: 28px;
		--fs-body: 20px;
	}
}

.overhauled {
	.accordion-top-header {
		margin-top: var(--spacing-base);
		margin-bottom: var(--spacing-base);
		@include osf-h2;
		color: var(--color-primary-dark);

		h2 {
			color: var(--color-primary-dark);
		}
	}

	.accordion {
		.accordion-item {
			&.active {
				.accordion-header {
					border-bottom: none;

					&.background {
						border-radius: var(--br-md);
					}
				}

				.accordion-content {
					border-bottom: 1px solid var(--color-shadows);
					max-height: unset;
					padding-top: calc(var(--spacing-base) * 2.5);
					padding-bottom: calc(var(--spacing-base) * 2.5);
				}
			}
		}

		.osf-image-right {
			max-width: 50% !important;
			margin-left: calc(var(--spacing-base) * 2) !important;
			margin-bottom: calc(var(--spacing-base) * 2) !important;
			margin-top: calc(var(--spacing-base) * 2) !important;
			float: right !important;
			height: auto !important;
		}

		.osf-image-left {
			max-width: 50% !important;
			margin-right: calc(var(--spacing-base) * 2) !important;
			margin-bottom: calc(var(--spacing-base) * 2) !important;
			margin-top: calc(var(--spacing-base) * 2) !important;
			float: left !important;
			height: auto !important;
		}

		.accordion-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 10px;
			padding-top: 10px;
			cursor: pointer;
			border-bottom: 1px solid var(--color-shadows);

			&.background {
				border-radius: var(--br-md);
				border: none;
				padding: 10px 32px 10px 32px;

				&:not(:last-child) {
					margin-bottom: var(--spacing-base);
				}
			}

			&:hover {
				background-color: var(--color-bg-primary);
			}

			.accordion-label {
				padding-right: calc(var(--spacing-base) * 2);

				&.osf-h2 {
					color: var(--color-primary-dark);
				}
			}

			svg {
				height: 18px;
				min-width: 12px;
			}
		}

		.accordion-content {
			padding-left: calc(var(--spacing-base) * 2.5);
			padding-right: calc(var(--spacing-base) * 2.5);
			max-height: 0;
			padding-top: 0;
			padding-bottom: 0;
			overflow: hidden;
			transition: max-height 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease;

			span,
			p,
			a:not(.osf-green-cta, .btn-primary, .btn-secondary, .btn-tertiary) {
				padding: 0;
				margin: 0;
			}

			p {
				margin-bottom: calc(var(--spacing-base) * 2);
			}
		}

		.accordion-icon {
			img {
				transition: transform 0.5s ease-in-out;
				transform: unset;
			}
		}

		.rotate-180 {
			transform: rotate(180deg);
		}
	}
}

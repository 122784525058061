.bottom-cta {
	height: 240px;
	padding: 0px;
	position: relative;

	img {
		height: 100%;
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	.content {
		background-color: rgba($color-white, 0.9);
		height: 180px;
		padding: 30px;
		position: absolute;
		top: 30px;
		left: 70px;
		right: 70px;
		margin-left: auto;
		margin-right: auto;
		max-width: $inner-container-width;
		margin: 0 auto;

		h2 {
			@include text(34, pie);
			color: $color-green-dark;
			font-family: $font-family-serif;
			font-weight: $font-weight-light;
		}

		p {
			@include text(kappa, mu);
			color: $color-neutral-darker;
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-medium;
			margin: unset;
		}

		.icon-wrapper {
			display: flex;
			justify-content: flex-end;

			svg {
				transform: rotate(90deg);
			}
		}
	}

	@include breakpoint(xs) {
		.content {
			top: 30px;
			left: 30px;
			right: 30px;

			p {
				color: #333230;
				font-family: $font-family-sans-serif;
				font-weight: 500;
			}
		}
	}

	@include breakpoint(sm) {
		.content {
			.icon-wrapper {
				margin-top: 25px;
			}

			p {
				font-size: 1.25rem;
				line-height: 1.1111111111;
			}
		}
	}

	@include breakpoint(md) {
		.content {
			.icon-wrapper {
				margin-top: 45px;
			}
		}
	}

	@include breakpoint(lg) {
		.content {
			.icon-wrapper {
				margin-top: 45px;
			}
		}
	}
}

.simulation-banner-two {
	background-color: $color-neutral-lightest-2;
	padding: 20px;

	.banner-content {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 10px;
		width: 100%;
		.image-wrapper {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 250px;

			img {
				//width: 100%;
				width: 180px;
				height: 31px;
			}
		}

		.content {
			@include text(mu, 16);
			letter-spacing: 0.72px;
			.text {
				color: $color-neutral-darker;
				font-family: $font-family-sans-serif;
				margin-bottom: 0;
				margin-left: 0;
				padding-top: 10px;
				text-align: center;
				text-transform: uppercase;
			}
			@include breakpoint(xs) {
				color: #333320;
				font-family: Brandon Grotesque, Medium;
				text-align: center !important;
				font-size: 14px;
			}
			@include breakpoint(md) {
				//font-size: 26px ;
			}
		}

		@include breakpoint(md) {
			align-items: center;
			flex-direction: row;
			padding: 50px 20px 50px 0;

			.content {
				margin-left: 90px;
				margin-top: -6px;
				.text {
					@include text(mu, 16);
					margin-left: 65px;
					padding-top: 8px;
					text-align: unset;
				}
			}
		}
	}
}

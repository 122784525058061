.rotate-svg-90 {
	svg {
		transform: rotate(90deg);
	}
}

.rotate-svg-180 {
	svg {
		transform: rotate(180deg);
	}
}

.logo-wall {
	.logo-wall-content {
		h1 {
			margin-bottom: calc(var(--spacing-base) * 3);
		}
	}
	h2 {
		margin: calc(var(--spacing-base) * 2) 0;
	}

	.logo-wall-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: calc(var(--spacing-base) / 2);
		justify-content: space-around;
		margin-bottom: calc(var(--spacing-base) * 4);
		padding: 0;

		> div {
			align-items: center;
			display: flex;
			height: 100px;
			justify-content: center;
			min-width: 150px;
			width: 18%;

			img {
				height: 100%;
				object-fit: scale-down;
				width: auto;
			}
		}
	}

	@include breakpoint(md) {
		.logo-wall-grid {
			gap: calc(var(--spacing-base) * 3);
			padding: calc(var(--spacing-base) * 2) 0;
		}
	}
}

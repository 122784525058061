.profile-page {
	background-color: $color-white;

	//Shift Clare chatbot head so it doesn't cover mobile CTA menu
	#toggle-widget-button {
		right: 16px;
		bottom: 70px;

		@include breakpoint(lg) {
			right: 16px;
			bottom: 16px;
		}
	}
}

.tag {
	@include text(lambda, 16);
	display: flex;
	justify-content: space-between;
	background-color: $color-neutral-light;
	margin: 0.25rem;
	padding: 6px 10px;
	align-items: center;
	flex-wrap: nowrap;
	&:hover {
		background-color: $color-blue-lightest;
		color: $color-black;
		cursor: pointer;

		.filter-category {
			color: $color-black;
		}

		.icon {
			stroke: $color-black;
		}
	}

	.tag-description {
		transition: all 0.1s ease-in-out;
		display: flex;
		flex-wrap: nowrap;
	}

	.tag-remove-btn {
		margin-left: 0.5rem;
		cursor: pointer;
		.icon {
			height: 10px;
			width: 10px;
		}
	}

	.filter-category {
		@include text(lambda, 20);
		margin-right: 0.375rem;
		transition: all 0.1s ease-in-out;
	}

	.filter-value {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

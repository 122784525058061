.gic-footer {
	background-color: $color-white;
	bottom: 0;
	min-height: $gic-footer-height-xxs;
	padding: 30px 15px 50px;
	width: 100%;

	&.gradient {
		@include gradient-background();
		box-shadow: $footer-inner-shadow;
		color: $color-neutral-lightest-4;

		a {
			color: $color-neutral-lightest-4;
		}
		.copyright-text {
			color: $color-white;
		}
	}

	a {
		color: $color-brown;
		display: block;
		padding-right: 0.25rem;
	}

	.verticle-line {
		margin-right: 0.25rem;
	}

	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.footer-logo {
		margin-bottom: 30px;
		width: 155px;
		height: auto;
	}

	.link-list {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.link-list-item {
		align-items: center;
		display: flex;
		margin-bottom: 0.25rem;

		&:first-child {
			margin-bottom: 1.25rem;
		}
	}

	.site-link,
	.copyright-text {
		@include text(lambda);
		color: $color-brown;
		font-weight: 300;
	}

	.site-link {
		&:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(md) {
		min-height: $gic-footer-height-md;
		padding: 27px 0;

		.footer-container {
			@include make-container();
		}

		.content {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		.footer-logo {
			margin-bottom: 0;
			width: 155px;
			height: auto;
		}

		.link-list {
			align-items: center;
			align-self: center;
			flex-direction: row;
			padding: 11px 0;
		}

		.link-list-item {
			margin-bottom: 0;
			margin-right: 0.75rem;

			&:after {
				content: '|';
				display: inline-block;
				font-style: normal;
				margin-left: 0.75rem;
			}

			&:first-child {
				margin-bottom: 0;
			}

			&:last-child {
				margin-right: 0;

				&:after {
					display: none;
				}
			}
		}
	}
}

$default-icon-color: $color-black;

.icon {
	height: 100%;
	stroke: black;
	stroke-linecap: round;
	stroke-width: 2px;

	use {
		path {
			all: inherit;
		}
	}

	&.icon-chevron-right {
		stroke: $default-icon-color;
	}
	/* stroke icons */
	&.icon-chevron-right,
	&.icon-arrow-right,
	&.icon-arrow-up,
	&.icon-download,
	&.icon-expand,
	&.icon-sort,
	&.icon-check,
	&.icon-close,
	&.icon-return,
	&.icon-arrow-go,
	&.icon-search {
		fill: none;
		stroke: $default-icon-color;
		stroke-width: 1.5;
	}

	&.icon-form-edit {
		fill: none;
		stroke: $default-icon-color;
		stroke-width: 2;
	}
	/* fill icons */
	&.icon-calendar,
	&.icon-calendar-two,
	&.icon-chat,
	&.icon-close-video,
	&.icon-columns,
	&.icon-copy,
	&.icon-critical,
	&.icon-doctor,
	&.icon-download-file,
	&.icon-expand-fs,
	&.icon-external,
	&.icon-facebook,
	&.icon-facebook-hover,
	&.icon-facebook-square,
	&.icon-filters,
	&.icon-information,
	&.icon-info-circle,
	&.icon-instagram-square,
	&.icon-link,
	&.icon-linkedin,
	&.icon-linkedin-hover,
	&.icon-linkedin-square,
	&.icon-location,
	&.icon-location-home,
	&.icon-mail,
	&.icon-mail-hover,
	&.icon-map,
	&.icon-menu,
	&.icon-menu-close,
	&.icon-menu-dark,
	&.icon-nurse,
	&.icon-cellphone,
	&.icon-osf-logo,
	&.icon-osf-logo-long,
	&.icon-phone,
	&.icon-play,
	&.icon-play-filled,
	&.icon-print,
	&.icon-profile,
	&.icon-share,
	&.icon-star,
	&.icon-start-over,
	&.icon-twitter,
	&.icon-map-parking,
	&.icon-twitter-hover,
	&.icon-twitter-square,
	&.icon-urgent-care,
	&.icon-virtual-visit,
	&.icon-warning,
	&.icon-youtube-square {
		fill: $default-icon-color;
		stroke-width: 0;
	}

	&.icon-menu,
	&.icon-menu-dark {
		height: 17px;
		width: 20px;
	}

	&.icon-menu-close {
		height: 40px;
		width: 40px;
	}

	&.icon-osf-logo {
		height: 34.2px;
		width: 100px;
	}

	&.icon-osf-logo-long {
		height: 26.54px;
		width: 188.85px;
	}

	&.icon-share {
		transform: rotate(-90deg);
	}
}

.overhauled {
	.breadcrumb {
		padding: calc(var(--spacing-base) * 2) 0;

		.breadcrumb-inner {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			ul {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					font-size: var(--fs-label);
					margin-bottom: 0;
					padding-right: var(--spacing-base);

					a {
						color: var(--color-body-dark);
						font-weight: var(--fw-regular);
						text-decoration: none;

						&:hover {
							color: var(--color-body-dark);
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

.picture-content-card {
	background-color: $color-white;
	display: block;
	margin-bottom: 2rem;
	&:hover {
		text-decoration: none;
	}

	.card-title {
		@include text(epsilon);
		color: $color-green-dark;
	}

	.card-description,
	.link-text {
		@include text(zeta);
		color: $color-neutral-darker;
	}

	.card-title,
	.card-description,
	.link-text {
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-light;
	}

	.card-title {
		margin-bottom: 1rem;
	}

	.card-description {
		margin-bottom: 2rem;
	}

	.picture-content-card-img {
		img {
			object-fit: cover;
			object-position: top;
			width: 100%;
		}
	}

	.picture-content-card-content {
		padding: 2rem;
		.card-body, .card-footer {
			padding: 0
		}

	}

	.card-footer {
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;
		background-color: transparent;
		border: none;
	}

	.content {
		padding: 2rem;
	}

	.card-footer {
		p {
			margin-bottom: 0;
		}
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: row-reverse;
		height: 340px;
		position: relative;

		&:hover {
			.picture-content-card-content {
				background-color: $color-green-lightest;
				flex-basis: 100%;
				min-width: 100%;
			}

			.picture-content-card-img {
				flex-basis: 0;
				min-width: 0;
			}
		}

		.picture-content-card-content {
			background-color: white;
			display: flex;
			flex-direction: column;
			height: 100%;
			min-height: 340px;
			transition-property: flex-basis, min-width;
			transition-duration: 0.2s;
			transition-timing-function: ease;
			flex-basis: 50%;
			min-width: 50%;
			z-index: 2;
			justify-content: space-between;

			h2 {
				@include text(epsilon);
				margin-bottom: 1rem;
			}

			h2,
			p {
				@include text(zeta);
				color: $color-neutral-darker !important;
				font-family: $font-family-sans-serif;
				font-weight: 300;
				word-wrap: break-word;
			}
		}

		.picture-content-card-img {
			flex-basis: 50%;
			min-width: 50%;
			position: relative;
			min-height: 100%;
			transition-property: flex-basis, min-width;
			transition-duration: 0.2s;
			transition-timing-function: ease;

			img {
				height: 100%;
				object-fit: cover;
				object-position: right top;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}

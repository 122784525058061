.overhauled {
	.shapes-container {
		height: 100%;
		position: absolute;
		width: 100%;

		.shapes1,
		.shapes2,
		.shapes3,
		.shapes4 {
			height: 100%;
			overflow: hidden;
			position: relative;
			width: 100%;

			@include breakpoint(lg) {
				circle,
				path,
				rect {
					stroke-width: 3px;
				}
			}

			rect {
				height: calc(100% - 2px);
				transform: translateX(1px) translateY(1px);
				width: calc(100% - 2px);

				@include breakpoint(lg) {
					height: calc(100% - 3px);
					transform: translateX(1.5px) translateY(1.5px);
					width: calc(100% - 3px);
				}
			}

			svg {
				aspect-ratio: 1 / 1;
				position: absolute;
			}
		}

		.shapes1 {
			svg:nth-child(1) {
				fill: var(--color-primary-light);
				left: -7px;
				top: 88px;
				transform: rotate(68.421deg);
				width: 37px;

				@include breakpoint(sm) {
					top: 103px;
				}
				@include breakpoint(md) {
					top: 83px;
				}
				@include breakpoint(lg) {
					left: -14px;
					top: 87px;
					width: 45px;
				}
				@include breakpoint(xl) {
					left: -30px;
					top: 105px;
					width: 68px;
				}
				@include breakpoint(xxl) {
					left: -16px;
					top: 134px;
				}
			}
			svg:nth-child(2) {
				fill: var(--color-secondary-light);
				left: 28px;
				top: 46px;
				transform: rotate(-158.293deg);
				width: 46px;

				@include breakpoint(sm) {
					top: 70px;
				}
				@include breakpoint(md) {
					top: 50px;
				}
				@include breakpoint(lg) {
					left: 26px;
					top: 44px;
					width: 55px;
				}
				@include breakpoint(xl) {
					top: 36px;
					width: 83px;
				}
				@include breakpoint(xxl) {
					left: 54px;
					top: 66px;
				}
			}
			svg:nth-child(3) {
				bottom: -15px;
				fill: var(--color-quaternary);
				right: 66px;
				width: 30px;

				@include breakpoint(sm) {
					bottom: -10px;
					right: 52px;
				}
				@include breakpoint(md) {
					bottom: -17px;
					right: 73px;
					width: 41px;
				}
				@include breakpoint(lg) {
					right: 100px;
					width: 44px;
				}
				@include breakpoint(xl) {
					bottom: -24px;
					right: 150px;
					width: 70px;
				}
				@include breakpoint(xxl) {
					right: 144px;
				}
			}
			svg:nth-child(4) {
				bottom: 24px;
				fill: var(--color-tertiary);
				right: 30px;
				transform: rotate(97.639deg);
				width: 41px;
				z-index: 1;

				@include breakpoint(sm) {
					bottom: 30px;
					right: 20px;
				}
				@include breakpoint(md) {
					bottom: 40px;
					right: 42px;
					transform: rotate(87.962deg);
					width: 56px;
				}
				@include breakpoint(lg) {
					bottom: 52px;
					right: 62px;
					transform: rotate(88.303deg);
					width: 61px;
				}
				@include breakpoint(xl) {
					bottom: 76px;
					right: 90px;
					transform: rotate(87.152deg);
					width: 95px;
				}
			}
			svg:nth-child(5) {
				bottom: -10px;
				fill: var(--color-primary-light);
				right: -20px;
				width: 72px;

				@include breakpoint(sm) {
					bottom: -4px;
					right: -30px;
				}
				@include breakpoint(md) {
					bottom: 8px;
					right: -26px;
					width: 97px;
				}
				@include breakpoint(lg) {
					bottom: 14px;
					right: -12px;
					width: 106px;
				}
				@include breakpoint(xl) {
					bottom: 17px;
					right: -30px;
					width: 166px;
				}
			}
		}

		.shapes2 {
			svg:nth-child(1) {
				bottom: -60px;
				fill: var(--color-secondary-light);
				right: 48px;
				width: 108px;

				@include breakpoint(md) {
					right: 72px;
					width: 155px;
				}
				@include breakpoint(lg) {
					bottom: -96px;
					right: 99px;
					width: 202px;
				}
				@include breakpoint(xl) {
					bottom: -124px;
					right: 130px;
					width: 287px;
				}
				@include breakpoint(xxl) {
					right: 137px;
				}
			}
			svg:nth-child(2) {
				bottom: 8px;
				fill: var(--color-quaternary);
				right: 18px;
				transform: rotate(104.967deg);
				width: 49px;

				@include breakpoint(md) {
					bottom: 20px;
					right: 23px;
					transform: rotate(109.598deg);
					width: 71px;
				}
				@include breakpoint(lg) {
					bottom: 14px;
					right: 34px;
					width: 93px;
				}
				@include breakpoint(xl) {
					bottom: 26px;
					right: 38px;
					width: 132px;
				}
				@include breakpoint(xxl) {
					right: 46px;
				}
			}
			svg:nth-child(3) {
				bottom: 54px;
				fill: var(--color-tertiary);
				right: -10px;
				width: 45px;

				@include breakpoint(md) {
					bottom: 90px;
					right: -16px;
					width: 65px;
				}
				@include breakpoint(lg) {
					bottom: 101px;
					right: -20px;
					width: 84px;
				}
				@include breakpoint(xl) {
					bottom: 150px;
					right: -30px;
					width: 120px;
				}
			}
			svg:nth-child(4) {
				bottom: 105px;
				fill: var(--color-primary-light);
				right: 16px;
				transform: rotate(63.79deg);
				width: 25px;

				@include breakpoint(md) {
					bottom: 164px;
					right: 23px;
					transform: rotate(68.421deg);
					width: 37px;
				}
				@include breakpoint(lg) {
					bottom: 198px;
					right: 36px;
					width: 48px;
				}
				@include breakpoint(xl) {
					bottom: 287px;
					right: 40px;
					width: 68px;
				}
			}
		}

		.shapes3 {
			svg:nth-child(1) {
				fill: var(--color-quaternary);
				right: 31px;
				top: 52px;
				width: 20px;
				z-index: 1;

				@include breakpoint(sm) {
					right: 42px;
					top: 40px;
					width: 27px;
				}
				@include breakpoint(md) {
					right: 48px;
					width: 33px;
				}
				@include breakpoint(lg) {
					right: 64px;
					top: 74px;
					width: 43px;
				}
				@include breakpoint(xl) {
					right: 88px;
					top: 69px;
					width: 50px;
				}
				@include breakpoint(xxl) {
					right: 90px;
					top: 95px;
				}
			}
			svg:nth-child(2) {
				fill: var(--color-tertiary);
				right: -2px;
				top: 52px;
				transform: rotate(30.04deg);
				width: 49px;

				@include breakpoint(sm) {
					top: 40px;
					width: 66px;
				}
				@include breakpoint(md) {
					right: -8px;
					width: 80px;
				}
				@include breakpoint(lg) {
					right: -12px;
					top: 66px;
					width: 104px;
				}
				@include breakpoint(xl) {
					right: 4px;
					top: 68px;
					width: 120px;
				}
				@include breakpoint(xxl) {
					right: 4px;
					top: 94px;
				}
			}

			svg:nth-child(3) {
				fill: var(--color-primary-light);
				right: 16px;
				top: 116px;
				transform: rotate(-178.323deg);
				width: 34px;

				@include breakpoint(sm) {
					right: 21px;
					top: 124px;
					width: 46px;
				}
				@include breakpoint(md) {
					top: 142px;
					width: 55px;
				}
				@include breakpoint(lg) {
					right: 32px;
					top: 200px;
					width: 72px;
				}
				@include breakpoint(xl) {
					right: 50px;
					top: 228px;
					width: 83px;
				}
				@include breakpoint(xxl) {
					right: 52px;
					top: 244px;
				}
			}

			svg:nth-child(4) {
				fill: var(--color-secondary-light);
				right: -20px;
				top: 142px;
				transform: rotate(-178.323deg);
				width: 42px;

				@include breakpoint(sm) {
					right: -26px;
					top: 162px;
					width: 56px;
				}
				@include breakpoint(md) {
					right: -32px;
					top: 184px;
					width: 68px;
				}
				@include breakpoint(lg) {
					right: -40px;
					top: 255px;
					width: 89px;
				}
				@include breakpoint(xl) {
					right: -40px;
					top: 287px;
					width: 103px;
				}
				@include breakpoint(xxl) {
					top: 303px;
				}
			}
		}

		.shapes4 {
			svg:nth-child(1) {
				bottom: 18px;
				fill: var(--color-primary-light);
				left: -6px;
				transform: rotate(76.847deg);
				width: 24px;
				z-index: 1;

				@include breakpoint(sm) {
					bottom: 11px;
					width: 30px;
				}
				@include breakpoint(md) {
					bottom: 30px;
					width: 36px;
				}
				@include breakpoint(lg) {
					bottom: 35px;
					left: -16px;
					width: 49px;
				}
			}
			svg:nth-child(2) {
				bottom: -12px;
				fill: var(--color-secondary-light);
				left: 6px;
				width: 48px;

				@include breakpoint(sm) {
					bottom: -24px;
					width: 58px;
				}
				@include breakpoint(md) {
					bottom: -18px;
					width: 70px;
				}
				@include breakpoint(lg) {
					bottom: -22px;
					width: 95px;
				}
				@include breakpoint(xxl) {
					left: 10px;
				}
			}
			svg:nth-child(3) {
				bottom: -40px;
				fill: var(--color-tertiary);
				left: 84px;
				width: 87px;

				@include breakpoint(sm) {
					bottom: -64px;
					left: 105px;
					width: 105px;
				}
				@include breakpoint(md) {
					bottom: -64px;
					left: 127px;
					width: 127px;
				}
				@include breakpoint(lg) {
					bottom: -84px;
					left: 162px;
					width: 172px;
				}
			}
			svg:nth-child(4) {
				fill: var(--color-quaternary);
				right: -20px;
				top: 31px;
				transform: rotate(108.01deg);
				width: 76px;

				@include breakpoint(sm) {
					right: -30px;
					width: 107px;
				}
				@include breakpoint(md) {
					right: -30px;
					top: 34px;
					width: 127px;
				}
				@include breakpoint(lg) {
					right: -56px;
					top: 48px;
					width: 152px;
				}
				@include breakpoint(xxl) {
					top: 94px;
				}
			}
			svg:nth-child(5) {
				fill: var(--color-primary-light);
				top: 85px;
				transform: rotate(37.392deg);
				right: 16px;
				width: 25px;

				@include breakpoint(sm) {
					width: 36px;
					top: 104px;
					right: 22px;
				}
				@include breakpoint(md) {
					right: 26px;
					top: 124px;
					width: 43px;
				}
				@include breakpoint(lg) {
					right: 20px;
					top: 162px;
					width: 51px;
				}
				@include breakpoint(xxl) {
					top: 208px;
				}
			}
		}
	}
}

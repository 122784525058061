.overhauled {
	.view-more {
		align-items: center;
		display: flex;
		gap: calc(var(--spacing-base) * 3);
		padding-top: calc(var(--spacing-base) * 6);
		width: 100%;
		.divider {
			border-top: 3px solid var(--color-shadows);
			flex-grow: 1;
			height: 0;
		}
	}
}

.overhauled {
	.modal {
		background-color: var(--color-overlay-opacity);
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		outline: 0;
		transition: opacity 0.2s ease-in-out;
		z-index: 10;

		&.visible {
			display: block;
			z-index: 999999;
		}

		&.open {
			opacity: 1;
		}

		.modal-inner-container {
			background-color: var(--color-base);
			border-radius: var(--br-md);
			box-shadow: var(--btn-shadow);
			overflow: hidden;
			position: relative;
			width: 100%;
		}

		.modal-inner {
			min-height: 300px;
			padding-bottom: calc(var(--spacing-base) * 5);
			padding-top: calc(var(--spacing-base) * 5);
			width: 100%;
		}

		.modal-close-btn {
			position: absolute;
			top: 5%;
			right: calc(var(--spacing-base) * 3);
			z-index: 1000;
			cursor: pointer;

			&:hover,
			&:focus {
				.icon {
					stroke: var(--color-shadows);
				}
			}
		}

		.modal-container {
			display: flex;
			background-color: transparent;
			height: 100%;
			justify-content: center;
			max-height: 100vh;
		}

		.modal-body {
			display: flex;
			height: 100%;
			overflow-y: auto;
		}

		.modal-content {
			display: flex;
			flex-direction: column;
			gap: calc(var(--spacing-base) * 3);

			h2 {
				color: var(--color-primary-dark);
			}

			@include breakpoint(md) {
				gap: calc(var(--spacing-base) * 4);
			}

			@include breakpoint(xl) {
				gap: unset;

				h2 {
					margin-bottom: calc(var(--spacing-base) * 3);
				}

				h3 {
					margin-bottom: calc(var(--spacing-base) * 2);
				}

				.body {
					margin-bottom: calc(var(--spacing-base) * 2);
				}
			}
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&.video-modal {
			.modal-inner {
				@include breakpoint(lg) {
					aspect-ratio: 3 / 1;
				}
			}
		}
	}
}

[class^='icon-'],
[class*=' icon-'] {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-twitter-x {
	align-items: center;
	display: flex;
	justify-content: center;

	&:before {
		background-size: contain;
		content: '';
		display: block;
		height: 85%;
		width: 85%;
		background-image: url(../../../assets/images/twitter-x.svg);
	}
}

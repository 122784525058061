.form-label {
	@include text(kappa);
	color: $color-neutral-darker;
	display: block;
	margin-bottom: 0.5rem;
}

.form-input-wrapper {
	.form-text-field {
		background: $color-neutral-lightest-2;
		border-radius: 30px;
		display: block;
		height: 40px;
		padding: 10px 15px 5px 20px;
		position: relative;

		&:hover {
			background: $color-blue-lightest-2;
		}

		&.form-text-area {
			height: 200px;
			padding-top: 0;
			padding-bottom: 0;

			textarea {
				height: 100%;
				resize: none;
				margin-top: 0;
				width: 100% !important;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;

				&::placeholder {
					border-left: 1px solid $color-green-lighter;
					padding-left: 0.5rem;
				}
			}
		}

		input,
		textarea,
		select {
			background: transparent;
			border: none;
			border-radius: unset;
			-webkit-box-flex: 1;
			flex-grow: 1;
			-ms-flex-positive: 1;
			font-size: 14px;
			margin-top: 7px;
			padding: 0;

			&:valid,
			&:focus {
				border: none;
				box-shadow: none;
				outline: 0;
				& ~ .text-label {
					font-size: 10px;
					top: 2px;
				}
			}

			&.disabled {
				background: $color-neutral-lightest-2;
			}
		}

		.text-label {
			color: $color-neutral-dark;
			cursor: text;
			display: block;
			font-family: $font-family-sans-serif;
			font-size: 14px;
			left: 20px;
			pointer-events: none;
			position: absolute;
			top: 10px;
			transition: 0.2s ease all;
			-moz-transition: 0.2s ease all;
			-webkit-transition: 0.2s ease all;
		}
	}

	span {
		&:hover {
			background: transparent;
		}
	}

	&.success {
		.form-text-field {
			border: 1px solid $color-green-darker-3;
		}

		span {
			@include text(lambda);
			color: $color-green-darker-3;
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
			padding-left: 22px;
		}
	}

	&.warning {
		.form-text-field {
			border: 1px solid $color-warning-2;
		}

		span {
			@include text(lambda);
			color: $color-warning-2;
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
			padding-left: 22px;
		}
	}

	&.critical {
		.form-text-field {
			border: 1px solid $color-red;
		}

		span {
			@include text(lambda);
			color: $color-red;
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
			padding-left: 22px;
		}
	}
}

.spotlight {
	//All variants
	.content-wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;

		&.align-center {
			text-align: center;
		}

		&.align-left {
			align-items: flex-start;
			text-align: left;
		}

		&.align-right {
			align-items: flex-end;
			text-align: right;
		}
	}

	&:has(.overlay-base, .overlay-dark, .overlay-secondary-dark) {
		a:not(.btn-primary, .btn-secondary, .btn-tertiary, .osf-green-cta) {
			color: var(--color-base);

			&:hover {
				color: var(--color-base);
			}
			&:active,
			&:focus,
			&:focus-visible {
				color: var(--color-bg-secondary);
			}
		}
	}

	//Narrow Variant
	&.narrow {
		.headline {
			color: var(--color-primary-dark);
		}
		.content-wrapper {
			gap: calc(var(--spacing-base) * 3);

			p {
				margin-bottom: 0;
			}
		}

		//Gradient
		&.gradient {
			.content-wrapper {
				align-items: flex-start;

				@include breakpoint(lg) {
					align-items: center;
					flex-direction: row;
				}
				@include breakpoint(xl) {
					gap: calc(var(--spacing-base) * 8);
				}
				@include breakpoint(xxl) {
					gap: calc(var(--spacing-base) * 15);
				}

				.text-wrapper {
					color: var(--color-base);
					display: flex;
					flex-direction: column;
					gap: calc(var(--spacing-base) * 3);

					@include breakpoint(lg) {
						flex: 0 0 59%;
					}
					@include breakpoint(xl) {
						flex: 0 0 66%;
					}
					@include breakpoint(xxl) {
						flex: 0 0 58%;
					}
				}

				.headline,
				.description {
					color: var(--color-base);
				}

				a {
					@include breakpoint(lg) {
						flex-grow: 1;
					}

					&:hover {
						box-shadow: var(--btn-shadow-light);
					}
				}
			}
		}

		//Image BG
		&.image-bg {
			background-position: center;
			background-size: cover;
			position: relative;
			z-index: 0;

			h2,
			.description {
				color: var(--color-base);
			}
		}
	}

	//Tall Variant
	&.tall {
		background-position: center;
		background-size: cover;
		padding: calc(var(--spacing-base) * 6) 0;
		position: relative;
		z-index: 0;

		@include breakpoint(sm) {
			padding: calc(var(--spacing-base) * 8) 0;
		}
		@include breakpoint(lg) {
			padding: calc(var(--spacing-base) * 13) 0;
		}
		@include breakpoint(xl) {
			padding: calc(var(--spacing-base) * 17) 0;
		}

		.content-wrapper {
			gap: calc(var(--spacing-base) * 4);

			h2,
			.description {
				color: var(--color-base);
			}

			.description {
				margin-bottom: 0;
			}
		}
	}

	//Location Variant
	&.locations {
		.bg-secondary {
			border-radius: var(--br-md);
			margin: 0 (calc(var(--spacing-base) * -2));
			overflow: hidden;

			@include breakpoint(md) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.content-wrapper {
			@include breakpoint(md) {
				align-items: flex-start;
				flex-direction: row;
			}

			.img-container {
				width: 100%;

				@include breakpoint(sm) {
					height: 66vmax;
				}

				@include breakpoint(md) {
					align-self: stretch;
					flex: 1 1 47%;
					height: auto;
				}

				picture {
					display: block;
					height: 100%;
					width: 100%;

					img {
						height: 100%;
						object-fit: cover;
						width: 100%;
					}
				}
			}

			.inner-content {
				display: flex;
				flex-direction: column;
				height: 100%;
				padding-bottom: calc(var(--spacing-base) * 3);
				padding-top: calc(var(--spacing-base) * 6);
				width: 100%;

				@include breakpoint(sm) {
					padding-bottom: calc(var(--spacing-base) * 4);
					padding-top: calc(var(--spacing-base) * 3);
				}

				@include breakpoint(md) {
					align-self: center;
					flex: 1 1 53%;
					padding-bottom: calc(var(--spacing-base) * 6);
					padding-top: calc(var(--spacing-base) * 6);
				}

				.headline {
					color: var(--color-primary-dark);
				}
				p {
					margin: calc(var(--spacing-base) * 2) 0 0 0;
				}
				a {
					margin-top: calc(var(--spacing-base) * 4);
				}
			}
		}
	}
}

.banner {
	margin-bottom: 40px;

	img {
		max-height: 250px;
		object-fit: cover;
		width: 100%;
		margin-bottom: 40px;
	}
}
.event-details {
	padding-bottom: 70px;

	.content {
		.event-title {
			@include text(delta, delta);
			color: $color-green-darker;
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-light;
			margin-bottom: 30px;
			@include breakpoint(md) {
				@include text(beta, 54);
			}
		}

		.event-description {
			p {
				@include text(kappa, iota);
				font-family: $font-family-serif;
				font-weight: $font-weight-regular;
				color: $color-neutral-darker;
				line-height: 1.6;
			}
		}

		.event-info {
			h6 {
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-bold;
				margin: 0;
				@include text(kappa, iota);
			}

			p {
				@include text(kappa, iota);
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;
				margin: 0;
			}

			a {
				@include text(kappa, iota);
				color: $color-green-darker;
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;
				margin: 0;
			}

			> div {
				margin-bottom: 20px;

				&:last-of-type {
					margin-bottom: unset;
				}
			}
		}

		.event-btns {
			bottom: 0;
			display: flex;
			left: 0;
			position: fixed;
			right: 0;

			.action-btn {
				flex-grow: 1;
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-regular;
				margin-left: auto;
				max-width: unset;
				min-height: 120px;

				&:hover {
					background-color: $color-blue-lightest;
				}

				.action-text {
					@include text(theta);
				}

				p {
					@include text(kappa);
				}

				svg.rotate90 {
					transform: rotate(90deg);
				}

				&:last-of-type {
					background-color: $color-green-lighter;

					&:hover {
						background-color: $color-blue-lightest;
					}

					// svg {
					// 	transform: ;
					// }
				}
			}
		}

		.row {
			flex-direction: column-reverse;
		}

		@include breakpoint(md) {
			.row {
				flex-direction: unset;
			}

			.event-btns {
				display: unset;
				position: unset;
				.action-btn {
					max-width: 165px;
					min-height: 165px;
				}
			}
		}
	}
}

.blog-details {
	@include breakpoint(sm) {
		margin: 0rem;
	}
	@include breakpoint(md) {
		margin-top: 2rem;
	}
	img:not(.hero-img, .related-image) {
		margin: calc(var(--spacing-base) * 3.5) auto;
		@include breakpoint(md) {
			margin: calc(var(--spacing-base) * 4.5) auto;
		}
		@include breakpoint(lg) {
			margin: calc(var(--spacing-base) * 6) auto;
		}
	}
	.hero-img {
		margin-bottom: calc(var(--spacing-base) * 3.75);
		@include breakpoint(md) {
			max-width: $inner-container-width;
			margin: 0 auto;
			margin-bottom: calc(var(--spacing-base) * 5);
		}
	}
	.content-paragraph {
		margin-bottom: 1rem;
		@include breakpoint(md) {
			margin-bottom: 2rem;
		}
		@include breakpoint(lg) {
			margin-bottom: 3rem;
		}
	}
	h3 {
		@include heading-3-regular();
		margin: 1.25rem 0;
	}
	h4 {
		margin-bottom: calc(var(--spacing-base) * 2.5);
	}
	.divider {
		background: var(--Line-Tan, #e3dbd1);
		height: 3px;
	}
}

.content-wrapper {
	@include jss-inner-container();
	margin: 0;

	@include breakpoint(sm) {
		margin: 0 calc(var(--spacing-base) * 3);
	}
	@include breakpoint(md) {
		margin: 0 auto;
	}

	.share-bar {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc(var(--spacing-base) * 2);
		padding: 0 calc(var(--spacing-base) * 4);
		margin: calc(var(--spacing-base) * 3) 0;

		@include breakpoint(md) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 0;
		}

		h4.share-bar-title {
			margin-top: 0;
			margin-bottom: 0;
		}

		.social-icons {
			display: flex;
			gap: calc(var(--spacing-base) * 1.5);
		}

		.social-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: var(--color-primary);
			transition: transform 0.2s ease;

			svg {
				width: 42px;
				height: 42px;
				fill: var(--color-base);
			}

			&:hover {
				svg > :first-child:is(circle, path),
				svg > :not(:first-child:is(circle)) ~ path {
					fill: var(--color-secondary-light);
				}
			}
		}
	}
}

.overhauled {
	.featured-list {
		.title {
			color: var(--color-primary-dark);
			margin-bottom: calc(var(--spacing-base) * 3);
		}
	}
	.featured-list.locations {
		.list-inner {
			display: flex;
			flex-direction: column;
			gap: calc(var(--spacing-base) * 3);

			@include breakpoint(md) {
				display: grid;
				gap: calc(var(--spacing-base) * 3);
				grid-template-columns: repeat(auto-fit, calc(50% - 12px));
			}

			.featured-item {
				border-radius: var(--br-md);
				display: block;
				overflow: hidden;
				text-decoration: none;

				@include breakpoint(lg) {
					display: flex;
				}
			}
			.item-image {
				aspect-ratio: 16/9;
				height: inherit;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.content-container {
				padding: calc(var(--spacing-base) * 3);
				width: 100%;

				.address {
					display: flex;
					flex-direction: column;
					gap: calc(var(--spacing-base) * 3);

					.address-details {
						display: flex;
						gap: calc(var(--spacing-base) * 2);

						svg {
							height: 20px;
							margin-top: 2px;
							width: 15px;
						}
					}

					p {
						margin-bottom: 0;
					}
				}
			}
			&:not(.vertical) {
				@include breakpoint(lg) {
					grid-template-columns: 71%;

					.item-image {
						flex: 0 0 45%;
						max-width: 45%;
					}

					.content-container {
						flex-grow: 1;
						padding: calc(var(--spacing-base) * 6) calc(var(--spacing-base) * 3);

						.address {
							gap: calc(var(--spacing-base) * 4);
						}
					}
					& ~ .view-more {
						width: 71%;
					}
				}
				@include breakpoint(xl) {
					grid-template-columns: repeat(auto-fit, calc(50% - 12px));

					.item-image {
						width: 67%;
					}

					.content-container {
						.address {
							gap: calc(var(--spacing-base) * 6);
						}
					}
					& ~ .view-more {
						width: 100%;
					}
				}
			}
			&.vertical {
				@include breakpoint(lg) {
					width: 83%;
					.featured-item {
						display: block;
					}
					& ~ .view-more {
						width: 83%;
					}
				}
				@include breakpoint(xl) {
					grid-template-columns: repeat(auto-fit, calc(25% - 18px));
					width: 100%;

					.featured-item {
						display: flex;
						flex-direction: column;

						.item-image {
							min-height: 0;
						}

						.content-container {
							display: flex;
							flex-grow: 1;

							.address {
								gap: calc(var(--spacing-base) * 4);
								justify-content: space-between;

								svg {
									flex: 0 0 15px;
								}

								p {
									flex: 1 1 auto;
								}
							}
						}
					}
					& ~ .view-more {
						width: 100%;
					}
				}
			}
		}
	}
}

.featured-list.providers {
	.item-image {
		flex: 0 0 37%;
		height: auto;

		@include breakpoint(sm) {
			flex: 0 0 33%;
		}
		@include breakpoint(md) {
			flex: 0 0 38%;
		}
		@include breakpoint(lg) {
			flex: 0 0 28%;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

.featured-list.providers {
	.flex {
		display: flex;
	}

	.list-inner {
		@include breakpoint(md) {
			columns: 2;
		}
		@include breakpoint(xl) {
			columns: 3;
		}
	}

	.featured-item {
		border-radius: var(--br-md);
		display: flex;
		margin-bottom: var(--spacing-base);
		min-height: 104px;
		overflow: hidden;
		text-decoration: none;

		@include breakpoint(sm) {
			min-height: 125px;
		}

		&:hover,
		&:focus {
			cursor: pointer;
			text-decoration: none;
		}
	}

	.content-container {
		justify-content: center;
		display: flex;
		flex-direction: column;
		gap: calc(var(--spacing-base) / 2);
		padding-bottom: calc(var(--spacing-base) * 2);
		padding-top: calc(var(--spacing-base) * 2);

		@include breakpoint(lg) {
			padding-bottom: calc(var(--spacing-base) * 3);
			padding-top: calc(var(--spacing-base) * 3);
		}
	}

	.specialties {
		display: inline-block;
		.item-subtitle {
			display: inline;
			margin: 0;
		}
	}

	@include breakpoint(md) {
		.list-inner {
			column-gap: calc(var(--spacing-base) * 3);
		}
	}

	@include breakpoint(lg) {
		.featured-item {
			margin-bottom: calc(var(--spacing-base) * 2);
		}
	}
}

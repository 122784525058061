.image-call-out {
	display: flex;
	flex-wrap: wrap;
	gap: calc(var(--spacing-base) * 4);
	margin: calc(var(--spacing-base) * 5) 0;
	.content {
		height: 100%;
		width: 150px;

		img {
			border-radius: 50%;
			height: 150px;
			object-fit: cover;
			width: 100%;
		}

		p {
			margin-bottom: 0;
			margin-top: calc(var(--spacing-base) * 2);
			text-align: center;
		}

		@include breakpoint(md) {
			width: 240px;

			img {
				height: 240px;
			}
		}
	}
}

.r2-navigation {
	background-color: $color-white;
	height: 70px;
	position: relative;

	@include breakpoint(md) {
		height: 215px;
	}

	@media screen and (min-width: 865px) {
		height: 165px;
	}

	@include breakpoint(lg) {
		height: 125px;
	}

	.bg-blue-light:hover {
		background-color: $color-blue-lightest-2;
	}

	.bg-blue-lightest:hover {
		background-color: $color-blue-lightest-2;
	}

	.bg-green-light:hover {
		background-color: $color-green-mid-light;
	}

	.bg-green-lightest:hover {
		background-color: $color-green-mid-light;
	}

	.bg-neutral-lighter:hover {
		background-color: $color-neutral-lightest-4;
	}

	.bg-neutral-lightest:hover {
		background-color: $color-neutral-lightest-4;
	}

	.nav-outer-container {
		z-index: 999999;
	}
	.navbar {
		align-items: center;
		background-color: $color-white;
		box-shadow: 0px 3px 6px rgba($color-black, 0.29);
		display: flex;
		height: 70px;
		z-index: 2;
		justify-content: space-between;
		padding: 0 15px;
		position: fixed;
		top: 0;
		width: 100%;

		.icon-osf-logo {
			fill: $color-brown;
			height: 40px;
			width: 108px;
		}
		.nav-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}

	.menu-open {
		align-items: center;
		display: flex;

		&:hover {
			cursor: pointer;
		}
	}

	.nav-menu {
		background-color: $color-white;
		display: none;
		opacity: 0;
		overflow: auto;
		padding: 15px;
		pointer-events: none;

		&.mobile-nav-open {
			animation: fade-in 0.4s;
			display: block;
			height: 100vh;
			left: 0;
			opacity: 1;
			z-index: 100000001;
			pointer-events: auto;
			position: fixed;
			top: 0;
			width: 100%;

			@keyframes fade-in {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
		}

		&.closing {
			animation: fade-out 0.4s;
			opacity: 0;

			@keyframes fade-out {
				from {
					opacity: 1;
				}
				to {
					opacity: 0;
				}
			}
		}
	}
	.nav-search {
		visibility: hidden; // done for #7570
	}
	.nav-content {
		.osf-logo {
			display: none;
		}
	}

	.nav-header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;

		.icon-osf-logo {
			fill: $color-brown;
			height: 40px;
			width: 108px;
			img {
				height: 40px;
				width: 108px;
			}
		}
	}

	.menu-close {
		padding-top: 8px;
		svg {
			width: 18.264px;
			height: 18.264px;
		}
		&:hover {
			cursor: pointer;
		}
	}

	.input-icon-container {
		margin-bottom: 1rem;
		position: relative;

		input[type='text'] {
			@include text(kappa, 19);
			border: 1.5px solid $color-neutral;
			padding: 9px 42px 9px 22px;
		}

		.icon {
			position: absolute;
			right: 18px;
			stroke: $color-green;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.search-btn {
		@include text(kappa, 19);
		border: 1.5px solid $color-neutral;
		margin-bottom: 1rem;
		padding: 9px 42px 9px 22px;
		position: relative;

		.icon {
			position: absolute;
			right: 22px;
			stroke: $color-green;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover,
		&:focus {
			background-color: $color-white;
		}
	}

	.main-nav {
		margin-bottom: 1rem;
	}

	.nav-action {
		margin-bottom: 2px;

		&.has-sub-nav {
			&.active {
				.sub-nav-menu {
					animation: slide-in 0.4s;
					display: block;
					transform: translateX(0);

					@keyframes slide-in {
						from {
							transform: translateX(100%);
						}
						to {
							transform: translateX(0);
						}
					}
				}
			}
			&.closing {
				.sub-nav-menu {
					animation: slide-out 0.4s;
					transform: translateX(100%);

					@keyframes slide-out {
						from {
							transform: translateX(0);
						}
						to {
							transform: translateX(100%);
						}
					}
				}
			}
		}
	}

	.nav-action-btn {
		@include text(lambda, 14);
		align-items: center;
		color: $color-light-black;
		display: flex;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-bold;
		height: 45px;
		justify-content: space-between;
		padding: 0 15px;
		text-align: left;
		width: 100%;

		&.get-care-btn {
			.icon {
				stroke-width: 3px;
			}
		}

		&:hover {
			cursor: pointer;
		}

		.icon {
			margin-left: 0.25rem;
			width: 11px;
		}

		.icon-arrow-go {
			height: 1.2rem;
		}

		.icon-chevron-right {
			stroke: $color-neutral-dark;
			stroke-width: 2px;
		}
	}

	.sub-nav-menu {
		background-color: $color-neutral-lightest;
		display: none;
		height: 100%;
		left: 0;
		overflow: auto;
		padding: 0 20px;
		position: fixed;
		top: 0;
		z-index: 2;
		width: 100%;

		.featured-item {
			background-color: $color-white;

			&:hover,
			&:focus {
				background-color: $color-blue-lightest;
			}
		}
	}

	.sub-nav-mobile-header {
		align-items: center;
		display: flex;
		height: 70px;
		justify-content: space-between;

		.mobile-nav-back-btn {
			align-items: center;
			cursor: pointer;
			display: flex;
			justify-content: flex-start;

			.icon {
				height: 16px;
				transform: rotate(180deg);
				width: 8px;
			}
		}

		.mobile-nav-close-btn {
			align-items: center;
			cursor: pointer;
			display: flex;
			justify-content: center;

			.icon {
				height: 16px;
				width: 16px;
			}
		}
	}

	.sub-nav-title {
		@include text(lambda, 20);
		color: $color-light-black;
		font-weight: $font-weight-bold;
		margin: 0;
	}

	.sub-nav-section {
		&:not(:first-of-type) {
			margin-top: 20px;
		}
	}

	.sub-nav-heading {
		@include text(iota, 20);
		color: $color-light-black;
		font-weight: $font-weight-medium;
		margin: 0 0 15px;
	}

	.featured-list {
		margin-bottom: 0;
		padding: 0;

		.featured-item {
			flex: 0 1 100%;
			max-width: 100%;
			justify-content: flex-start;
			align-items: start;
		}
	}

	.sub-nav-link-list {
		li {
			margin-bottom: 0;
		}
		.sub-nav-link {
			@include text(kappa, 20);
			color: $color-light-black;
			display: inline-block;
			margin-bottom: 15px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.sub-nav-btn-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			flex: 0 1 calc(50% - 10px);
		}

		.sub-nav-link-btn {
			@include text(kappa, 20);
			align-items: center;
			background-color: $color-white;
			color: $color-light-black;
			display: flex;
			justify-content: center;
			margin-bottom: 15px;
			padding: 8px;
			text-align: center;
			width: 100%;

			&:hover,
			&:focus {
				background-color: $color-blue-lightest;
			}
		}
	}

	.view-all-link {
		@include text(kappa, 20);
		color: $color-light-black;
		margin-top: 1rem;

		&:hover {
			text-decoration: underline;
		}
	}

	.utility-links {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		padding: 0 1rem;
		position: relative;
	}

	.utility-link {
		@include text(lambda, 14);
		align-items: center;
		color: $color-neutral-dark;
		display: inline-flex;

		&:hover,
		&:focus {
			color: $color-green-dark;
			text-decoration: underline;
		}

		.icon {
			fill: $color-green;
			margin-right: 0.25rem;
		}
	}

	.zip-btn {
		min-width: 46px;

		&:hover {
			cursor: pointer;
		}

		&.geolocating {
			justify-content: flex-start;
			text-decoration: none;

			&:after {
				-webkit-animation: ellipsis steps(4, end) 900ms infinite;
				animation: ellipsis steps(4, end) 900ms infinite;
				content: '\2026'; /* ascii code for the ellipsis character */
				display: inline-block;
				overflow: hidden;
				vertical-align: bottom;
				width: 0px;
			}

			@keyframes ellipsis {
				to {
					width: 12px;
				}
			}
		}
	}

	@include breakpoint(md) {
		.nav-outer-container {
			align-items: center;
			background-color: $color-white;
			box-shadow: 0px 3px 6px rgba($color-black, 0.29);
			display: flex;
			position: fixed;
			top: 0;
			width: 100%;
		}

		.nav-container {
			background-color: $color-white;
		}

		.navbar {
			display: none;
		}

		.nav-menu {
			align-items: center;
			display: flex;
			opacity: 1;
			overflow: visible;
			pointer-events: auto;
		}

		.nav-header {
			display: none;
		}

		.nav-content {
			align-items: flex-end;
			display: flex;
			flex: 1;
			flex-wrap: wrap;

			.osf-logo {
				display: block;
				margin-bottom: 1rem;

				&:focus:not(.focus-visible),
				&:focus:not(&:focus-visible),
				&:focus-visible {
					outline: none;
				}

				.icon-osf-logo {
					fill: $color-brown;
					height: 50px;
					width: 147px;

					img {
						&:focus:not(.focus-visible),
						&:focus-visible {
							outline: none;
						}
					}
				}
			}
		}

		.menu-close {
			display: none;
		}

		.nav-search {
			margin-left: auto;
			position: relative;
		}

		.input-icon-container {
			margin-bottom: 1.25rem;

			input[type='text'] {
				padding: 5px 42px 5px 22px;
				width: 275px;
			}
		}

		.search-btn {
			margin-bottom: 1.25rem;
			padding: 5px 42px 5px 22px;
			width: 275px;
		}

		.main-nav {
			display: flex;
			margin-bottom: 0;
			order: 1;
			position: relative;
			width: 100%;
		}

		.nav-action {
			flex: 1;
			margin-bottom: 0;
			margin-right: 4px;

			&:last-of-type {
				margin-right: 0;
			}

			&.has-sub-nav {
				&:hover,
				&:focus-within {
					.nav-action-btn {
						position: relative;

						.icon {
							transform: rotate(-90deg);
							display: flex;
							align-items: center;
							justify-content: center;
						}

						&:after {
							content: '';
							cursor: default;
							display: block;
							height: 4px;
							left: 0;
							pointer-events: auto;
							position: absolute;
							top: 100%;
							width: 100%;
							z-index: 1;
						}
						&.bg-blue-light,
						&.bg-blue-light:after {
							background-color: $color-blue-lightest-2;
						}
						&.bg-blue-lightest,
						&.bg-blue-lightest:after {
							background-color: $color-blue-lightest-2;
						}
						&.bg-green-light,
						&.bg-green-light:after {
							background-color: $color-green-mid-light;
						}
						&.bg-green-lightest,
						&.bg-green-lightest:after {
							background-color: $color-green-mid-light;
						}
						&.bg-neutral-lighter,
						&.bg-neutral-lighter:after {
							background-color: $color-neutral-lightest-4;
						}
						&.bg-neutral-lightest,
						&.bg-neutral-lightest:after {
							background-color: $color-neutral-lightest-4;
						}
					}

					.sub-nav-menu {
						animation: none;
						display: block;
						transform: none;
						z-index: unset;

						&.closed {
							display: none;
						}
					}
				}

				.nav-action-btn {
					pointer-events: none;
				}
			}
		}

		.nav-action-btn {
			height: 43px;

			&.get-care-btn {
				height: 43px;
			}

			.icon-chevron-right {
				transform: rotate(90deg);
			}
		}

		.sub-nav-menu {
			background-color: $color-neutral-lightest;
			border: 2px solid $color-white;
			height: auto;
			max-height: calc(100vh - 250px);
			left: auto;
			padding: 20px 40px;
			position: absolute;
			right: -2px;
			top: calc(100% + 2px);
			transform: none;
			width: auto;
			z-index: unset;
		}

		.sub-nav-mobile-header {
			display: none;
		}

		.sub-nav-title {
			text-transform: uppercase;
		}

		.sub-nav-content {
			display: flex;
			justify-content: center;
		}

		.sub-nav-section {
			&:not(:first-of-type) {
				border-left: 1px solid $color-white;
				margin-left: 40px;
				margin-top: 0;
				padding-left: 40px;
			}
		}

		.featured-list {
			max-width: 330px;
		}

		.sub-nav-link-list {
			max-width: 200px;
			min-width: 125px;
		}

		.sub-nav-btn-list {
			flex-direction: column;
			flex-wrap: unset;
			max-width: 200px;
			min-width: 200px;

			li {
				flex: 0 1 100%;
			}
		}

		.utility-links {
			align-items: center;
			height: 32px;
			justify-content: flex-end;
			margin-bottom: 1.25rem;
			margin-left: 35px;
			padding: 0;
		}

		.utility-link {
			margin-left: 1rem;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	@include breakpoint(lg) {
		.sub-nav-menu {
			max-height: calc(100vh - 125px);
		}

		.nav-outer-container {
			height: 125px;
		}

		.nav-container {
			padding-right: 15px;
			padding-left: 15px;
		}

		.nav-menu {
			padding: 0;
		}

		.nav-header {
			display: block;
			margin-bottom: 0;
			margin-right: 57px;

			.icon-osf-logo img {
				height: 76px;
				width: 205px;
			}
		}

		.input-icon-container {
			input[type='text'] {
				width: 315px;
			}
		}

		.search-btn {
			width: 315px;
		}

		.nav-content {
			align-items: center;

			.osf-logo {
				display: none;
			}
		}

		.nav-action-btn {
			height: 39px;

			&.get-care-btn {
				height: 39px;
			}
		}

		.utility-links {
			margin-left: 57px;
		}

		.utility-link {
			margin-left: 25px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	@media screen and (min-width: 1121px) {
		.nav-container {
			margin-right: auto;
			margin-left: auto;
			max-width: $max-container-width;
			padding-right: $lg-grid-outer-padding;
			padding-left: $lg-grid-outer-padding;
			width: 100%;
		}
	}

	@include breakpoint(xl) {
		.nav-header {
			display: block;
			margin-bottom: 0;
			margin-right: 57px;

			.icon-osf-logo {
				img {
					height: 76px;
					width: 205px;
				}
			}
		}

		.nav-container {
			padding-right: $xl-grid-outer-padding;
			padding-left: $xl-grid-outer-padding;
		}
	}
}

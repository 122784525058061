.error-page-container {
	background-color: $color-neutral-lightest-2;
	height: 100vh;
	.error-page-inner {
		@include make-container();
		padding: 10px 33px;
	}

	.error-status {
		color: $color-neutral-dark;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-light;
		@include text(lambda, kappa);
	}

	.error-desc {
		margin: 10% 0;
	}

	.error-wrapper {
		margin: 0 auto;
		max-width: 500px;
	}

	h1 {
		color: $color-green-darker;
		font-family: $font-family-serif;
		font-weight: $font-weight-light;
		margin-bottom: 20px;
		@include text(zeta, delta);
	}

	h4 {
		color: $color-black;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-light;
		@include text(eta, 26);

		&:first-of-type {
			margin-bottom: 15px;
		}
	}

	.refresh-btn,
	.homepage-link {
		@include text(kappa, lambda);
		color: $color-green-darker;
		cursor: pointer;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-light;
	}

	p {
		&:not(:last-of-type) {
			margin-bottom: 8px;
		}
	}

	.error-search {
		align-items: center;
		background-color: $color-white;
		border-radius: 30px;
		display: flex;
		flex-basis: 40%;
		margin-top: 20px;
		padding: 10px 15px 10px 20px;

		&.loading {
			.loading-overlay {
				opacity: 1;
			}

			svg {
				opacity: 0;
			}
		}

		input {
			background: transparent;
			border: none;
			border-radius: unset;
			flex-grow: 1;
			font-size: unset;
			padding: 0 10px;

			&:focus {
				border: 0;
				box-shadow: none;
				outline: 0;
			}
		}

		.search-icon-container {
			display: flex;
			height: 1rem;
			position: relative;
			width: 1rem;

			.loading-overlay {
				height: 1rem;
				width: 1rem;
			}
		}

		svg {
			cursor: pointer;
			fill: $color-green;
			opacity: 1;
			transition: opacity 0.4s ease-in-out;
			stroke: $color-green;
		}
	}

	@include breakpoint(md) {
		h1 {
			@include text(34, 38);
		}
	}
}

.blog-intro-wrapper {
	@include jss-inner-container();
}

.blog-intro {
	margin-bottom: calc(var(--spacing-base) * 9);
	margin-top: calc(var(--spacing-base) * 6);
	h1 {
		color: var(--color-primary-dark);
		font-weight: 400;
		margin-bottom: calc(var(--spacing-base) * 4);
	}
}

.article-listing {
	@include make-container(1260px);
	margin-top: calc(var(--spacing-base) * 6);
	.articles-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: calc(var(--spacing-base) * 3);

		@include breakpoint(smd) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include breakpoint(lg) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.article-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: calc(var(--spacing-base) * 3);
		gap: calc(var(--spacing-base) * 2);
		border-radius: var(--br-md);
		background: var(--color-base);
		box-shadow: 0px 3px 12px 0px var(--color-shadows);
		text-decoration: none;

		&:hover {
			transform: translateY(-4px);
			transition: transform 0.2s ease;
		}
	}

	.article-image {
		width: 100%;
		img {
			width: 100%;
			height: 220px;
			object-fit: cover;
		}
	}

	.article-date {
		color: var(--color-overlay-base);
		font-size: var(--fs-label);
		margin-bottom: calc(var(--spacing-base) * 2);
	}

	.article-summary {
		color: var(--color-overlay-base);
		font-size: var(--fs-body);
		text-decoration: none;
		line-height: var(--lh-extra);
		font-family: var(--ff-serif);
	}
}

.pagination-container {
	display: flex;
	justify-content: center;
	margin-top: calc(var(--spacing-base) * 10);

	.pagination-btn {
		&.previous {
			svg {
				right: 0;
			}
		}

		&.next {
			svg {
				left: 0;
			}
		}
	}
}

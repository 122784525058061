.overhauled {
	.pld-form {
		.title {
			color: var(--color-primary-dark);
			margin-bottom: calc(var(--spacing-base) * 2);
		}
		.form-wrapper {
			border: 1px solid var(--color-body-dark);
			border-radius: var(--br-md);
			margin: 0 auto;
			padding: calc(var(--spacing-base) * 3);
			width: 98%;

			label:not(.radio-label) {
				font-weight: var(--fw-medium);
			}

			fieldset {
				padding: var(--spacing-base) calc(var(--spacing-base) * 2);
			}

			.remove-btn {
				display: none;

				&.shown {
					display: block;
				}
			}

			.field-wrapper {
				margin-bottom: calc(var(--spacing-base) * 2);
			}

			.expandable-field {
				button {
					justify-content: center;
					width: 100%;

					&::after {
						content: none;
					}
				}
				.field-wrapper {
					max-height: 0;
					overflow: hidden;
					transition: max-height 1.5s ease;

					p {
						margin: var(--spacing-base) 0 0 0;
					}
					textarea {
						min-height: 100px;
					}
				}
				&.show {
					.field-wrapper {
						max-height: 500px;
					}
				}
			}

			textarea {
				border: none;
				display: flex;
				justify-content: space-between;
				margin: 0;
				width: 100%;
				background-color: var(--color-bg-secondary);
				border-radius: var(--br-md);
				padding: var(--spacing-base) calc(var(--spacing-base) * 2);
				transition: all 0.1s ease-in-out;
				opacity: 0.5;
				width: 100%;

				&::placeholder {
					color: var(--color-btn-hover-shadow);
				}
			}

			input[type='file'] {
				margin-bottom: var(--spacing-base);

				&::file-selector-button {
					margin-bottom: 0;
				}
			}
			.error-msg {
				color: transparent;
				position: absolute;

				&.show {
					color: var(--color-errors);
				}
			}
			.submit-btn {
				margin: 0 auto;

				&:disabled {
					background-color: var(--color-btn-hover-shadow);
					border: none;
					cursor: default;

					&:hover {
						box-shadow: none;
					}
				}
			}
			.response-msg {
				margin: calc(var(--spacing-base) * 2) 0;
				text-align: center;
			}
		}
	}
}

.blog-pills {
	&.overhauled {
		width: unset;
		@include breakpoint(sm) {
			width: 100%;
		}
	}
	h4 {
		margin-top: calc(var(--spacing-base) * 4);
		margin-bottom: calc(var(--spacing-base) * 3);
	}
}

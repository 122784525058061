.overhauled {
	fieldset {
		border: none;
	}

	input[type='file'] {
		appearance: none;
		border: none;
		display: flex;
		justify-content: space-between;
		margin: 0;
		width: 100%;
		transition: all 0.1s ease-in-out;
		opacity: 0.5;

		&::placeholder {
			color: var(--color-btn-hover-shadow);
		}

		&::file-selector-button {
			margin: var(--spacing-base) 0;
			@include btn-secondary();
			padding: var(--spacing-base) calc(var(--spacing-base) * 2);
		}
	}

	input[type='tel'],
	input[type='text'],
	select {
		border: none;
		display: flex;
		justify-content: space-between;
		margin: 0;
		width: 100%;
		background-color: var(--color-bg-secondary);
		border-radius: var(--br-md);
		padding: var(--spacing-base) calc(var(--spacing-base) * 2);
		transition: all 0.1s ease-in-out;
		opacity: 0.5;

		&::placeholder {
			color: var(--color-btn-hover-shadow);
		}
	}
	textarea {
		display: flex;
		margin: calc(var(--spacing-base) * 2) 0;
	}
	select {
		appearance: none;
		background-image: url('../../images/icons/chevron-down.svg');
		background-position: right 16px top 50%;
		background-repeat: no-repeat;
		background-size: 12px 12px;

		&.open:focus {
			background-image: url('../../images/icons/chevron-up.svg');
		}
	}

	.required {
		color: var(--color-errors);
	}
}

.overhauled {
	.hero {
		height: 79vmax;
		position: relative;
		z-index: 0;

		@include breakpoint(sm) {
			height: 55vmax;
		}

		@include breakpoint(md) {
			height: 47vmax;
		}
		@include breakpoint(lg) {
			aspect-ratio: 3 / 1;
			height: auto;
			overflow: hidden;
		}

		&::before {
			background: rgb(2, 0, 36);
			background: linear-gradient(
				4deg,
				rgba(102, 97, 97, 0.9) 31.72%,
				rgba(102, 100, 97, 0) 92.67%
			);
			content: '';
			height: 100%;
			mix-blend-mode: multiply;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: 0;
		}

		.video-btn {
			cursor: pointer;
			position: absolute;
			right: calc(var(--spacing-base) * 4);
			top: calc(var(--spacing-base) * 2);
			z-index: 99;

			@include breakpoint(md) {
				right: calc(var(--spacing-base) * 4);
				top: calc(var(--spacing-base) * 4);
			}
			@include breakpoint(xl) {
				right: calc(var(--spacing-base) * 8);
			}
			@include breakpoint(xxl) {
				right: calc(var(--spacing-base) * 12);
			}
		}

		.hero-img {
			display: block;
			height: 100%;
			width: 100%;

			img,
			source,
			canvas {
				height: 100%;
				object-fit: cover;
				object-position: 50% 33%;
				width: 100%;
			}
		}

		.hero-video {
			display: block;
			height: 100%;
			object-fit: cover;
			width: 100%;

			iframe {
				height: 100%;
			}

			video.jss-hero-video {
				@include breakpoint(xs) {
					max-width: 100%;
					height: 100%;
				}
			}
		}

		.hero-inner-wrapper {
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;

			.jss-inner-container {
				height: 100%;
			}

			.hero-content {
				display: flex;
				flex-direction: column;
				gap: calc(var(--spacing-base) * 3);
				height: 100%;
				justify-content: flex-end;
				padding-bottom: calc(var(--spacing-base) * 8);

				@include breakpoint(md) {
					padding-bottom: calc(var(--spacing-base) * 16);
				}

				h1,
				p {
					color: $color-white;
				}

				p {
					margin-bottom: 0;
				}
			}
		}
	}
}

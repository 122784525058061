.get-care-toast {
	margin-top: 0px !important;
}

.get-care-container {
	background-color: $color-neutral-lightest-2;
	display: flex;
	padding: 3rem 0;
	flex-direction: column;
	overflow: hidden;

	.content-container {
		max-width: 100%;
		padding: 0 $sm-grid-outer-padding;
		position: relative;
	}

	@include breakpoint(md) {
		min-height: 400px;

		.content-container {
			margin: 0 auto;
			max-width: $medium-container-width;
		}
	}
	@include breakpoint(lg) {
		.content-container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.author-component {
	margin: 2rem 0;

	.action-btn {
		min-height: 55px;
		padding: 0 20px;

		div {
			padding-top: 13px;
		}

		svg {
			transform: rotate(90deg);
		}
	}
}

.author-container {
	display: flex;
	flex-direction: column;
	height: auto;
}

.author-image {
	height: 240px;

	img {
		height: 240px;
		object-fit: cover;
		margin-left: 0;
		max-width: unset;
		width: 100%;

		@include breakpoint(md) {
			width: 240px;
		}
	}
}

.author-details {
	margin: 20px 16px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.author-info {
		.name {
			@include text(kappa, kappa);
			font-family: $font-family-sans-serif;
			font-weight: 700;
			color: $color-neutral-darker;
			text-transform: uppercase;
			margin: 0 0 10px;
		}

		.summary {
			overflow: hidden;
			transition: max-height 0.5s ease-in-out;

			&.collapsed {
				max-height: 6em;
			}

			&.expanded {
				max-height: 100%;
			}
		}

		.email {
			@include text(kappa, kappa);
			font-family: $font-family-sans-serif;
			color: $color-blue-dark;
			margin: 10px 0;
			display: block;
		}

		.phone {
			@include text(kappa, kappa);
			font-family: $font-family-sans-serif;
			color: $color-neutral-darker;
			margin: 10px 0;

			@include breakpoint(md) {
				margin: 10px 0 0;
			}
		}

		.see-more {
			position: absolute;
			bottom: 0;
			right: 0;
			background: white;
			padding-left: 8px;
			cursor: pointer;
		}
	}
}

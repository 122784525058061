.form-select-wrapper {
	background: $color-neutral-lightest-2;
	border-radius: 30px;
	display: inline-flex;
	height: 40px;
	position: relative;

	select {
		appearance: none;
		background: transparent;
		border: none;
		color: $color-light-black;
		height: 100%;
		border-radius: 30px;
		padding: 5px 40px 5px 20px;
		width: 100%;
	}

	.icon-chevron-right {
		pointer-events: none;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: rotate(90deg) translateX(-50%);
	}
	.icon-chevron-select-down {
		position: absolute;
		right: 0.5rem;
		top: 32%;
	}
}

.CTA-container {
	font-family: $font-family-sans-serif;
	padding: 0.5rem;

	.schedule-link {
		background-color: $color-green-dark;
		color: $color-white;

		&:hover {
			background-color: $color-blue-lightest;
		}
	}

	@include breakpoint(md) {
		display: flex;
	}
}

.overhauled {
	.content-carousel-wrapper {
		h2 {
			color: var(--color-primary-dark);
			text-align: center;
		}
		.content-carousel {
			display: flex;

			.card-wrapper {
				display: flex;
				gap: calc(var(--spacing-base) * 4);
				overflow: hidden;
				padding-top: calc(var(--spacing-base) * 4);

				@include breakpoint(md) {
					gap: calc(var(--spacing-base) * 6);
				}

				@include breakpoint(lg) {
					gap: calc(var(--spacing-base) * 5);
				}
				@include breakpoint(xl) {
					gap: calc(var(--spacing-base) * 4);
					padding-top: calc(var(--spacing-base) * 8);
				}
			}

			.content-carousel-card {
				align-items: center;
				display: flex;
				flex-direction: column;
				min-width: 100%;

				@include breakpoint(md) {
					flex: 1 0 calc(50% - 24px);
					min-width: 0;
				}
				@include breakpoint(lg) {
					flex: 1 0 calc(33% - 26px);
				}
				@include breakpoint(xl) {
					flex: 1 0 calc(33% - 21px);
				}

				.content-carousel-img {
					aspect-ratio: 1 / 1;
					border-radius: 100%;
					overflow: hidden;
					width: 136px;

					@include breakpoint(sm) {
						width: 151px;
					}
					@include breakpoint(xl) {
						width: 219px;
					}

					img {
						height: 100%;
						object-fit: cover;
						width: 100%;
					}
				}

				.content-carousel-content {
					align-items: center;
					display: flex;
					flex-direction: column;
					padding: calc(var(--spacing-base) * 4) 0;
					text-align: center;

					@include breakpoint(sm) {
						padding: calc(var(--spacing-base) * 4) 0;
					}
					@include breakpoint(lg) {
						padding-bottom: 0;
					}
					@include breakpoint(xl) {
						padding-top: calc(var(--spacing-base) * 4);
					}

					.content-carousel-card-description {
						margin: calc(var(--spacing-base) * 2) 0 0 0;
					}

					.btn-primary {
						margin-top: calc(var(--spacing-base) * 6);

						@include breakpoint(md) {
							margin-top: calc(var(--spacing-base) * 5);
						}
					}
				}
			}
		}
		.control {
			align-items: center;
			display: flex;
			gap: var(--spacing-base);
			padding: calc(var(--spacing-base) * 3) 0 0 calc(var(--spacing-base) * 4);

			@include breakpoint(lg) {
				display: none;
			}
		}

		.control-next,
		.control-previous {
			align-items: center;
			background-color: var(--color-base);
			border-radius: 100%;
			box-shadow: var(--btn-shadow);
			cursor: pointer;
			display: flex;
			flex: 1 0 33px;
			height: 33px;
			justify-content: center;
		}

		.control-previous {
			svg {
				transform: rotate(180deg);
			}
		}

		.divider {
			background: var(--color-primary-dark);
			height: 2px;
			margin-left: calc(var(--spacing-base) * 3);
			width: 100%;
		}
	}
}

.alert-listing-group {
	margin-bottom: 40px;

	.alert-listing-group-title {
		@include text(theta, 24);
		color: $color-green-dark;
		font-family: $font-family-serif;
		font-weight: $font-weight-regular;
		margin: 0 0 20px;
	}

	.alert-listing {
		margin-bottom: 20px;

		.alert-container {
			display: flex;
		}

		.alert-type-date {
			@include text(lambda, 18);
			color: $color-light-black;
			display: flex;
			font-weight: $font-weight-bold;
			margin-bottom: 0.5rem;
			text-transform: uppercase;

			span {
				position: relative;

				&:not(:first-of-type) {
					display: inline-block;
					margin-left: 0.25rem;
					padding-left: 0.25rem;

					&:before {
						content: '|';
						display: block;
						left: 0;
						position: absolute;
						transform: translateX(-50%);
					}
				}
			}
		}

		.alert-content {
			@include text(lambda, 18);
			color: $color-neutral-darker;
			font-family: $font-family-sans-serif;
			margin-bottom: 0;
		}

		.learn-more {
			@include text(lambda, 18);
			color: $color-blue-dark;
			display: inline-block;
			margin-left: 0.25rem;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.pagination {
	display: inline-flex;
	justify-content: center;
	margin-bottom: 2rem;
	width: 100%;

	ul,
	li {
		align-items: center;
		display: inline-flex;
		list-style: none;
		@include breakpoint(md) {
			padding: 5px;
		}
	}

	ul,
	li:first-child,
	li:last-child {
		padding: 0px;
		text-decoration: unset;

		&:hover a {
			&::after {
				opacity: 0;
			}
		}
	}

	li:hover a {
		&::after {
			opacity: 1;
		}
	}

	li.active a {
		&::after {
			opacity: 1;
		}
	}

	a {
		color: $color-black;
		margin: 0px 4px 0px 4px;
		padding: 0px 3px;
		position: relative;

		&:focus {
			&::after {
				opacity: 1;
			}
		}

		&::after {
			content: '';
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $color-black;
			opacity: 0;
		}
	}

	.pagination-btn {
		align-items: center;
		background-color: $color-neutral-lightest-2;
		border-radius: 50%;
		display: inline-flex;
		height: 40px;
		justify-content: center;
		position: relative;
		width: 40px;
	}

	.previous {
		svg {
			position: absolute;
			transform: rotate(-90deg);
		}
	}

	.next {
		svg {
			position: absolute;
			transform: rotate(90deg);
		}
	}

	.icon {
		width: 21px;
	}

	@include breakpoint(sm) {
		a {
			margin: 0px 10px 0px 10px;
		}
	}
}

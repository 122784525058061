.event-listing {
	box-shadow: 0px 10px 10px #00000059;
	margin-bottom: 50px;

	.event-img {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.event-content {
		color: $color-neutral-darker;
		padding: 30px;
		position: relative;
		background-color: white;

		h1 {
			@include text(theta, iota);
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-light;
			color: $color-green-darker;
			margin: 15px 0 10px;
		}

		p {
			@include text(kappa);
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
		}

		.event-date {
			@include text(kappa);
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
		}

		.event-details {
			display: flex;
			flex-direction: column;
			font-weight: $font-weight-medium;

			.event-time {
				@include text(lambda);
				font-family: $font-family-sans-serif;
			}

			.event-type {
				@include text(kappa);
				font-family: $font-family-sans-serif;
			}
		}
	}

	.learn-or-register {
		display: flex;
		flex-direction: row;

		.action-btn-group {
			font-family: $font-family-sans-serif;
			display: block;
		}

		.action-btn {
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-regular;
			min-height: 165px;

			&:hover {
				background-color: $color-blue-lightest;
			}

			.action-text {
				@include text(theta);
			}

			p {
				@include text(kappa);
				font-family: $font-family-sans-serif;
			}

			svg {
				transform: rotate(90deg);
			}

			.icon-arrow-up {
				transform: rotate(-90deg);
			}

			&:last-of-type {
				background-color: $color-green-lighter;

				&:hover {
					background-color: $color-blue-lightest;
				}
			}
		}
	}

	@include breakpoint(md) {
		display: flex;
		box-shadow: unset;

		.event-img {
			flex-basis: 50%;
		}

		.event-content {
			flex-basis: 50%;

			h1 {
				@include text(theta, iota);
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-light;
				color: $color-green-darker;
				margin: 15px 0 10px;
			}
		}

		.learn-or-register {
			flex-direction: column;

			.action-btn {
				min-width: 165px;
			}
		}
	}

	@include breakpoint(lg) {
		max-height: 332px;
	}
}

.events-list-page {
	display: flex;
	justify-content: space-between;
}

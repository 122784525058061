.overhauled {
	.photo-feature {
		.content-wrapper {
			display: flex;
			flex-direction: column;

			.headline {
				color: var(--color-primary-dark);
			}
			.description {
				margin-bottom: var(--spacing-base);
				margin-top: calc(var(--spacing-base) * 3);
			}

			.images-row {
				@include breakpoint(md) {
					display: flex;
					gap: calc(var(--spacing-base) * 3);
				}
				.feature {
					display: flex;
					flex-direction: column;
					gap: calc(var(--spacing-base) * 2);
					margin-top: calc(var(--spacing-base) * 3);

					@include breakpoint(md) {
						flex: 1 1 50%;
						gap: calc(var(--spacing-base) * 3);
					}
					.img-container {
						border-radius: var(--br-md);
						height: 210px;
						overflow: hidden;

						img {
							height: 100%;
							object-fit: cover;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.simulation-banner {
	background-color: $color-blue-lightest;
	padding: 20px;
	text-align: center;
	width: 100%;
	margin-bottom: 50px;
	.text {
		@include text(mu, 16);
		font-family: $font-family-sans-serif;
		margin-bottom: 0;
		margin-top: 0;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 0.72px;

		span {
			font-weight: $font-weight-bold;
		}
	}

	@include breakpoint(lg) {
		margin-top: -1rem;
	}

	&:has(~ .jss-two-column > .right-column > .right-rail) {
			margin-bottom: 0;
		
			@include breakpoint(lg) {
				margin-bottom: 50px;
			}
	}

	&:has(~ .banner) {
		margin-bottom: 0;
	}
}

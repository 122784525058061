.r2-zip-overlay {
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}

.r2-zip-update {
	background-color: $color-neutral-lightest-2;
	border: 1px solid $color-neutral-lighter;
	border-radius: 5px;
	box-shadow: 3px 3px 6px rgba($color-black, 0.29);
	margin-top: 0.25rem;
	padding: 0.5rem 1.25rem;
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	z-index: 9;

	&:before {
		border-color: transparent transparent $color-neutral-lighter transparent;
		border-style: solid;
		border-width: 10px;
		content: ' ';
		position: absolute;
		right: 20px;
		top: -20px;
	}

	&:after {
		border-color: transparent transparent $color-neutral-lightest-2
			transparent;
		border-style: solid;
		border-width: 9px;
		content: ' ';
		position: absolute;
		right: 21px;
		top: -18px;
	}

	label {
		@include text(lambda);
		color: $color-neutral-darker;
		display: block;
		margin-bottom: 0.5rem;
	}

	.input-container {
		position: relative;

		&.invalid {
			.zip-input-field {
				border-color: $color-red;
				outline-color: $color-red;
			}

			.error-message {
				@include text(mu);
				color: $color-red;
				display: block;
				margin-left: 0.75rem;
			}
		}
	}

	.zip-input-field {
		@include text(lambda);
		border: 1px solid $color-neutral-dark;
		background-color: $color-neutral-lightest-4;
	}

	p {
		@include text(mu, 20);
		color: $color-light-black;
		margin-bottom: 0;
	}

	.input-btn-container {
		display: flex;
		margin-bottom: 0.5rem;
	}

	button {
		@include text(lambda);
		background-color: $color-green-lightest;
		border-radius: 20px;
		height: 32px;
		margin-left: 0.5rem;
		padding: 0 10px;

		&:hover {
			cursor: pointer;
		}
	}
}

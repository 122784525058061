.r2-search-bar {
	.r2-search-overlay {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba($color-black, 0.3);
		z-index: 6;
	}

	.r2-search-bar-container {
		@include make-container();
		background-color: $color-white;
		position: fixed;
		top: 0;
		left: 0;
		padding: 0 15px;
		z-index: 7;
	}

	.r2-search-bar-inner {
		align-items: center;
		display: flex;
		justify-content: center;
		padding: 1rem 0 1.25rem;
	}

	.close-search-btn {
		align-items: center;
		background-color: $color-white;
		border-radius: 50%;
		display: flex;
		height: 40px;
		justify-content: center;
		margin-left: 0.5rem;
		min-width: 40px;
		width: 40px;

		&:hover {
			background-color: $color-blue-lightest;
			cursor: pointer;
		}
	}

	.search-form {
		align-items: center;
		display: flex;
		width: 100%;
	}

	.search-input-container {
		flex: 1;
		position: relative;
	}

	.search-input {
		@include text(kappa, 21);
		background-color: $color-white;
		border: 1.5px solid $color-neutral;
		padding: 8px 1.25rem;
	}

	.icon-search {
		pointer-events: none;
		position: absolute;
		right: 23px;
		stroke: $color-green;
		top: 50%;
		transform: translateY(-50%);
	}

	.submit-btn {
		display: none;
	}

	.search-bar-results {
		background-color: $color-white;
		box-shadow: 0px 6px 6px rgba($color-black, 0.29);
		position: absolute;
		display: flex;
		flex-direction: column;
		left: 0;
		margin-top: -1px;
		top: 100%;
		width: 100%;
	}

	.mobile-provider-location-links {
		display: flex;
		margin-bottom: 1.25rem;
		padding: 0 15px;
		width: 100%;
	}

	.providers-link {
		@include text(iota, 20);
		align-items: center;
		background-color: $color-green-lightest;
		color: $color-black;
		display: flex;
		flex: 0 1 50%;
		height: 45px;
		justify-content: center;
	}

	.locations-link {
		@include text(iota, 20);
		align-items: center;
		background-color: $color-green-lighter;
		color: $color-black;
		display: flex;
		flex: 0 1 50%;
		height: 45px;
		justify-content: center;
	}

	.search-category-set {
		background-color: $color-white;
		display: flex;
		flex-direction: column;
		padding: 0 15px;
	}

	.search-category {
		@include text(iota, 20);
		color: $color-black;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	.search-bar-result {
		@include text(iota, 20);
		color: $color-light-black;
		font-weight: $font-weight-medium;
		margin-bottom: 1rem;

		&.active {
			text-decoration: underline;
		}
	}

	.search-bar-view-all {
		@include text(lambda);
		align-items: center;
		background-color: $color-neutral;
		color: $color-white;
		cursor: pointer;
		display: flex;
		font-weight: $font-weight-medium;
		height: 50px;
		justify-content: center;
		width: 100%;

		&.active {
			text-decoration: underline;
		}
	}

	.provider-location-results {
		display: none;
	}

	@include breakpoint(md) {
		.mobile-provider-location-links {
			display: none;
		}

		.provider-location-results {
			display: block;
			position: relative;

			.search-category {
				margin-bottom: 10px;
			}

			&:before {
				background-color: $color-neutral;
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				transform: translateX(-50%);
				width: 1px;
			}
		}

		.featured-list {
			margin: 0;

			.featured-item {
				margin-bottom: 10px;

				.item-image {
					width: 40%;
					height: fit-content;
				}
				.content-container {
					width: 60%;
				}

				&.active {
					background-color: $color-blue-lightest;
				}
			}

			.item-name {
				@include text(lambda, 16);
			}

			.view-all-link {
				color: $color-green-dark;
				margin-top: 0;

				&.active {
					text-decoration: underline;
				}
			}
		}

		.r2-search-bar-container {
			box-shadow: 0px 3px 6px rgba($color-black, 0.29);
			background-color: transparent;
			left: 0;
			padding: 0;
			position: absolute;
			top: -50px;
			transform: translateX(-145px);
			width: 630px;
		}

		.r2-search-bar-inner {
			background-color: $color-white;
			padding: 38px 0;
			position: relative;
		}

		.search-form {
			margin-left: 145px;
		}

		.search-input-container {
			width: 275px;
			max-width: 275px;
		}

		.search-input {
			@include text(kappa, 19);
			background-color: $color-neutral-lightest;
			border: 1.5px solid $color-neutral-lightest;
			padding: 5px 42px 5px 22px;
		}

		.icon-search {
			stroke: $color-green-light;
			stroke-width: 2px;
		}

		.submit-btn {
			@include text(lambda, 19);
			align-items: center;
			background-color: $color-green-lightest;
			border-radius: 25px;
			display: flex;
			font-weight: $font-weight-bold;
			justify-content: center;
			margin-left: 1rem;
			padding: 5px 1rem;

			&:hover,
			&:focus {
				background-color: $color-blue-lightest-2;
				cursor: pointer;
			}
		}

		.close-search-btn {
			position: absolute;
			right: 1rem;
		}

		.search-bar-results {
			align-items: center;
			background-color: $color-white;
		}

		.search-results-inner {
			display: flex;
			padding: 0 0 1.25rem;
			width: 100%;
		}

		.general-results {
			.empty-search-result {
				padding: 0px 2.5rem;
				display: block;
				font-size: 1.125rem;
				color: $color-black;
				font-weight: 700;
			}
		}

		.general-results,
		.provider-location-results {
			flex: 0 1 50%;
		}

		.search-category-set {
			margin: 0;
			padding: 0 40px;

			&:not(:first-of-type) {
				margin-top: 1rem;
			}
		}

		.search-bar-result {
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.search-bar-view-all {
			justify-content: flex-start;
			padding: 0 40px;

			&:hover,
			&:focus {
				background-color: $color-blue-lightest;
				color: $color-black;
			}
		}
	}

	@include breakpoint(lg) {
		.r2-search-bar-container {
			top: -17px;
			transform: translateX(-125px);
		}

		.r2-search-bar-inner {
			padding: 17px 0 30px;
		}

		.search-form {
			margin-left: 125px;
		}

		.search-input-container {
			width: 315px;
			max-width: 315px;
		}
	}
}

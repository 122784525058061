.social-media-links {
	.social-description {
		color: var(--color-base);
		margin-bottom: calc(var(--spacing-base) * 3);
		text-align: center;
	}

	.social-links {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: auto;
		max-width: 360px;
	}

	.social-link {
		align-items: center;
		display: flex;
		justify-content: center;

		.icon {
			height: 44px;
			width: 44px;

			@include breakpoint(md) {
				height: 54px;
				width: 54px;
			}

			&.icon-facebook-square:before {
				content: '';
			}

			svg > :first-child {
				fill: var(--color-base);
			}

			svg {
				path {
					fill: var(--color-primary-dark);
				}
			}

			&.icon-youtube-square {
				rect {
					fill: var(--color-primary-dark);
					& + path {
						fill: var(--color-base);
					}
				}
			}

			&:hover {
				svg > :first-child {
					fill: var(--color-shadows);
				}
				&.icon-youtube-square {
					rect {
						& + path {
							fill: var(--color-shadows);
						}
					}
				}
			}
		}
	}
}

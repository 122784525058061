.toast {
	top: 0%;
	position: sticky;
	width: 100%;
	z-index: 100;
	margin-top: 0px;

	.toast-container {
		padding: 0 5px;
	}

	.toast-inner {
		align-items: center;
		background-color: $color-neutral-lightest;
		box-shadow: 3px 3px 4px rgba($color-black, 0.29);
		display: flex;
		padding: 10px 20px;
		position: relative;
	}

	.toast-icon {
		display: flex;
		flex: 0 1 40px;
		margin-right: 10px;

		.icon {
			height: 40px;
			width: 40px;
		}
	}

	.toast-description {
		@include text(iota, 20);
		color: $color-neutral-darker;
		font-family: $font-family-sans-serif;
		margin-bottom: 10px;

		&:only-child {
			margin-bottom: 0;
		}
	}

	.action-link {
		@include text(iota, 20);
		color: $color-blue-dark;
		display: inline-block;
		margin-left: 10px;
	}

	.btn-close {
		display: flex;
		margin-left: auto;
		padding-left: 10px;

		.icon {
			height: 14px;
			width: 14px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	@include breakpoint(md) {
		.toast-container {
			@include make-container();
		}

		.action-link {
			color: $color-green-dark;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.btn-close {
			top: 30px;
		}
	}

	@include breakpoint(lg) {
		.toast-inner {
			margin: 0 50px;
		}
	}
}

@include breakpoint(md) {
	.toast {
		margin-top: -25px;
	}
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * brandon-grotesque:
 *   - http://typekit.com/eulas/0000000000000000000132df
 *   - http://typekit.com/eulas/0000000000000000000132e3
 *   - http://typekit.com/eulas/000000000000000077359ded
 *   - http://typekit.com/eulas/000000000000000077359dee
 *   - http://typekit.com/eulas/000000000000000077359df2
 * chaparral-pro:
 *   - http://typekit.com/eulas/00000000000000003b9aee4e
 *   - http://typekit.com/eulas/00000000000000003b9aee52
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-03-03 19:01:13 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=wds4ngs&ht=tk&f=6905.6907.10294.10296.10300.10301.10302&a=91719634&app=typekit&e=css');

@font-face {
	font-family: 'chaparral-pro';
	src: url('https://use.typekit.net/af/d07f20/00000000000000003b9aee4b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/d07f20/00000000000000003b9aee4b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('woff'),
		url('https://use.typekit.net/af/d07f20/00000000000000003b9aee4b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'chaparral-pro';
	src: url('https://use.typekit.net/af/9da37c/00000000000000003b9aee4e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/9da37c/00000000000000003b9aee4e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/9da37c/00000000000000003b9aee4e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'chaparral-pro';
	src: url('https://use.typekit.net/af/20a4c3/00000000000000003b9aee52/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/20a4c3/00000000000000003b9aee52/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/20a4c3/00000000000000003b9aee52/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'brandon-grotesque';
	src: url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'brandon-grotesque';
	src: url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'brandon-grotesque';
	src: url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('woff'),
		url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'brandon-grotesque';
	src: url('https://use.typekit.net/af/08312f/000000000000000077359dee/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/08312f/000000000000000077359dee/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
			format('woff'),
		url('https://use.typekit.net/af/08312f/000000000000000077359dee/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'brandon-grotesque';
	src: url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('woff'),
		url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

.tk-chaparral-pro {
	font-family: 'chaparral-pro', serif;
}
.tk-brandon-grotesque {
	font-family: 'brandon-grotesque', sans-serif;
}

body {
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-regular;
}

p {
	@include text(iota, 20);
	font-family: $font-family-serif;
	margin-bottom: 1rem;
	line-height: 1.5rem;
}

.button-headline {
	@include text(iota);
	font-weight: $font-weight-bold;
}

.button-cta {
	@include text(theta);
}

.button-detail {
	@include text(kappa, 20);
}

.filter {
	@include text(lambda);
	font-family: Brandon Grotesque, Regular;
	text-align: left !important;
	color: #333230;
}

.subject-matter-detail {
	@include text(kappa);
}

.text-plus {
	@include text(kappa, 20);
}

.paragraph-intro {
	@include text(theta, 24);
	font-family: $font-family-serif;
	font-family: Chaparral Pro, Regular;
	font-weight: $font-weight-light;
	text-align: left !important;
	span {
		margin-bottom: 1.5rem;
	}
}

.intro-text {
	color: $color-neutral-darker;
	font-size: 34px;
	line-height: 38px;
	padding-bottom: 2.25rem;
	margin-bottom: 2.25rem;
	border-bottom: 1px solid $color-neutral-lighter;
}

.body-copy {
	@include text(kappa, 24);
	color: $color-neutral-darker;
}

.text-container {
	margin-top: 1rem;
	margin-bottom: 1.5rem;
}

.biography-text {
	font-weight: $font-weight-light;
	margin-bottom: 1.5rem;
}

.ordered-list {
	@include text(kappa, lambda);
	font-family: $font-family-sans-serif;
}

.content-block {
	margin: calc(var(--spacing-base) * 2) 0;
}

.osf-button-headline {
	@include text(iota);
	font-weight: $font-weight-bold;
}
.osf-button-headline-white {
	@include text(iota);
	font-weight: $font-weight-bold;
	color: white;
}
.osf-button-cta {
	@include text(theta);
}
.osf-button-cta-white {
	@include text(theta);
	color: white;
}
.osf-button-detail {
	@include text(kappa, 20);
}
.osf-button-detail-white {
	@include text(kappa, 20);
	color: white;
}
.osf-filter-black {
	@include text(lambda);
	color: black;
}
.osf-filter-white {
	@include text(lambda);
	color: white;
}
.osf-module-title {
	@include text(lambda);
}
.osf-paragraph-intro {
	@include text(theta, 24);
	font-family: $font-family-serif;
	font-weight: $font-weight-light;
	span {
		margin-bottom: 1.5rem;
	}
}

.osf-clear-item ~ hr {
	clear: both;
}

.overhauled {
	.link-list {
		//margin is only here to override that set in /frontend link-list, remove w cleanup
		margin-bottom: 0;

		.inner-container {
			@include breakpoint(lg) {
				display: flex;
				gap: calc(var(--spacing-base) * 6);
			}
			@include breakpoint(xl) {
				display: flex;
				gap: calc(var(--spacing-base) * 8);
			}
			.content-wrapper {
				display: flex;
				flex-direction: column;
				gap: calc(var(--spacing-base) * 2);

				@include breakpoint(sm) {
					gap: calc(var(--spacing-base) * 3);
				}

				@include breakpoint(lg) {
					flex-basis: 66%;
					gap: calc(var(--spacing-base) * 4);
					padding: calc(var(--spacing-base) * 2) 0;
				}
				@include breakpoint(xl) {
					padding: calc(var(--spacing-base) * 9) 0 calc(var(--spacing-base) * 5) 0;
				}

				p {
					margin-bottom: 0;
				}
			}

			.heading {
				color: var(--color-primary-dark);
			}

			.desktop {
				align-items: center;
				display: flex;
				flex-basis: 34%;

				.img-container {
					height: 100%;
					width: 100%;
				}
			}
			.mobile {
				margin: var(--spacing-base) 0;

				@include breakpoint(sm) {
					margin-top: 0;
				}

				@include breakpoint(md) {
					align-items: center;
					display: flex;
					flex-basis: 52%;
					min-height: 100%;
				}

				@include breakpoint(lg) {
					display: none;
				}

				.img-container {
					@include breakpoint(md) {
						height: 100%;
						max-height: 466px;
						width: 100%;
					}
				}
			}
			.img-container {
				aspect-ratio: 16 / 9;
				border-radius: var(--br-md);
				box-shadow: var(--btn-shadow);
				overflow: hidden;
				@include breakpoint(sm) {
					margin: 0;
				}
				img {
					height: 100%;
					object-fit: cover;
					width: 100%;
				}
			}
			.wrapper {
				@include breakpoint(md) {
					display: flex;
					gap: calc(var(--spacing-base) * 6);
				}
			}
			.links {
				margin-top: calc(var(--spacing-base) * 2);

				@include breakpoint(md) {
					flex-basis: 48%;
					margin-top: calc(var(--spacing-base) * -2);
				}
				@include breakpoint(lg) {
					columns: 2;
					flex-basis: 100%;
					gap: calc(var(--spacing-base) * 8);
					margin-top: calc(var(--spacing-base) * -1);
				}
				@include breakpoint(xl) {
					gap: calc(var(--spacing-base) * 11);
				}

				.link {
					text-decoration: none;
				}

				.link-outer {
					border-bottom: 2px solid var(--color-shadows);
					padding: var(--spacing-base) 0 calc(var(--spacing-base) * 2) 0;

					@include breakpoint(md) {
						padding: calc(var(--spacing-base) * 2) 0;
					}

					@include breakpoint(lg) {
						display: inline-block;
						width: 100%;
					}
					@include breakpoint(xl) {
						padding: calc(var(--spacing-base) * 3) 0;
					}

					&:hover {
						cursor: pointer;
					}

					.link-wrapper {
						align-items: center;
						display: flex;
						gap: var(--spacing-base);
						justify-content: space-between;
						padding-right: calc(var(--spacing-base) * 3);

						@include breakpoint(md) {
							padding-right: calc(var(--spacing-base) * 2);
						}
						@include breakpoint(xl) {
							padding-right: calc(var(--spacing-base) * 3);
						}

						&:hover {
							padding-right: calc(var(--spacing-base) * 3 - 4px);

							@include breakpoint(md) {
								padding-right: calc(var(--spacing-base) * 2 - 4px);
							}
							@include breakpoint(xl) {
								padding-right: calc(var(--spacing-base) * 3 - 4px);
							}
						}
						a {
							color: var(--color-body-dark);
							text-decoration: none;

							&:hover {
								text-decoration: none !important;
							}
						}
						svg {
							height: 16px;
							stroke-linecap: round;
							stroke-width: 3px;
							width: 10px;

							path {
								stroke: var(--color-primary-dark);
							}
						}
					}
				}
			}
			&:not(.with-img) {
				@include breakpoint(lg) {
					display: block;
				}
				.heading,
				.description {
					@include breakpoint(lg) {
						width: 66%;
					}
					@include breakpoint(xl) {
						width: 100%;
					}
				}
				.links {
					margin-top: 0;
					@include breakpoint(md) {
						columns: 2;
						flex-basis: 100%;
					}
					@include breakpoint(lg) {
						columns: 3;
					}
				}
			}
		}
	}
}
